import React from 'react'
import styled from 'styled-components'
import { ColumnProps } from './table.component'
import { Row, RatioContainer } from '../../shared.styled'
import { useIsAdmin } from '../../hooks/use-is-admin.hook'
import { SortOrderEnum } from '../../sort.utils'
import { SortableIcon } from '../../../assets/icons/sortable.icon'
import { VisibilityStatusEnum } from '../../shared.enums'

interface TableHeadersProps {
    columns: ColumnProps[]
}

export enum HeaderEnum {
    BRAND = 'brand',
    STOCK = 'stock',
    DATE_VEHICLE = 'dateVehicle',
    BUY_PRICE = 'buyPrice',
    SELLER_PRICE = 'sellerPrice',
    MILEAGE = 'mileage',
    DATE_OF_SALE = 'dateOfSale',
    SALE_PRICE = 'salePrice',
    SALE_MARGIN = 'saleMargin',
    LOAN_VALUE = 'loanValue',
}

export enum HeaderEnumCompany {
    NAME = 'name',
    COLOR = 'color',
    NUMBER_OF_STANDS = 'numberOfStands',
    NUMBER_OF_CARS = 'numberOfCars',
}

export enum HeaderEnumStand {
    NAME = 'name',
    LOCATION = 'location',
    ADDRESS = 'address',
    ACTIVE = 'active',
    ON_SALE = 'onSale',
    SOLD = 'sold',
    OTHER = 'other',
}

export enum HeaderEnumUsers {
    USERNAME = 'username',
    FIRSTNAME = 'firstName',
    LASTNAME = 'lastName',
    ROLE = 'role',
    ACTIVE = 'active',
}

export enum HeaderEnumFinancial {
    NAME = 'name',
    CODE = 'code',
}

export enum HeaderContactsEnum {
    NAME = 'name',
    DATE = 'date',
    LAST_ITERACTION = 'last_iteraction',
    PHONE = 'phone',
    EMAIL = 'email',
    STATUS = 'status',
    SELLER = 'seller',
}

export enum HeaderEnumSupplier {
    NAME = 'name',
    FREQUENT = 'isFrequent',
    NUMBER_VEHICLES = 'numberVehicles',
}

interface SortStatus {
    key: string
    order: SortOrderEnum
}

export const TableHeaders = ({ columns }: TableHeadersProps) => {
    const isAdmin = useIsAdmin()
    const [sortedHeader, setSortedHeader] = React.useState<
        SortStatus | undefined
    >(undefined)

    const _onHeaderClick = (header: string, onClick?: Function) => {
        if (onClick) {
            // If we're already sorting by the column, we just need to inverse the order
            if (sortedHeader && sortedHeader.key === header) {
                const newSortOrder =
                    sortedHeader.order === SortOrderEnum.ASC
                        ? SortOrderEnum.DESC
                        : SortOrderEnum.ASC
                onClick(header, newSortOrder)
                setSortedHeader({ key: header, order: newSortOrder })
            } else {
                // If we order by another column, we start the ordering ASC
                onClick(header, SortOrderEnum.ASC)
                setSortedHeader({ key: header, order: SortOrderEnum.ASC })
            }
        }
    }

    const renderColumn = (column: ColumnProps) => {
        return (
            <Header
                key={`header-${column.key}`}
                ratio={column.ratio}
                alignment={column.alignment}
            >
                <HeaderText
                    onClick={() => _onHeaderClick(column.key, column.onClick)}
                >
                    {column.label}
                    {column.onClick ? <SortableIcon /> : null}
                </HeaderText>
            </Header>
        )
    }

    return (
        <Container>
            {columns.map((column: ColumnProps) => {
                let visibility = column.visibility || VisibilityStatusEnum.ALL
                switch (visibility) {
                    case VisibilityStatusEnum.ALL:
                        return renderColumn(column)
                    case VisibilityStatusEnum.ADMIN_ONLY:
                        return isAdmin && renderColumn(column)
                    case VisibilityStatusEnum.SELLER_ONLY:
                        return !isAdmin && renderColumn(column)
                    default:
                        return null
                }
            })}
        </Container>
    )
}

const Container = styled(Row as any)`
    display: flex;
    min-width: 950px;
    flex-direction: row;
    border-bottom: 1px solid #b0bbc7;
    padding-bottom: 8px;
    margin-bottom: 24px;
    padding: 0 12px 8px 12px;
`

export interface HeaderProps {
    alignment: 'flex-start' | 'center' | 'flex-end' | undefined
    id: string | undefined
}

const Header = styled(RatioContainer as any)<HeaderProps>`
    display: flex;

    justify-content: ${props =>
        props.alignment ? props.alignment : 'flex-start'};

    svg {
        fill: #44566c;
        margin-left: 8px;
        width: 10px;
        height: 10px;
    }
`

const HeaderText = styled('span')`
    font-family: Merriweather-Light;
    font-size: 14px;
    font-weight: bold;
    color: #44566c;
    cursor: pointer;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`
