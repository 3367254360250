import React from 'react'
import { VehiclePhoto, VehicleVideo } from '../../shared/shared.models'
import { Popper } from '@material-ui/core'
import {
    StarRounded,
    Fullscreen,
    DeleteRounded,
    CloseRounded,
} from '@material-ui/icons'
import { Colors } from '../../configs/styled.config'
import styled from 'styled-components'
import { Column, Row } from '../../shared/shared.styled'

interface HelperMenuProps {
    anchorEl: HTMLElement
    photo: VehiclePhoto
    closePopper: () => void
    showConfirmationModal: () => void
    showFullScreenImage: () => void
    setImageAsFeatured: () => void
}

interface HelperMenuVideoProps {
    anchorEl: HTMLElement
    video: VehicleVideo
    closePopper: () => void
    showConfirmationModal: () => void
}

export const HelperMenu = ({
    anchorEl,
    showConfirmationModal,
    closePopper,
    showFullScreenImage,
    setImageAsFeatured,
}: HelperMenuProps) => {
    const anchorWidth = anchorEl.clientWidth
    const anchorHeight = anchorEl.clientHeight
    return (
        <Popper
            modifiers={{
                preventOverflow: {
                    enabled: false,
                },
                hide: {
                    enabled: false,
                },
                flip: { enabled: false },
                offset: { offset: `0,${-anchorHeight}` },
            }}
            open={true}
            anchorEl={anchorEl}
        >
            <HelperMenuContainer height={anchorHeight} width={anchorWidth}>
                <Row style={{ padding: 8 }}>
                    <CloseRounded
                        onClick={closePopper}
                        style={{
                            color: 'white',
                            marginLeft: 'auto',
                            fontSize: 24,
                            cursor: 'pointer',
                        }}
                    />
                </Row>
                <MenuRow
                    onClick={(e: any) => {
                        e.stopPropagation()
                        setImageAsFeatured()
                    }}
                >
                    <StarRounded style={{ color: 'yellow' }} />
                    <MenuText>Destacar</MenuText>
                </MenuRow>
                <MenuRow
                    onClick={(e: any) => {
                        e.stopPropagation()
                        showFullScreenImage()
                    }}
                >
                    <Fullscreen style={{ color: 'white' }} />
                    <MenuText>Pré-visualizar</MenuText>
                </MenuRow>
                <MenuRow
                    onClick={(e: any) => {
                        e.stopPropagation()
                        showConfirmationModal()
                    }}
                >
                    <DeleteRounded style={{ color: Colors['alive-red'] }} />
                    <MenuText>Eliminar</MenuText>
                </MenuRow>
            </HelperMenuContainer>
        </Popper>
    )
}

export const HelperMenuVideo = ({
    anchorEl,
    showConfirmationModal,
    closePopper,
}: HelperMenuVideoProps) => {
    const anchorWidth = anchorEl.clientWidth
    const anchorHeight = anchorEl.clientHeight
    return (
        <Popper
            modifiers={{
                preventOverflow: {
                    enabled: false,
                },
                hide: {
                    enabled: false,
                },
                flip: { enabled: false },
                offset: { offset: `0,${-anchorHeight}` },
            }}
            open={true}
            anchorEl={anchorEl}
        >
            <HelperMenuContainer height={anchorHeight} width={anchorWidth}>
                <Row style={{ padding: 8 }}>
                    <CloseRounded
                        onClick={closePopper}
                        style={{
                            color: 'white',
                            marginLeft: 'auto',
                            fontSize: 24,
                            cursor: 'pointer',
                        }}
                    />
                </Row>
                <MenuRow
                    onClick={(e: any) => {
                        e.stopPropagation()
                        showConfirmationModal()
                    }}
                >
                    <DeleteRounded style={{ color: Colors['alive-red'] }} />
                    <MenuText>Eliminar</MenuText>
                </MenuRow>
            </HelperMenuContainer>
        </Popper>
    )
}

const HelperMenuContainer = styled(Column as any)<{
    width: number
    height: number
}>(
    props => `
  background-color: rgba(0, 0, 0, 0.7);
  width: ${props.width}px;
  height: ${props.height}px;
  border-radius: 6px;
`
)

const MenuRow = styled(Row as any)`
    height: 36px;
    cursor: pointer;
    padding: 0 8px;

    :hover {
        background-color: #333;

        > span {
            font-weight: bold;
        }
    }
`

const MenuText = styled('span')`
    font-family: Merriweather-Light;
    font-size: 16px;
    margin-left: 8px;
    color: white;
`
