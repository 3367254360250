import { css } from 'styled-components'

export const Colors = {
    'alive-red': '#ec1c24',
    'alive-green': '#34C7A5',
    slate: '#4a5b6d',
    'pale-grey': '#fafbfb',
    'slate-light': '#5c6c7f',
    'light-grey-blue-90': '#9facb9e6',
    'dull-red': '#c44536',
    'very-light-brown': '#ceb992',
    'ice-blue': '#eef2f4',
    dark: '#283d3b',
    'cloudy-blue': '#b3c0ce',
    'light-grey-blue': '#b0bbc7',
    cornflower: '#7755fe',
    'very-light-blue': '#e7edf3',
    white: '#ffffff',
    green: '#3c9120',
    'gold-pmauto': '#FFCC33',
    'primary-digiauto': '#f4623a',
}

export const Typography = {
    'Body_Text_#1_Regular_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        color: #4d5762;
    `,
    'Body_Text_#2_Regular_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        color: #44566c;
    `,
    H4_White_Left: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #ffffff;
    `,
    H4_High_contrast_Left: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#2_White_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
    `,

    'Body_Text_#2_Low_Contrast_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: center;
        color: #8996a6;
    `,

    H3_White_Center: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
    `,

    H2_White_Center: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
    `,

    H1_White_Right: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: -0.7px;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#2_White_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#1_Regular_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #44566c;
    `,

    H4_Link_Center: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #00c2ff;
    `,

    'Body_Text_#1_White_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
    `,

    iny_Text_Regular_Center: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        color: #8996a6;
    `,

    'Body_Text_#1_High_Contrast_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        color: #44566c;
    `,

    Tiny_Text_Regular_Left: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: #8996a6;
    `,

    H4_Low_contrast_Right: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        color: #8a97a6;
    `,

    H2_Regular_Right: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: bold;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#2_Link_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #00c2ff;
    `,

    H1_Regular_Center: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: -0.7px;
        text-align: center;
        color: #44566c;
    `,

    'Body_Text_#3_White_Right': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #ffffff;
    `,

    H1_White_Left: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: -0.1px;
        color: #ffffff;
    `,

    H3_Regular_Right: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        text-align: right;
        color: #44566c;
    `,

    H1_Regular_Left: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: bold;

        letter-spacing: -0.7px;
        color: #44566c;
    `,

    H3_Regular_Center: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;

        text-align: center;
        color: #44566c;
    `,

    Tiny_Text_Link_Left: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        color: #00c2ff;
    `,

    Tiny_Text_White_Right: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0.3px;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#3_Link_Right': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        text-align: right;
        color: #00c2ff;
    `,

    H2_Regular_Center: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: bold;
        text-align: center;
        color: #44566c;
    `,

    H2_White_Right: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#2_High_Contrast_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        color: #44566c;
    `,

    'Body_Text_#1_Link_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: center;
        color: #00c2ff;
    `,

    Tiny_Text_Regular_Right: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0.3px;
        text-align: right;
        color: #8996a6;
    `,

    'Body_Text_#1_Regular_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        color: #44566c;
    `,

    H4_High_contrast_Center: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #44566c;
    `,

    'Body_Text_#2_Low Contrast_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: right;
        color: #8996a6;
    `,

    H1_White_Center: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: 600;
        letter-spacing: -0.1px;
        text-align: center;
        color: #ffffff;
    `,

    'Body_Text_#2_High Contrast_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -0.2px;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#2_Link_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: right;
        color: #00c2ff;
    `,

    'Body_Text_#3_Link_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: 600;
        color: #00c2ff;
    `,

    'Body_Text_#3_White_Centered': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #ffffff;
    `,

    H4_White_Center: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #ffffff;
    `,

    H4_Link_Left: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #00c2ff;
    `,

    'Body_Text_#2_Regular_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: center;
        color: #44566c;
    `,

    'Body_Text_#3_Low Contrast_Right': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        text-align: right;
        color: #8996a6;
    `,

    H1_Regular_Right: css`
        font-family: Merriweather-Light;
        font-size: 34px;
        font-weight: bold;
        letter-spacing: -0.7px;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#3_Low Contrast_Center': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        text-align: center;
        color: #8996a6;
    `,

    'Body_Text_#2_White_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
    `,

    H4_High_contrast_Right: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #44566c;
    `,

    'Body_Text_#3_Link_Center': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        color: #00c2ff;
    `,

    H3_White_Right: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#2_High_Contrast_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #44566c;
    `,

    Tiny_Text_White_Center: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0.3px;
        text-align: center;
        color: #ffffff;
    `,

    H4_White_Right: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        color: #ffffff;
    `,

    'Body_Text_#3_Regular_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        color: #44566c;
    `,

    'Body_Text_#2_Low_Contrast_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        color: #8996a6;
    `,

    Tiny_Text_White_Left: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        letter-spacing: 0.3px;
        color: #ffffff;
    `,

    'Body_Text_#1_High_Contrast_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        text-align: center;
        color: #44566c;
    `,

    'Body_Text_#1_White_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: right;
        color: #ffffff;
    `,

    H2_White_Left: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: 600;
        color: #ffffff;
    `,

    'Body_Text_#3_High Contrast_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        color: #44566c;
    `,

    H2_Regular_Left: css`
        font-family: Merriweather-Light;
        font-size: 26px;
        font-weight: bold;
        color: #44566c;
    `,

    'Body_Text_#1_Link_Right': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        text-align: right;
        color: #00c2ff;
    `,

    H4_Low_contrast_Left: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        color: #8a97a6;
    `,

    'Body_Text_#1_High_Contrast_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        color: #44566c;
    `,

    H4_Link_Right: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: right;
        color: #00c2ff;
    `,

    Tiny_Text_Link_Center: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        text-align: center;
        color: #00c2ff;
    `,

    'Body_Text_#1_Link_Left': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        color: #00c2ff;
    `,

    H3_White_Left: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
    `,

    'Body-Text_#2_Link_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: 600;
        color: #00c2ff;
    `,

    H4_Low_contrast_Center: css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        text-align: center;
        color: #8996a6;
    `,

    'Body_Text_#3_High Contrast_Center': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        color: #44566c;
    `,

    Tiny_Text_Link_Right: css`
        font-family: Merriweather-Light;
        font-size: 9px;
        font-weight: 600;
        text-align: right;
        color: #00c2ff;
    `,

    'Body_Text_#3_Regular_Center': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        text-align: center;
        color: #44566c;
    `,

    'Body_Text_#3_Low_Contrast_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        color: #8996a6;
    `,

    H3_Regular_Left: css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: bold;
        color: #44566c;
    `,

    'Body_Text_#3_White_Left': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
    `,

    'Body_Text_#3_High Contrast_Right': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        font-weight: bold;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#3_Regular_Right': css`
        font-family: Merriweather-Light;
        font-size: 14px;
        text-align: right;
        color: #44566c;
    `,

    'Body_Text_#1_White_Center': css`
        font-family: Merriweather-Light;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        color: #ffffff;
    `,
}
