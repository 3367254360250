import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

export const useIsSuperAdmin = () => {
    const isSuperAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.user.isSuperUser === true
    )

    return isSuperAdmin
}
