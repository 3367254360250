import React from 'react'
import { IBaseInterface } from './user.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { CreateUserRequest } from '../admin.interfaces'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import UserFeatures from '../components/user-features.component'

export const UserDetailsPage = ({ user }: IBaseInterface) => {
    // Hooks
    const dispatch = useDispatch()
    const alert = useAlert()
    // Redux state
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)

                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
        }
    }

    /**
     * Calls the API endpoint to update a stand and handles the
     * loading and error states of the form
     */
    async function updateUser(
        values: CreateUserRequest,
        actions: FormikHelpers<CreateUserRequest>
    ) {
        actions.setSubmitting(true)
        try {
            //await AdminApi.methods.updateUserPassword(user.uuid, user);
            const res2 = await AdminApi.methods.updateUserRole(
                user.uuid,
                values
            )
            dispatch(
                AdminActions.methods.updateUserSuccessAction(res2.data.user)
            )
            setIsEditing(false)
            alert.success('Utilizador gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o utilizador. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    /**
     * Calls the API endpoint to update a user and put it active and handles the
     * loading and error states of the form
     */
    async function activateUser() {
        try {
            const res = await AdminApi.methods.activateUser(
                user.uuid,
                user.companyUuid
            )
            dispatch(
                AdminActions.methods.activateUserSuccessAction(res.data.user)
            )
            setIsEditing(false)
            alert.success('Utilizador gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o utilizador. Por favor tente mais tarde'
            )
        } finally {
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(':companyId', user.companyUuid)
            )
        }
    }

    /**
     * Calls the API endpoint to deactivate a user and handles the
     * loading and error states of the form
     */
    async function deactivateUser() {
        try {
            const res = await AdminApi.methods.deactivateUser(
                user.uuid,
                user.companyUuid
            )
            dispatch(
                AdminActions.methods.deactivateUserSuccessAction(res.data.user)
            )
            setIsEditing(false)
            alert.success('Utilizador desativado com successo.')
        } catch (e) {
            alert.error(
                'Erro ao tentar desativar o utilizador. Por favor tente mais tarde'
            )
        } finally {
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(':companyId', user.companyUuid)
            )
        }
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...user,
        }
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit And Sell vehicle buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                    />
                </>
            )
        }
        return (
            <>
                <PrimaryButton
                    label="Editar utilizador"
                    onClick={() => setIsEditing(true)}
                />

                <PrimaryButton
                    isLoading={isSubmitting}
                    label={
                        user.isActive
                            ? 'Desativar Utilizador'
                            : 'Ativar Utilizador'
                    }
                    onClick={
                        user.isActive
                            ? () => deactivateUser()
                            : () => activateUser()
                    }
                    redTheme={user.isActive ? true : false}
                    greenTheme={user.isActive ? false : true}
                />
                {/* <PrimaryButton
          label="Apagar utilizador"
          // onClick={
          //  //apagar
          // }
          redTheme={true} 
        />*/}
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateUserRequest>
                initialValues={getInitialValues()}
                onSubmit={updateUser}
            >
                {({ dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={`Detalhes do ${user.username}`}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>
                        <UserFeatures
                            isEditing={isEditing}
                            isCreating={false}
                        />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
