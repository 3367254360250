import React from 'react'
import { ContactIteraction } from '../admin.interfaces'
import { Column, RatioContainer, Row } from '../../shared/shared.styled'
import { Typography, Colors } from '../../configs/styled.config'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'

interface ContactIteractionTableProps {
    iteractions: ContactIteraction[]
    editIteraction: (iteractionIdx: number) => void
    deleteIteraction: (iteractionIdx: number) => void
    isEditing?: boolean
}

export const ContactIteractionTable = ({
    iteractions,
    editIteraction,
    deleteIteraction,
    isEditing = true,
}: ContactIteractionTableProps) => {
    return (
        <Column>
            <div style={{ marginBottom: 16 }}>
                <Row>
                    <RatioContainer ratio={1 / 6}>
                        <HeaderText>Data de entrada</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={4 / 6}>
                        <HeaderText>Descrição</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 6}></RatioContainer>
                </Row>
                <Separator />
            </div>
            {iteractions.map((iteraction, idx) => (
                <TableRow key={`iteraction-${idx}`}>
                    <RatioContainer ratio={1 / 6}>
                        <RowText>{iteraction.date}</RowText>
                    </RatioContainer>
                    <EllipsedContainer ratio={4 / 6}>
                        <EllipsedRowText>
                            {iteraction.description
                                ? iteraction.description
                                : '-'}
                        </EllipsedRowText>
                    </EllipsedContainer>

                    <ButtonsContainer ratio={1 / 6}>
                        {isEditing ? (
                            <>
                                <EditIcon onClick={() => editIteraction(idx)} />
                                <DeleteForeverIcon
                                    onClick={() => deleteIteraction(idx)}
                                    style={{
                                        fill: Colors['gold-pmauto'],
                                    }}
                                />
                            </>
                        ) : null}
                    </ButtonsContainer>
                </TableRow>
            ))}
        </Column>
    )
}

const HeaderText = styled('span')`
    ${Typography['Body_Text_#1_High_Contrast_Left']}
`

const RowText = styled('div')`
    ${Typography['Body_Text_#2_Regular_Left']}
`

const EllipsedRowText = styled(RowText as any)`
    padding-right: 8px;
`

const EllipsedContainer = styled(RatioContainer as any)`
    flex-wrap: wrap;
`

const ButtonsContainer = styled(RatioContainer as any)`
    justify-content: flex-end;

    svg {
        width: 20px;
        height: 20px;
        fill: inherit;
        cursor: pointer;
    }

    svg:not(:last-child) {
        margin-right: 16px;
    }
`

const TableRow = styled(Row as any)`
    min-height: 36px;
    padding: 8px 0 8px 0;

    :nth-child(odd) {
        background-color: #fafafa;
    }
`

const Separator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`
