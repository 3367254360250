import React from "react";

const ChevronDownIcon = () => {
  return (
    <svg
      viewBox="0 0 14 8"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Symbols"
        stroke="inherit"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UI-/-Dropdown-/-Closed"
          transform="translate(-167.000000, -46.000000)"
        >
          <g id="feather-chevron-down">
            <g transform="translate(162.000000, 38.000000)">
              <g
                id="Icon"
                transform="translate(6.000000, 9.000000)"
                stroke="#B0BBC7"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
              >
                <g id="Icon-Group">
                  <polyline id="Icon-Path" points="0 0 6 6 12 0"></polyline>
                </g>
              </g>
              <rect
                id="ViewBox"
                fillRule="nonzero"
                x="-5.32907052e-15"
                y="0"
                width="24"
                height="24"
              ></rect>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ChevronDownIcon;
