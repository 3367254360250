import React from "react";

const SettingsIcon = () => {
  return (
    <svg
      viewBox="0 0 23 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="inherit"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Vehicles-/-Create-vehicle---Dropdowns-open"
          transform="translate(-19.000000, -192.000000)"
          stroke="inherit"
        >
          <g
            id="UI-/-Navigation-Menu"
            transform="translate(0.000000, -0.000000)"
          >
            <g
              id="dashicons-admin-generic"
              transform="translate(19.000000, 191.000000)"
            >
              <g id="Icon" transform="translate(1.000000, 2.000000)">
                <path
                  d="M20.6,12.4 L17.984,12.4 C17.78,13.24 17.456,14.02 17.012,14.716 L18.86,16.564 L16.34,19.084 L14.492,17.236 C13.796,17.668 13.016,17.992 12.2,18.184 L12.2,20.8 L8.6,20.8 L8.6,18.184 C7.784,17.992 7.004,17.668 6.308,17.236 L4.46,19.084 L1.916,16.54 L3.764,14.692 C3.332,13.996 3.008,13.216 2.816,12.4 L0.2,12.4 L0.2,8.836 L2.804,8.836 C2.996,7.996 3.332,7.216 3.764,6.508 L1.916,4.66 L4.436,2.14 L6.284,3.988 C6.98,3.544 7.772,3.22 8.6,3.016 L8.6,0.4 L12.2,0.4 L12.2,3.016 C13.016,3.208 13.796,3.532 14.492,3.964 L16.34,2.116 L18.884,4.66 L17.036,6.508 C17.468,7.216 17.804,7.996 17.996,8.836 L20.6,8.836 L20.6,12.4 Z M10.4,14.2 C12.392,14.2 14,12.592 14,10.6 C14,8.608 12.392,7 10.4,7 C8.408,7 6.8,8.608 6.8,10.6 C6.8,12.592 8.408,14.2 10.4,14.2 Z"
                  id="Icon-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SettingsIcon;
