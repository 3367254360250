import React, { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { CreateSupplierRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import styled from 'styled-components'
import Checkbox from '../../shared/components/checkbox.component'

interface SupplierFeaturesProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
    withTitle?: boolean
    noFrequent?: boolean
    isSuperUser?: boolean
}

const SupplierFeatures = ({
    isEditing = true,
    rightElement = null,
    withTitle = true,
    noFrequent = false,
}: SupplierFeaturesProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateSupplierRequest
    >()

    return (
        <Section>
            {withTitle && (
                <>
                    <Row>
                        <SectionTitle>Detalhes Gerais</SectionTitle>
                    </Row>
                    <SectionSeparator />
                </>
            )}

            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Nome"
                                name="name"
                                placeholder="Ex: Fornecedor A"
                                onChange={handleChange}
                                value={values.name}
                                error={errors.name}
                            />
                        </RatioContainer>
                        {!noFrequent && (
                            <Checkbox
                                disabled={!isEditing}
                                checked={values.isFrequent}
                                onChange={() =>
                                    setFieldValue(
                                        'isFrequent',
                                        !values.isFrequent
                                    )
                                }
                                containerStyle={{ marginBottom: 16 }}
                                label="É frequente"
                            />
                        )}
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={2 / 5}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                placeholder="Ex: Rua Oliveira do Bairro"
                                name="Morada"
                                title="Rua"
                                onChange={evt => {
                                    const aux = evt.target.value.toUpperCase()
                                    setFieldValue('street', aux)
                                }}
                                value={values.street}
                                error={errors.street}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 5}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Freguesia"
                                placeholder="Ex: Travanca"
                                name="parish"
                                onChange={handleChange}
                                value={values.parish}
                                error={errors.parish}
                            />
                        </RatioContainer>

                        <RatioContainer ratio={1 / 5}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Concelho"
                                placeholder="Ex: Ovar"
                                name="county"
                                onChange={handleChange}
                                value={values.county}
                                error={errors.county}
                            />
                        </RatioContainer>

                        <RatioContainer ratio={1 / 5}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Distrito"
                                placeholder="Ex: Viseu"
                                name="district"
                                onChange={handleChange}
                                value={values.district}
                                error={errors.district}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Telemóvel"
                                placeholder="Ex: 914232123"
                                name="phone"
                                maxLength={9}
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'phone',
                                        evt.target.value.toString().trim()
                                    )
                                }}
                                value={values.phone}
                                error={errors.phone}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Email"
                                placeholder="Ex: ola@gmail.com"
                                name="email"
                                type="email"
                                onChange={handleChange}
                                value={values.email}
                                error={errors.email}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="NIF"
                                placeholder="Ex: 232222123"
                                name="nif"
                                type="number"
                                onChange={handleChange}
                                value={values.nif}
                                error={errors.nif}
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default SupplierFeatures

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
