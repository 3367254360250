import React from "react";

const NotificationIcon = () => {
  return (
    <svg
      viewBox="0 0 20 23"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="inherit"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Vehicles-/-Create-vehicle---Dropdowns-open"
          transform="translate(-21.000000, -921.000000)"
          stroke="inherit"
        >
          <g
            id="UI-/-Navigation-Menu"
            transform="translate(0.000000, -0.000000)"
          >
            <g
              id="Icons-/-Notifications---Active"
              transform="translate(19.000000, 917.000000)"
            >
              <g id="mdi-bell" transform="translate(0.000000, 3.000000)">
                <g id="Icon" transform="translate(3.000000, 2.000000)">
                  <path
                    d="M18,17 L18,18 L0,18 L0,17 L2,15 L2,9 C1.99920557,5.90366232 4.0327335,3.17466784 7,2.29 C6.99507868,2.19339597 6.99507868,2.09660403 7,2 C7,0.8954305 7.8954305,0 9,0 C10.1045695,0 11,0.8954305 11,2 C11.005,2.097 11.005,2.193 11,2.29 C13.9672665,3.17466784 16.0007944,5.90366232 16,9 L16,15 L18,17 L18,17 Z M11,19 C11,20.1045695 10.1045695,21 9,21 C7.8954305,21 7,20.1045695 7,19"
                    id="Icon-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default NotificationIcon;
