import React from "react";

const SearchIcon = () => {
  return (
    <svg
      viewBox="0 0 22 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Vehicles-/-List-view---With-Notifications"
          transform="translate(-137.000000, -135.000000)"
        >
          <g id="Icons-/-Search" transform="translate(113.000000, 125.000000)">
            <g id="UI-/-Search-bar">
              <rect
                id="BG"
                stroke="#979797"
                fill="#FFFFFF"
                x="0"
                y="5.68434189e-14"
                width="393"
                height="44"
                rx="22"
              ></rect>
              <g id="Icon" transform="translate(23.365777, 10.500000)">
                <path
                  d="M21.1080848,21.6328125 L14.457604,14.32875 C15.4896193,12.8634375 16.0478807,11.071875 16.0478807,9.1875 C16.0478807,6.931875 15.2463403,4.816875 13.7969093,3.2221875 C12.3474784,1.6275 10.4166111,0.75 8.36538462,0.75 C6.31415816,0.75 4.38329082,1.6303125 2.93385989,3.2221875 C1.48186813,4.8140625 0.68288854,6.931875 0.68288854,9.1875 C0.68288854,11.4403125 1.48442896,13.5609375 2.93385989,15.1528125 C4.38329082,16.7475 6.31159733,17.625 8.36538462,17.625 C10.0811421,17.625 11.7098312,17.011875 13.0440247,15.88125 L19.6945055,23.1825 L20.8110283,21.9590625 L21.1080848,21.6328125 Z M12.4217425,13.6425 C11.3359498,14.8321875 9.89676217,15.4875 8.36538462,15.4875 C6.83400706,15.4875 5.39481947,14.8321875 4.30902669,13.6425 C3.22579474,12.45 2.62912088,10.869375 2.62912088,9.1875 C2.62912088,7.505625 3.22579474,5.9221875 4.30902669,4.7325 C5.39481947,3.5428125 6.83400706,2.8875 8.36538462,2.8875 C9.89676217,2.8875 11.3385106,3.54 12.4217425,4.7325 C13.5049745,5.925 14.1016484,7.505625 14.1016484,9.1875 C14.1016484,10.869375 13.5049745,12.4528125 12.4217425,13.6425 Z"
                  id="Icon-Shape"
                  fill="#CFD5DB"
                ></path>
                <g
                  id="Icons-/-Search"
                  transform="translate(1.000000, 0.000000)"
                ></g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SearchIcon;
