import React from 'react'
import { useFormikContext } from 'formik'
import { CreateContactRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import Dropdown from '../../shared/components/dropdown.component'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
import { DateInput } from '../../shared/components/date-input.component'
import styled from 'styled-components'
import { HourInput } from '../../shared/components/hour-input.component'
import TextArea from '../../shared/components/text-area.component'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { LoggedUser } from '../../shared/shared.models'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'

interface ContactManagementProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
}

const ContactManagement = ({
    isEditing = true,
    rightElement = null,
}: ContactManagementProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateContactRequest
    >()

    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )
    const users = useSelector(
        (state: IStoreInterface) => state.adminReducer.users
    )

    const isAdmin = useIsAdmin()

    return (
        <Section>
            <Row>
                <SectionTitle>Gestão do Contato</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <DateInput
                                inEdition={isEditing}
                                placeholder="DD-MM-AAAA"
                                name="date"
                                title="Data de Contato"
                                onChange={evt => {
                                    setFieldValue(
                                        'date',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.date ? values.date : ''}
                                error={errors.date}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <HourInput
                                inEdition={isEditing}
                                placeholder="HH:MM"
                                name="hour"
                                title="Hora de Contato"
                                onChange={evt => {
                                    setFieldValue(
                                        'hour',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.hour ? values.hour : ''}
                                error={errors.hour}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <Dropdown
                                inEdition={isEditing}
                                label="Origem do Contacto"
                                placeholder="Escolha a origem do contato"
                                value={values.font}
                                selectValue={opt =>
                                    setFieldValue('font', opt.key)
                                }
                                clearValue={() =>
                                    setFieldValue('font', undefined)
                                }
                                options={DropdownUtils.getContactFontdDropdownOptions()}
                                error={errors.font}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <Dropdown
                                inEdition={isEditing}
                                required={true}
                                label="Estado do Contacto"
                                placeholder="Escolha o estado do contato"
                                value={values.status || ''}
                                selectValue={opt =>
                                    setFieldValue('status', opt.key)
                                }
                                options={DropdownUtils.getContactStatusdDropdownOptions()}
                                error={errors.status}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                disabled={true}
                                title="Criador do Contato"
                                required={true}
                                name="creatorUserUuid"
                                value={loggedUser.username}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <Dropdown
                                inEdition={isEditing}
                                disabled={!isAdmin}
                                label="Associação do Contacto"
                                required={true}
                                placeholder={
                                    !isAdmin
                                        ? loggedUser.username
                                        : 'Escolha um utilizador'
                                }
                                value={values.contactUserUuid}
                                clearValue={() =>
                                    setFieldValue('contactUserUuid', undefined)
                                }
                                selectValue={
                                    !isAdmin
                                        ? () =>
                                              setFieldValue(
                                                  'contactUserUuid',
                                                  loggedUser.uuid
                                              )
                                        : opt =>
                                              setFieldValue(
                                                  'contactUserUuid',
                                                  opt.key
                                              )
                                }
                                options={
                                    !isAdmin
                                        ? [
                                              {
                                                  key: loggedUser.uuid,
                                                  label: loggedUser.username,
                                              },
                                          ]
                                        : DropdownUtils.getUsersDropdownOptionsSale(
                                              users
                                          )
                                }
                                error={errors.contactUserUuid}
                            />
                        </RatioContainer>

                        <RatioContainer ratio={2 / 4}>
                            <TextArea
                                inEdition={isEditing}
                                containerStyles={{ marginBottom: 24 }}
                                title="Observações"
                                placeholder="Ex: O contato comprou ..."
                                name="observation"
                                onChange={handleChange}
                                value={
                                    values?.observation
                                        ? values?.observation?.replace(
                                              /  +/g,
                                              ' '
                                          )
                                        : ''
                                }
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default ContactManagement

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
