import React from 'react'
import { Vehicle } from '../../shared/shared.models'
import { useRoutes } from 'hookrouter'
import { VehicleDetailsPage } from './vehicle-details.page'
import { SaleDetailsPage } from '../../sales/pages/sale-details.page'
import { CreateSalePage } from '../../sales/pages/create-sale/create-sale.page'
import { useSelector, useDispatch } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { createSelector } from 'reselect'
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import cloneDeep from 'lodash/cloneDeep'

export interface IBaseInterface {
    vehicle: Vehicle
}

const routes = {
    '/': () => (vehicle: Vehicle) => <VehicleDetailsPage vehicle={vehicle} />,
    '/new-sale': () => (vehicle: Vehicle) => (
        <CreateSalePage vehicle={vehicle} />
    ),
    '/sale-details': () => (vehicle: Vehicle) => (
        <SaleDetailsPage vehicle={vehicle} />
    ),
}

interface VehiclePageProps {
    vehicleId: string
}

/**
 * Function to get the vehicle from the redux store
 */
const vehicleSelector = () =>
    createSelector(
        (state: IStoreInterface) => state.adminReducer.vehicles,
        (state: IStoreInterface, vehicleId: string | undefined) => vehicleId,
        (vehicles, vehicleId) => vehicles.find(v => v.uuid === vehicleId)
    )

/**
 * This page handles the routing of the VehiclePage
 * @param vehicleId {string}
 */
export const VehiclePage = ({ vehicleId }: VehiclePageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()

    /**
     * Memoize the selector. This means that we only change this selector in case the vehicleId changes
     */
    const memoedSelector = React.useMemo(vehicleSelector, [vehicleId])

    /**
     * Gets the vehicle from the redux store
     */
    const vehicle = cloneDeep(
        useSelector((state: IStoreInterface) =>
            memoedSelector(state, vehicleId)
        ) as Vehicle
    )

    const [isLoading, setIsLoading] = React.useState(true)

    /**
     * Calls the endpoint to fetch the vehicle information
     */
    const fetchVehicleData = React.useCallback(async () => {
        try {
            const res = await AdminApi.methods.getVehicle(vehicleId)
            dispatch(
                AdminActions.methods.updateVehicleSuccessAction(
                    res.data.vehicle
                )
            )
        } catch (e) {
            alert.error(
                'Houve um erro a ir buscar a informação deste veículo. Por favor tente mais tarde'
            )
        } finally {
            setIsLoading(false)
        }
    }, [vehicleId, dispatch, alert])

    React.useEffect(() => {
        fetchVehicleData()
    }, [fetchVehicleData])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação do veículo</LoadingText>
            </Column>
        )
    }

    if (!vehicle) {
        throw new Error('Este veículo não existe')
    } else {
        return match(vehicle)
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
