import { AdminReducerInterface, adminReducer } from '../admin/admin.store'
import { createLogger } from 'redux-logger'
import { combineReducers, createStore, applyMiddleware, Store } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { setTokenInClient } from './axios.config'
import { PayloadAction } from '../shared/shared.interface'

export interface IStoreInterface {
    adminReducer: AdminReducerInterface
}

function getStore() {
    const logger = createLogger({ collapsed: true })

    const shouldReadPreviousStoreState = true

    const saveStore = (storeState: IStoreInterface) => {
        //sessionStorage.setItem('store', JSON.stringify(storeState))
    }

    const retrieveStore = (): IStoreInterface | undefined => {
        try {
            const token = localStorage.getItem('token')
            const user: any = localStorage.getItem('user')
            const company = localStorage.getItem('company')
            const financials = localStorage.getItem('financials')

            if (token && user && company) {
                setTokenInClient(token)
                return {
                    adminReducer: {
                        token,
                        vehicles: [],
                        categories: [],
                        companies: [],
                        costTypes: [],
                        financials: financials ? JSON.parse(financials) : [],
                        notifications: [],
                        stands: [],
                        users: [],
                        user: JSON.parse(user),
                        company: JSON.parse(company),
                    },
                }
            }

            // if (true) {
            //     const parsedState = JSON.parse(
            //         previousStoreState
            //     ) as IStoreInterface
            //     if (parsedState.adminReducer.token) {
            //     }
            //     return parsedState
            // }
        } catch {
            // TODO: Handle errors
        }

        return undefined
    }

    const reducers = combineReducers({
        adminReducer,
    })

    const store: Store<any, PayloadAction<any>> = createStore(
        reducers,
        shouldReadPreviousStoreState ? retrieveStore() : {},
        composeWithDevTools(applyMiddleware(logger))
    )

    store.subscribe(() => {
        saveStore(store.getState())
    })

    return store
}

export default getStore()
