export enum RoleEnum {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
    SELLER = 'SELLER',
    MANAGER = 'MANAGER',
    EDITOR1 = 'EDITOR1',
    EDITOR2 = 'EDITOR2',
    EDITOR3 = 'EDITOR3',
    WORKSHOP = 'WORKSHOP',
}
export enum FuelEnum {
    DIESEL = 'DIESEL',
    GAS = 'GAS',
    ELECTRIC = 'ELECTRIC',
    GASOLINE = 'GASOLINE',
    HYBRID = 'HYBRID',
}

export enum VehicleTypeEnum {
    LIGHT_PASSENGERS = 'LIGHT_PASSENGERS',
    COMMERCIAL = 'COMMERCIAL',
    HEAVY_PASSENGERS = 'HEAVY_PASSENGERS',
    HEAVY_GOODS = 'HEAVY_GOODS',
    MOTORCYCLES = 'MOTORCYCLES',
}

export enum StandsEnum {
    ILHAVO = 'ILHAVO',
}

export enum VisibilityStatusEnum {
    ADMIN_ONLY = 'ADMIN_ONLY',
    SELLER_ONLY = 'SELLER_ONLY',
    ALL = 'ALL',
    SUPER_ADMIN_ONLY = 'SUPER_ADMIN_ONLY',
}

export enum AvailabilityEnum {
    REPARING = 'REPARING',
    READY_FOR_SALE = 'READY_FOR_SALE',
    SOLD = 'SOLD',
    DELIVERED = 'DELIVERED',
    VERIFYING = 'VERIFYING',
}

export enum CarStateEnum {
    NEW = 'NEW',
    USED = 'USED',
    SEMI_NEW = 'SEMI_NEW',
}

export enum NotificationTypeEnum {
    NEW_VEHICLE = 'NEW_VEHICLE',
    UPDATED_VEHICLE = 'UPDATED_VEHICLE',
    SOLD_VEHICLE = 'SOLD_VEHICLE',
    IN_STOCK_VEHICLE = 'IN_STOCK_VEHICLE',
}

export enum ShiftEnum {
    MANUAL = 'MANUAL',
    AUTOMATIC = 'AUTOMATIC',
}

export enum FontEnum {
    OLD_CLIENT = 'OLD_CLIENT',
    EMAIL = 'EMAIL',
    EVENTS = 'EVENTS',
    STAND_TICKET = 'STAND_TICKET',
    RADIO = 'RADIO',
    RECOMENDATION = 'RECOMENDATION',
    SOCIAL_NETWORK = 'SOCIAL_NETWORK',
    OTHER = 'OTHER',
    OTHER_WEB_SV = 'OTHER_WEB_SV',
}

export enum StatusEnum {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    SOLD = 'SOLD',
    ORDERED = 'ORDERED',
}

export enum OriginEnum {
    OLD_CUSTOMER = 'OLD_CUSTOMER',
    RECOMMENDATION = 'RECOMMENDATION',
    TEMPORARY_CUSTOMER = 'TEMPORARY_CUSTOMER',
    SOCIAL_NETWORK = 'SOCIAL_NETWORK',
    EMAIL = 'EMAIL',
    EVENTS = 'EVENTS',
    RADIO = 'RADIO',
    OTHER = 'OTHER',
    WEBSITE = 'WEBSITE',
    OTHER_WEB_SV = 'OTHER_WEB_SV',
}
