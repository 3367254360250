import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import { CustomNotification, LoggedUser } from '../shared.models'
import { Typography, Colors } from '../../configs/styled.config'
import { Row, Column } from '../shared.styled'
import CloseIcon from '../../assets/icons/close.icon'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { AdminApi } from '../../admin/admin.api'
import { AdminActions } from '../../admin/admin.store'
import { useDispatch } from 'react-redux'
import moment, { Moment } from 'moment'
import { NotificationRow } from './notification-row.component'
import { useAlert } from 'react-alert'
import SmallButton from './small-button.component'
import TrashIcon from '../../assets/icons/trash.icon'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from './modals/confirmation.modal'
import { NotificationTypeEnum } from '../shared.enums'

interface NotificationsDrawerProps {
    isDrawerVisible: boolean
    notifications: CustomNotification[]
    user: LoggedUser
    setDrawerVisibility: (visibility: boolean) => void
}

const NotificationsDrawer = ({
    isDrawerVisible,
    notifications,
    user,
    setDrawerVisibility,
}: NotificationsDrawerProps) => {
    // Hooks initialization
    const dispatch = useDispatch()
    const alert = useAlert()
    const [showModal, hideModal] = useModal(() => (
        <ConfirmationModal
            title="Eliminar todas as notificações"
            description="Está prestes a eliminar todas as notificações. Tem a certeza?"
            onCancel={() => hideModal()}
            onClick={() => deleteAllNotifications()}
        />
    ))

    const deleteAllNotifications = async () => {
        try {
            await AdminApi.methods.deleteAllNotifications(user.uuid)
            dispatch(AdminActions.methods.deleteAllNotificationsSuccess())
            alert.success('As notificações foram eliminadas com sucesso')
        } catch (e) {
            alert.error('Erro ao eliminar as notificações')
        } finally {
            hideModal()
        }
    }

    // Local state
    const [showDisplayAnimation, setShowDisplayAnimation] = React.useState(
        false
    )

    // Effects
    React.useEffect(() => {
        if (isDrawerVisible && !showDisplayAnimation) {
            setShowDisplayAnimation(true)
        }
    }, [isDrawerVisible, showDisplayAnimation])

    const unreadNotifications = notifications.filter(n => !n.isRead)

    const _toggleNotificationStatus = (
        notif: CustomNotification,
        value?: boolean
    ) => {
        AdminApi.methods
            .readNotification(notif.uuid, value ? value : !notif.isRead)
            .then(() => {
                dispatch(
                    AdminActions.methods.updateNotificationAction(
                        notif.uuid,
                        !notif.isRead
                    )
                )
            })
            .catch(e => {
                alert.error(
                    'Houve um erro a actualizar a notificação, por favor tente mais tarde'
                )
            })
    }

    const _deleteNotification = (notification: CustomNotification) => {
        AdminApi.methods
            .deleteNotification(notification.notificationUuid)
            .then(res => {
                dispatch(
                    AdminActions.methods.deleteNotificationSuccessAction(
                        notification.notificationUuid
                    )
                )
            })
            .catch(e => {
                alert.error(
                    'Houve um erro a eliminar a notificação, por favor tente mais tarde'
                )
            })
    }

    const separateNotificationByDay = () => {
        const auxNotifications = [...notifications]
        const notificationMap = new Map<string, CustomNotification[]>()
        auxNotifications.sort((n1, n2) => {
            const isBefore = moment(n1.createdAt).isBefore(n2.createdAt)
            return isBefore ? 1 : -1
        })

        auxNotifications.forEach(notification => {
            const momentDate = moment(notification.createdAt)
            const dayOfNotification = getDayTranslation(momentDate)

            if (notificationMap.has(dayOfNotification)) {
                const previousValues =
                    notificationMap.get(dayOfNotification) || []
                notificationMap.set(dayOfNotification, [
                    ...previousValues,
                    notification,
                ])
            } else {
                notificationMap.set(dayOfNotification, [notification])
            }
        })

        return notificationMap
    }

    const _onNotificationClick = (notif: CustomNotification) => {
        if (!notif.isRead) {
            _toggleNotificationStatus(notif, true)
        }
        if (notif.notificationType !== NotificationTypeEnum.SOLD_VEHICLE) {
            navigate(
                RoutesEnum.VEHICLE_PAGE.replace(
                    ':vehicleId',
                    notif.payload.uuid
                ),
                true
            )
        }

        setDrawerVisibility(false)
    }

    const getDayTranslation = (momentDate: Moment) => {
        if (momentDate.isSame(moment(), 'days')) {
            return 'Hoje'
        }
        if (momentDate.isSame(moment().subtract(1, 'days'))) {
            return 'Ontem'
        } else {
            return momentDate.format('DD-MM-YYYY')
        }
    }

    const renderNotifications = () => {
        const notifMap = separateNotificationByDay()
        const notificationGroup: React.ReactNode[] = []
        for (const [key, values] of notifMap) {
            notificationGroup.push(
                <NotificationGroup key={`notif-group-${key}`}>
                    <NotificationDay>{key}</NotificationDay>
                    <Separator />
                    {values
                        .sort((a, b) => {
                            return a.isRead ? 1 : -1
                        })
                        .map(notif => (
                            <NotificationRow
                                key={notif.notificationUuid}
                                notif={notif}
                                onNotificationClick={_onNotificationClick}
                                onNotificationDelete={_deleteNotification}
                                onToggleNotification={_toggleNotificationStatus}
                            />
                        ))}
                </NotificationGroup>
            )
        }
        return notificationGroup
    }

    return (
        <>
            <CommonContainer
                isVisible={isDrawerVisible}
                shouldDisplayAnimation={showDisplayAnimation}
            >
                <Row style={{ marginBottom: 24 }}>
                    <NotificationsTitle>
                        Notificações ({unreadNotifications.length})
                    </NotificationsTitle>
                    <span
                        onClick={() => setDrawerVisibility(false)}
                        style={{
                            cursor: 'pointer',
                            marginLeft: 'auto',
                            width: 20,
                            height: 20,
                        }}
                    >
                        <CloseIcon />
                    </span>
                </Row>
                <Row style={{ marginBottom: 12, justifyContent: 'flex-end' }}>
                    <SmallButton
                        iconComponent={
                            <TrashIcon
                                style={{
                                    fill: Colors['gold-pmauto'],
                                    stroke: Colors['gold-pmauto'],
                                }}
                            />
                        }
                        label="Eliminar notificações"
                        onClick={() => showModal()}
                    />
                </Row>
                <Column>{renderNotifications()}</Column>
            </CommonContainer>
            <NotificationOverlay
                onClick={() => setDrawerVisibility(false)}
                isVisible={isDrawerVisible}
                shouldDisplayAnimation={showDisplayAnimation}
            />
        </>
    )
}

export default NotificationsDrawer

const slideIn = keyframes`
  0% {
    transform: translateX(0);
  }
  
  100% {
    transform: translateX(611px);
  }

`

const slideOut = keyframes`
  0% {
    transform: translateX(611px);
  }

  100% {
    transform: translateX(0);
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
    z-index: -1;
  }
  
  100% {
    opacity: 1;
    z-index: 27;
  }

`

const fadeOut = keyframes`
  0% {
    opacity: 1;
    z-index: 27;
  }

  100% {
    opacity: 0;
    z-index: -1;
  }
`

const slideInAnimation = css`
    animation: ${slideIn} 0.25s ease-out forwards;
`

const slideOutAnimation = css`
    animation: ${slideOut} 0.25s ease-out forwards;
`

const fadeInAnimation = css`
    animation: ${fadeIn} 0.25s ease-out forwards;
`

const fadeOutAnimation = css`
    animation: ${fadeOut} 0.25s ease-out forwards;
`

const NotificationsTitle = styled('h1')`
    ${Typography.H2_Regular_Left}
`

interface CommonContainerProps {
    isVisible: boolean
    shouldDisplayAnimation: boolean
}

export const CommonContainer = styled('div')<CommonContainerProps>`
    left: -611px;
    position: fixed;
    width: 43%;
    max-width: 611px;
    padding: 36px 24px;
    height: 100%;
    background-color: white;
    z-index: 28;
    box-shadow: 0 3px 10px 0 #00000019, 0 3px 6px 0 #8e8e8e3a;
    overflow: auto;
    ${props =>
        props.shouldDisplayAnimation
            ? props.isVisible
                ? slideInAnimation
                : slideOutAnimation
            : ''};

    @media (min-width: 1280px) {
        min-width: 611px;
    }

    ::-webkit-scrollbar {
        background-color: white;
        width: 14px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #fafafa;
        border: 3px solid white;
    }
`

interface NotificationOverlayProps {
    isVisible: boolean
    shouldDisplayAnimation: boolean
}

export const NotificationOverlay = styled('div')<NotificationOverlayProps>`
    background-color: rgba(51, 51, 51, 0.4);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    opacity: 0;
    ${props =>
        props.shouldDisplayAnimation
            ? props.isVisible
                ? fadeInAnimation
                : fadeOutAnimation
            : ''};
`

const NotificationGroup = styled(Column as any)`
    margin-bottom: 16px;
`

const NotificationDay = styled('span')`
    ${Typography.H4_Low_contrast_Left};
    font-size: 15px;
    margin-bottom: 2px;
`

const Separator = styled('div')`
    height: 1px;
    width: 100%;

    background-color: #8996a6;
    margin-bottom: 8px;
`
