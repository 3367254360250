import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import { CircleLoader } from 'react-spinners'

interface PrimaryButtonProps extends HTMLAttributes<HTMLButtonElement> {
    label: string
    type?: 'submit' | 'button'
    isLoading?: boolean
    redTheme?: boolean
    disabled?: boolean
    greenTheme?: boolean
    goldTheme?: boolean
}

const PrimaryButton = ({
    label,
    type = 'button',
    isLoading = false,
    disabled = false,
    redTheme,
    greenTheme,
    goldTheme,
    ...rest
}: PrimaryButtonProps) => {
    return (
        <StyledButton
            disabled={disabled}
            redTheme={redTheme}
            greenTheme={greenTheme}
            goldTheme={goldTheme}
            type={type}
            {...rest}
        >
            {isLoading ? (
                <LoadingContainer>
                    <CircleLoader color="white" size={16} />
                </LoadingContainer>
            ) : (
                label
            )}
        </StyledButton>
    )
}

export default PrimaryButton

interface Themes {
    redTheme?: boolean
    greenTheme?: boolean
    goldTheme?: boolean
}

export const StyledButton = styled('button')<Themes>`
    background-color: ${props =>
        props.redTheme
            ? Colors['primary-digiauto']
            : props.greenTheme
            ? '#2e990e'
            : props.goldTheme
            ? Colors['gold-pmauto']
            : 'white'};
    padding: 3px 16px;
    border-radius: 18px;
    outline: 0;
    height: 36px;
    cursor: pointer;

    ${props =>
        props.redTheme || props.greenTheme || props.goldTheme
            ? 'border: none'
            : 'border: solid 1px #afafaf'};

    ${props =>
        props.redTheme || props.greenTheme || props.goldTheme
            ? Typography['Body_Text_#1_White_Center']
            : Typography['Body_Text_#1_High_Contrast_Center']}
    ${props =>
        props.disabled
            ? ' background-color:grey;'
            : `:hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }`};
`

export const LoadingContainer = styled('div')`
    margin: auto;
    width: fit-content;
`
