import React from 'react'
import { IBaseInterface } from './company.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { useIsSuperAdmin } from '../../shared/hooks/use-is-super-admin.hook'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { CreateCompanyRequest } from '../admin.interfaces'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'

import CompanyFeatures from '../components/company-features.component'

export const CompanyDetailsPage = ({ company }: IBaseInterface) => {
    // Hooks
    const isSuperAdmin = useIsSuperAdmin()
    const dispatch = useDispatch()
    const alert = useAlert()
    // Redux state
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)

                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
        }
    }

    /**
     * Calls the API endpoint to update a stand and handles the
     * loading and error states of the form
     */
    async function updateCompany(
        values: CreateCompanyRequest,
        actions: FormikHelpers<CreateCompanyRequest>
    ) {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.updateCompany(
                company.uuid,
                values
            )
            dispatch(
                AdminActions.methods.updateCompanySuccessAction(
                    res.data.company
                )
            )
            setIsEditing(false)
            alert.success('Empresa gravada com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o stand. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    /**
     * Calls the API endpoint to delete a company
     */
    async function eliminateCompany() {
        try {
            const company_uuid = company.uuid
            const res = await AdminApi.methods.deleteCompany(company.uuid)
            if (res.status === 200)
                dispatch(
                    AdminActions.methods.deleteCompanySuccessAction(
                        company_uuid
                    )
                )
            setIsEditing(false)
            alert.success('Empresa apagada com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar apagar a empresa. Por favor tente mais tarde'
            )
        } finally {
            navigate(RoutesEnum.COMPANIES_LIST)
        }
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...company,
        }
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit And Sell vehicle buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                    />
                </>
            )
        }
        return (
            <>
                <PrimaryButton
                    label="Editar empresa"
                    onClick={() => setIsEditing(true)}
                />

                <PrimaryButton
                    isLoading={isSubmitting}
                    label="Eliminar empresa"
                    onClick={eliminateCompany}
                    redTheme={company.active ? true : false}
                    greenTheme={company.active ? false : true}
                />
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateCompanyRequest>
                initialValues={getInitialValues()}
                onSubmit={updateCompany}
            >
                {({ values, setFieldValue, dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={company.name}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>
                        <CompanyFeatures
                            isEditing={isSuperAdmin && isEditing}
                        />
                        {/* <ListCompanies companies={companies} /> */}
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
