import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Column, Row } from '../../../shared/shared.styled'
import { Formik } from 'formik'
import TextInput from '../../../shared/components/text-input.component'
import PrimaryButton from '../../../shared/components/button.component'
import * as Yup from 'yup'
import { Cost } from '../../admin.interfaces'
import styled from 'styled-components'
import { Typography, Colors } from '../../../configs/styled.config'
import CloseIcon from '../../../assets/icons/close.icon'
import { SelectedCost } from '../costs-section.component'
import Dropdown from '../../../shared/components/dropdown.component'
import { DropdownUtils } from '../../../shared/utils/dropdowns.utils'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../../configs/store.config'

interface AddCostModalProps {
    addCost: (values: Cost) => void
    editCost: (values: SelectedCost) => void
    hideModal: () => void
    selectedCost: SelectedCost | undefined
}

const formikValidation = Yup.object().shape({
    costType: Yup.string()
        .required('Campo obrigatório')
        .min(1, 'Escolha um tipo'),
    value: Yup.number()
        .required('Campo obrigatório')
        .min(1, 'Valor tem de ser maior que 0'),
    date: Yup.string().required('Campo obrigatório'),
})

export const AddCostModal = ({
    addCost,
    editCost,
    hideModal,
    selectedCost,
}: AddCostModalProps) => {
    // Redux state
    const costTypes = useSelector(
        (state: IStoreInterface) => state.adminReducer.costTypes
    )

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const [executing, setExecuting] = useState(false)

    // Initial values
    const initialValues = selectedCost
        ? selectedCost.cost
        : {
              costType: '',
              value: 0,
              description: '',
              associatedInvoice: '',
              date: '',
          }

    async function submitCost(values: Cost) {
        setExecuting(true)
        if (selectedCost) {
            editCost({ cost: values, costIdx: selectedCost.costIdx })
        } else {
            addCost(values)
        }
        await sleep(1000)
        setExecuting(false)
    }

    return (
        <ReactModal
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: { zIndex: 1000 },
                content: { width: 500, margin: 'auto', height: 'fit-content' },
            }}
            isOpen={true}
        >
            <Column>
                <Row>
                    <StyledTitle>Novo custo</StyledTitle>
                    <CloseIcon
                        onClick={() => hideModal()}
                        style={{
                            marginLeft: 'auto',
                            width: 18,
                            height: 18,
                            color: Colors['gold-pmauto'],
                        }}
                    />
                </Row>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submitCost}
                    validationSchema={formikValidation}
                    validateOnChange={false}
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        values,
                        setFieldValue,
                    }) => {
                        return (
                            <StyledColumn>
                                <Dropdown
                                    placeholder="Escolha tipo de custo"
                                    label="Tipo de custo"
                                    options={DropdownUtils.getVehicleCostTypeOptions(
                                        costTypes
                                    )}
                                    value={values.costType}
                                    selectValue={opt =>
                                        setFieldValue('costType', opt.key)
                                    }
                                    error={errors.costType}
                                />
                                <TextInput
                                    value={values.description}
                                    onChange={handleChange}
                                    name="description"
                                    title="Descrição"
                                />
                                <Row
                                    style={{ justifyContent: 'space-between' }}
                                >
                                    <div style={{ width: '45%' }}>
                                        <TextInput
                                            value={values.date}
                                            onChange={handleChange}
                                            name="date"
                                            type="date"
                                            error={errors.date}
                                            title="Data"
                                        />
                                    </div>
                                    <div style={{ width: '45%' }}>
                                        <TextInput
                                            value={values.value}
                                            onChange={handleChange}
                                            name="value"
                                            error={errors.value}
                                            type="number"
                                            title="Custo (€)"
                                        />
                                    </div>
                                </Row>
                                <TextInput
                                    value={values.associatedInvoice}
                                    onChange={handleChange}
                                    name="associatedInvoice"
                                    error={errors.associatedInvoice}
                                    title="OR (Ordem de reparação)"
                                />
                                <PrimaryButton
                                    label="Guardar custo"
                                    type="button"
                                    redTheme={true}
                                    onClick={() => handleSubmit()}
                                    disabled={executing || false}
                                />
                            </StyledColumn>
                        )
                    }}
                </Formik>
            </Column>
        </ReactModal>
    )
}

const StyledTitle = styled('h1')`
    ${Typography.H2_Regular_Left}
`

const StyledColumn = styled(Column as any)`
    padding: 24px 40px;

    > div {
        margin-bottom: 24px;
    }
`
