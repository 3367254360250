import React from 'react'
import { Section, SectionTitle, Row, Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { CreateVehicleRequest } from '../admin.interfaces'
import { useFormikContext } from 'formik'
import { Supplier } from '../../shared/shared.models'
import { useModal } from 'react-modal-hook'
import { AddSupplierModal } from './modals/add-supplier.modal'
import SmallButton from '../../shared/components/small-button.component'
import PlusIcon from '../../assets/icons/plus.icon'
import { Colors, Typography } from '../../configs/styled.config'
import { SupplierTable } from './supplier-table.component'
import TextArea from '../../shared/components/text-area.component'

export interface SelectedSupplier {
    supplier: Supplier
    supplierIdx: number
}

interface SupplierSectionProps {
    isEditing?: boolean
}

export const SupplierSection = ({ isEditing = true }: SupplierSectionProps) => {
    const formikProps = useFormikContext<CreateVehicleRequest>()

    const [selectedSupplier, setSelectedSupplier] = React.useState<
        SelectedSupplier | undefined
    >(undefined)
    // Modal
    const [showModal, hideModal] = useModal(
        () => (
            <AddSupplierModal
                selectedSupplier={selectedSupplier}
                hideModal={hideModal}
                addSupplier={addSupplier}
                editSupplier={editSupplier}
            />
        ),
        [selectedSupplier]
    )

    /**
     * Adds a supplier to our formik values
     * @param values {Supplier}
     */
    const addSupplier = (values: Supplier) => {
        formikProps.setFieldValue('supplier', values)
        if (values) {
            const supIdx: number = 0
            const supValues: SelectedSupplier = {
                supplier: values,
                supplierIdx: supIdx,
            }
            setSelectedSupplier(supValues)
        }

        hideModal()
    }

    /**
     * Replaces a supplier with the new values
     * @param obj SelectedSupplier
     */
    const editSupplier = (obj: SelectedSupplier) => {
        const previousSupplier = formikProps.values
        if (previousSupplier) {
            formikProps.setFieldValue('supplier', obj.supplier)
        }
        setSelectedSupplier(undefined)
        hideModal()
    }

    /**
     * Show the edit supplier modal
     * @param supplierIdx SelectedSupplier
     */
    const showEditSupplierModal = (supplierIdx: number) => {
        const supplier = formikProps.values.supplier
        if (supplier) {
            setSelectedSupplier({ supplier, supplierIdx })
            showModal()
        }
    }

    return (
        <Section>
            <SectionTitle>
                <Row>
                    Gestão do Fornecedor
                    <Row style={{ marginLeft: 'auto' }}>
                        <SmallButton
                            disabled={
                                !isEditing ||
                                formikProps.values.supplier !== null
                            }
                            iconComponent={
                                <PlusIcon
                                    style={{
                                        fill: isEditing
                                            ? Colors['gold-pmauto']
                                            : '#d8d8d8',
                                    }}
                                />
                            }
                            label="Adicionar novo fornecedor"
                            onClick={() => showModal()}
                        />
                    </Row>
                </Row>
            </SectionTitle>
            <Column style={{ marginTop: 16 }}>
                {formikProps.values.supplier ? (
                    <SupplierTable
                        editSupplier={showEditSupplierModal}
                        supplier={formikProps.values.supplier}
                        isEditing={isEditing}
                    />
                ) : (
                    <StyledText>Não existe fornecedor.</StyledText>
                )}
                <Row style={{ alignItems: 'flex-start', marginTop: 16 }}>
                    <TextArea
                        inEdition={isEditing}
                        disabled={!isEditing || !formikProps.values.supplier}
                        title="Notas"
                        placeholder="Ex: O fornecedor ..."
                        name="supplierNotes.notes"
                        onChange={e =>
                            formikProps.setFieldValue(
                                'supplierNotes.notes',
                                e.target.value
                            )
                        }
                        value={
                            formikProps.values?.supplierNotes?.notes
                                ? formikProps.values?.supplierNotes?.notes.replace(
                                      /  +/g,
                                      ' '
                                  )
                                : ''
                        }
                    />
                </Row>
            </Column>
        </Section>
    )
}

export default SupplierSection

const StyledText = styled('span')`
    align-self: center;
    ${Typography.H1_Regular_Center};
    font-size: 20px;
    margin-top: 24px;
`
