import axios from 'axios'

const IP = process.env.REACT_APP_API_IP
const PORT = process.env.REACT_APP_API_PORT
let url = ''

if (PORT === '8081') url = `http://${IP}:${PORT}`
else url = `https://${IP}:${PORT}`

export const AxiosInstance = axios.create({
    baseURL: IP && PORT ? url : 'http://localhost:5000',
    timeout: 180000,
})

export function removeTokenFromClient() {
    const newHeaders = { ...AxiosInstance.defaults.headers }
    delete newHeaders.Authorization
    AxiosInstance.defaults.headers = newHeaders
}

AxiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error?.response)
        const path = window.location.href
        //const status = error?.response?.status
        removeTokenFromClient()
        localStorage.clear()
        if (!path.includes('/login')) {
            window.location.href = '/login'
            return Promise.reject(error)
        }
    }
)

// axios.interceptors.response.use(
//     response => {
//         console.log('EXPIRED TOKEN!')
//         return response
//     },
//     error => {
//         const dispatch = useDispatch()
//         const status = error.response?.status || 500
//         if (status === 401) {
//             console.log('EXPIRED TOKEN!')
//             removeTokenFromClient()
//             dispatch(AdminActions.methods.logoutAction())
//             navigate(RoutesEnum.LOGIN)
//             localStorage.clear()
//         } else {
//             return Promise.reject(error) // Delegate error to calling side
//         }
//     }
// )

export function setTokenInClient(token: string) {
    AxiosInstance.defaults.headers = {
        ...AxiosInstance.defaults.headers,
        Authorization: `Bearer ${token}`,
    }
}
