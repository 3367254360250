import React from "react";
import styled from "styled-components";
import { Colors } from "../../configs/styled.config";
import PlusIcon from "../../assets/icons/plus.icon";

interface ActionButtonProps {
  onClick: (args?: any) => void;
}

const ActionButton = ({ onClick }: ActionButtonProps) => {
  return (
    <ActionButtonContainer onClick={onClick}>
      <PlusIcon />
    </ActionButtonContainer>
  );
};

export default ActionButton;

export const ActionButtonContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 53px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.5);
  background-color: ${Colors["gold-pmauto"]};
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;

  :hover {
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
  }

  svg {
    width: 20px;
    height: 20px;
    fill: white;
  }
`;
