import React from 'react'
import { ColumnProps, TableValue } from './table.component'
import styled from 'styled-components'
import { RatioContainer, RowTable } from '../../shared.styled'
import { Colors } from '../../../configs/styled.config'
import { useIsAdmin } from '../../hooks/use-is-admin.hook'
import { HeaderProps } from './table-headers.component'
import { VisibilityStatusEnum } from '../../shared.enums'

interface TableRowProps<T> {
    entity: T
    columns: ColumnProps[]
    rowStyles?: (value: T) => React.CSSProperties
    onClick?: (value: T) => void
}

export const TableRow = <T extends TableValue>({
    entity,
    columns,
    rowStyles,
    onClick,
}: TableRowProps<T>) => {
    const isAdmin = useIsAdmin()

    const renderCell = (column: ColumnProps) => {
        return (
            <Cell
                key={`cell-${column.key}`}
                ratio={column.ratio}
                alignment={column.alignment}
                id={`cell-${column.key}`}
            >
                {column.renderFunction ? (
                    column.renderFunction(entity)
                ) : (
                    <CellText>
                        {!!entity[column.key] ? entity[column.key] : '-'}
                    </CellText>
                )}
            </Cell>
        )
    }

    return (
        <Container
            style={rowStyles ? rowStyles(entity) : {}}
            onClick={() => (onClick ? onClick(entity) : null)}
        >
            {columns.map(column => {
                let visibility = column.visibility || VisibilityStatusEnum.ALL
                switch (visibility) {
                    case VisibilityStatusEnum.ALL:
                        return renderCell(column)
                    case VisibilityStatusEnum.ADMIN_ONLY:
                        return isAdmin && renderCell(column)
                    case VisibilityStatusEnum.SELLER_ONLY:
                        return !isAdmin && renderCell(column)
                    default:
                        return null
                }
            })}
        </Container>
    )
}

const Cell = styled(RatioContainer as any)<HeaderProps>`
    display: flex;
    justify-content: ${props =>
        props.alignment ? props.alignment : 'flex-start'};
    font-family: Merriweather-Light;
    font-size: 16px;
    color: #4d5762;
    * {
        font-family: Merriweather-Light;
        font-size: 16px;
        color: #4d5762;
        ${props =>
            props.id === 'cell-iteractions' &&
            'white-space: nowrap;text-overflow: ellipsis; overflow: hidden; max-width: 25vw;'}
    }
`

const CellText = styled('span')`
    font-family: Merriweather-Light;
    font-size: 16px;
    color: #4d5762;
`

const Container = styled(RowTable as any)`
    height: 75px;
    border-radius: 10px;
    border: solid 1px #979797;
    background-color: ${Colors.white};
    cursor: pointer;
    margin-bottom: 16px;
    padding: 0 12px;

    :hover {
        box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.12);
    }
`
