import React from 'react'
import { Company } from '../../shared/shared.models'
import { useRoutes } from 'hookrouter'
import { CompanyDetailsPage } from './company-details.page'
import CreateCompanyPage from './create-company.page'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'

export interface IBaseInterface {
    company: Company
}

const routes = {
    '/': () => (company: Company) => <CompanyDetailsPage company={company} />,
    '/create-company': () => (company: Company) => <CreateCompanyPage />,
    '/company-details': () => (company: Company) => (
        <CompanyDetailsPage company={company} />
    ),
}

interface CompanyPageProps {
    companyId: string
}

/**
 * This page handles the routing of the CompanyPage
 * @param companyId {string}
 */
export const CompanyPage = ({ companyId }: CompanyPageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()

    const [company, setCompany] = React.useState<Company>()
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        async function fetchData() {
            try {
                const res = await AdminApi.methods.getCompany(companyId)
                setCompany(res?.data?.company)
            } catch (e) {
                alert.error(
                    'Houve um erro a ir buscar a informação desta empresa. Por favor tente mais tarde'
                )
            } finally {
                setIsLoading(false)
            }
        }
        fetchData()
    }, [companyId, dispatch, alert])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação da empresa</LoadingText>
            </Column>
        )
    }

    if (!company) {
        throw new Error('Esta empresa não existe')
    } else {
        return match(company)
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
