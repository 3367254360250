import React, { SVGProps } from "react";

interface PlusIconProps extends SVGProps<SVGSVGElement> {}

const PlusIcon = (props: PlusIconProps) => {
  return (
    <svg
      {...props}
      viewBox="0 0 45 45"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>Icons / Plus</title>
      <g
        id="Backoffice"
        stroke="inherit"
        strokeWidth="1"
        fill="inherit"
        fillRule="evenodd"
      >
        <g
          id="Vehicles-/-List-view"
          transform="translate(-1335.000000, -919.000000)"
          fill="inherit"
        >
          <g id="Action-button" transform="translate(1304.000000, 888.000000)">
            <g id="Icons-/-Plus" transform="translate(31.000000, 31.000000)">
              <path d="M25.5,0 L25.5,19.5 L45,19.5 L45,25.5 L25.5,25.5 L25.5,45 L19.5,45 L19.5,25.499 L0,25.5 L0,19.5 L19.5,19.499 L19.5,0 L25.5,0 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PlusIcon;
