import React from 'react'
import { VehicleFilters } from '../../admin/components/vehicle-search.component'
import { CompanyFilters } from '../../admin/components/company-search.component'
import { ContactFilters } from '../../admin/components/contacts-search.component'

/**
 * Helper hook to handle logic of the filters
 */
export const useFilter = () => {
    const [filters, setFilters] = React.useState<any>({})
    /**
     * Updates a filter on our map
     * @param key string
     * @param value any
     */
    const updateFilter = React.useCallback(
        (key: VehicleFilters, value: any) => {
            setFilters((filters: any) => {
                const newFilters = { ...filters }
                if (value === '') {
                    delete newFilters[key]
                } else {
                    newFilters[key] = value
                }
                return newFilters
            })
        },
        []
    )

    const updateContactFilter = React.useCallback(
        (key: ContactFilters, value: any) => {
            setFilters((filters: any) => {
                const newFilters = { ...filters }
                if (value === '') {
                    delete newFilters[key]
                } else {
                    newFilters[key] = value
                }
                return newFilters
            })
        },
        []
    )

    const updateMultipleFilters = React.useCallback(
        (values: { key: VehicleFilters; value: any }[]) => {
            setFilters((filters: any) => {
                const newFilters = { ...filters }
                values.forEach(obj => {
                    if (obj.value === '') {
                        delete newFilters[obj.key]
                    } else {
                        newFilters[obj.key] = obj.value
                    }
                })
                return newFilters
            })
        },
        []
    )

    const updateCompanyFilter = React.useCallback(
        (key: CompanyFilters, value: any) => {
            setFilters((filters: any) => {
                const newFilters = { ...filters }
                if (value === '') {
                    delete newFilters[key]
                } else {
                    newFilters[key] = value
                }
                return newFilters
            })
        },
        []
    )

    /**
     * Clears all the filters from our map
     */
    const clearAllFilters = () => {
        setFilters({})
    }

    /**
     * Clears a single filter from our map
     * @param key string
     */
    const clearFilter = (key: string) => {
        const newFilters = { ...filters }
        delete newFilters[key]
        setFilters(newFilters)
    }

    return {
        updateFilter,
        updateContactFilter,
        updateMultipleFilters,
        updateCompanyFilter,
        clearAllFilters,
        clearFilter,
        filters,
    }
}
