import React from "react";
import TextInput, { TextInputProps } from "./text-input.component";
import { euroFormatter } from "../shared.utils";

interface EuroInputProps extends TextInputProps {}

export const EuroInput = ({
  value,
  title,
  inEdition,
  ...rest
}: EuroInputProps) => {
  function getValue() {
    if (!inEdition) {
      if (value) {
        return euroFormatter.format(Number(value));
      }
      return euroFormatter.format(0);
    }
    return value;
  }

  const _title = inEdition ? `${title} (€)` : title;

  return (
    <TextInput
      value={getValue()}
      title={_title}
      inEdition={inEdition}
      {...rest}
    />
  );
};
