import React from 'react'
import { useIsAdmin } from '../../../../shared/hooks/use-is-admin.hook'
import { useFormikContext } from 'formik'
import {
    euroFormatter,
    getCostsTotalValue,
    getVehicleTotalValue,
} from '../../../../shared/shared.utils'
import {
    Section,
    SectionTitle,
    WrapRow,
    SmallSectionTitle,
} from '../../../../shared/shared.styled'
import TextInput from '../../../../shared/components/text-input.component'
import { RetakeSection } from './retake.section'
import { LoansSection } from './loans.section'
import { SaleRequest } from '../../../sales.interfaces'
import { Vehicle, Stand } from '../../../../shared/shared.models'
import PaymentSection from './payment.section'
import { Colors } from '../../../../configs/styled.config'
import TextArea from '../../../../shared/components/text-area.component'
import { SharedSectionProps } from '../../../../shared/shared.interface'
import Dropdown from '../../../../shared/components/dropdown.component'
import { DropdownUtils } from '../../../../shared/utils/dropdowns.utils'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../../../configs/store.config'
import { AdminApi } from '../../../../admin/admin.api'
import { useAlert } from 'react-alert'
import { useIsWorkshop } from '../../../../shared/hooks/use-is-workshop.hook'

interface FinanceSectionProps extends SharedSectionProps {
    vehicle: Vehicle
}

export const FinanceSection = ({ vehicle, isEditing }: FinanceSectionProps) => {
    const isAdmin = useIsAdmin()
    const isWorkshop = useIsWorkshop()
    const alert = useAlert()
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        SaleRequest
    >()
    const { company } = useSelector(
        (state: IStoreInterface) => state.adminReducer
    )
    const [stands, setStands] = React.useState<Stand[]>([])

    React.useEffect(() => {
        try {
            if (company) {
                AdminApi.methods.getStands(company.uuid).then(
                    res => {
                        setStands(res.data.stands)
                    },
                    e => {
                        alert.error('Error fetching the stands list')
                    }
                )
            }
        } catch (e) {
            alert.error('Erro ao listar os stands. Por favor tente mais tarde')
        }
    }, [alert, company])

    /**
     * Helper function to get the sale margin
     * @param salePrice string
     * @param totalCost number
     */
    const getSaleMargin = (salePrice = 0) => {
        const totalCost = getVehicleTotalValue(vehicle)
        const salePriceNum = Number(salePrice)
        return salePriceNum - totalCost
    }

    return (
        <Section>
            <SectionTitle>Dados Financeiros</SectionTitle>
            <WrapRow>
                {isAdmin && (
                    <>
                        <TextInput
                            inEdition={isEditing}
                            name="vehicle.buyPrice"
                            disabled={true}
                            value={euroFormatter.format(vehicle.buyPrice)}
                            title="Valor de compra"
                            containerStyle={{ maxWidth: 250 }}
                        />

                        <TextInput
                            inEdition={false}
                            value={euroFormatter.format(
                                getCostsTotalValue(
                                    vehicle.costs
                                        ? vehicle.costs
                                        : vehicle.totalCosts || 0
                                )
                            )}
                            title="Custos"
                            containerStyle={{ maxWidth: 250 }}
                        />
                        <TextInput
                            inEdition={false}
                            value={euroFormatter.format(
                                getVehicleTotalValue(vehicle)
                            )}
                            title="Custo total"
                            containerStyle={{ maxWidth: 250 }}
                        />
                        <TextInput
                            inEdition={false}
                            value={
                                vehicle.previewCosts
                                    ? euroFormatter.format(vehicle.previewCosts)
                                    : ''
                            }
                            title="Custos previstos"
                            containerStyle={{ maxWidth: 250 }}
                        />
                    </>
                )}
                <TextInput
                    inEdition={false}
                    value={euroFormatter.format(vehicle.sellerPrice)}
                    title="Preço de tabela"
                    containerStyle={{ maxWidth: 250 }}
                />
            </WrapRow>
            <WrapRow>
                {!isWorkshop && (
                    <TextInput
                        inEdition={isEditing}
                        value={
                            !isEditing && values.salePrice
                                ? euroFormatter.format(values.salePrice)
                                : values.salePrice
                        }
                        required={true}
                        title="Valor da venda"
                        error={errors.salePrice}
                        name="salePrice"
                        onChange={handleChange}
                        containerStyle={{ maxWidth: 250 }}
                    />
                )}
                {isAdmin && (
                    <TextInput
                        inEdition={false}
                        value={euroFormatter.format(
                            getSaleMargin(values.salePrice)
                        )}
                        title="Margem da venda"
                        containerStyle={{ maxWidth: 250 }}
                        style={{
                            color:
                                getSaleMargin(values.salePrice) > 0
                                    ? Colors.green
                                    : Colors['alive-red'],
                        }}
                    />
                )}
            </WrapRow>
            <RetakeSection isEditing={isEditing} />
            <LoansSection isEditing={isEditing} />
            {!isWorkshop && <PaymentSection isEditing={isEditing} />}
            <SmallSectionTitle>Observações</SmallSectionTitle>
            <WrapRow>
                <TextArea
                    inEdition={isEditing}
                    title="Observações de negócio"
                    value={values.businessNotes}
                    name="businessNotes"
                    onChange={handleChange}
                    containerStyles={{ maxWidth: '500px' }}
                />
                <TextInput
                    inEdition={isEditing}
                    title="Quilometros á data de venda"
                    type="number"
                    value={values.vehicleKilometersOnSaleDate}
                    name="vehicleKilometersOnSaleDate"
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                />
            </WrapRow>
            <SmallSectionTitle>Outros</SmallSectionTitle>
            <WrapRow>
                <Dropdown
                    inEdition={isEditing}
                    label="Origem da Venda"
                    placeholder="Escolher origem"
                    value={values.origin}
                    error={errors.origin}
                    selectValue={opt => setFieldValue('origin', opt.key)}
                    clearValue={() => setFieldValue('origin', undefined)}
                    options={DropdownUtils.getOriginDropdownOptions()}
                />
                <Dropdown
                    inEdition={isEditing}
                    label="Stand da Venda"
                    placeholder="Escolher stand"
                    value={values.standLocation}
                    error={errors.standLocation}
                    selectValue={opt => setFieldValue('standLocation', opt.key)}
                    clearValue={() => setFieldValue('standLocation', undefined)}
                    options={DropdownUtils.getStandLocationDropdownOptions(
                        stands
                    )}
                />
            </WrapRow>
        </Section>
    )
}
