import React from 'react'
import { FormContent } from '../forms/create-sale.form'
import { useModal } from 'react-modal-hook'
import {
    ConfirmationModal,
    ConfirmationModalProps,
} from '../../shared/components/modals/confirmation.modal'
import { Vehicle } from '../../shared/shared.models'
import FixedPageTitle from '../../admin/components/fixed-page-title.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { AvailabilityEnum } from '../../shared/shared.enums'
import PrimaryButton from '../../shared/components/button.component'
import { useFormikContext } from 'formik'
import { Sale } from '../sales.interfaces'
import { RoutesEnum } from '../../routes.constants'
import { navigate } from 'hookrouter'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'

interface EditSaleFormProps {
    vehicle: Vehicle
    deleteSale: (saleId: string) => Promise<void>
    updateSale: (values: Sale) => Promise<Sale>
    deliverVehicle: (vehicleId: string) => Promise<void>
}

export const EditSaleForm = ({
    vehicle,
    deleteSale,
    updateSale,
    deliverVehicle,
}: EditSaleFormProps) => {
    // Hooks
    const isAdmin = useIsAdmin()
    const isWorkshop = useIsWorkshop()

    const { values, resetForm } = useFormikContext<Sale>()

    const [modalProps, setModalProps] = React.useState<ConfirmationModalProps>({
        onClick: () => null,
        onCancel: () => null,
    })

    // Confirmation modals
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => <ConfirmationModal {...modalProps} />,
        [modalProps]
    )

    const [isEditing, setIsEditing] = React.useState(false)

    /**
     * Check what confirmation modal we need to show depending on the action the user wants to do
     * @param type string
     */
    const configureModalAndShow = (type: string) => {
        switch (type) {
            case 'delete':
                setModalProps({
                    title: 'Eliminar venda',
                    description:
                        'Tem a certeza que quer eliminar esta venda? Vai perder toda a informação associada a ela',
                    onClick: async () => {
                        // We dont trycatch here because we dont care about the result of the promise
                        await deleteSale(values.uuid)
                        hideConfirmationModal()
                    },
                    onCancel: () => hideConfirmationModal(),
                })
                break
            case 'update':
                setModalProps({
                    title: 'Editar venda',
                    description: 'Tem a certeza que quer editar esta venda?',
                    onClick: async () => {
                        // We dont trycatch here because we dont care about the result of the promise

                        try {
                            const res = await updateSale(values)
                            resetForm({ values: res })
                            setIsEditing(false)
                        } finally {
                            hideConfirmationModal()
                        }
                    },
                    onCancel: () => hideConfirmationModal(),
                })
                break
            case 'deliver':
                setModalProps({
                    title: 'Entregar veículo',
                    description:
                        'Ao entregar o veículo, esta venda é dada como completa',
                    onClick: async () => {
                        await deliverVehicle(vehicle.uuid)
                        hideConfirmationModal()
                    },
                    onCancel: () => hideConfirmationModal(),
                })
                break
            case 'cancelEdition':
                setModalProps({
                    title: 'Cancelar edição',
                    description:
                        'Tem a certeza que quer cancelar a edição? Vai perder todos os dados alterados',
                    onClick: async () => {
                        resetForm()
                        setIsEditing(false)
                        hideConfirmationModal()
                    },
                    onCancel: () => hideConfirmationModal(),
                })
                break
        }
        showConfirmationModal()
    }

    const goToVehicleDetailsPage = (vehicle: Vehicle) => {
        navigate(RoutesEnum.VEHICLE_PAGE.replace(':vehicleId', vehicle.uuid))
    }

    /**
     * Helper function to decide which buttons to render
     */
    const renderButtons = () => {
        // If the user is a seller and the vehicle availability is "DELIVERED",
        // the user can't do anything anymore.
        if (!isAdmin) {
            if (vehicle.availability !== AvailabilityEnum.DELIVERED) {
                return isEditing ? (
                    <>
                        <PrimaryButton
                            onClick={() =>
                                configureModalAndShow('cancelEdition')
                            }
                            label="Cancelar"
                        />
                        <PrimaryButton
                            redTheme={true}
                            onClick={() => configureModalAndShow('update')}
                            label="Guardar alterações"
                        />
                    </>
                ) : (
                    <>
                        {isWorkshop && (
                            <PrimaryButton
                                goldTheme={true}
                                onClick={() => goToVehicleDetailsPage(vehicle)}
                                label="Detalhes do veículo"
                            />
                        )}
                        <PrimaryButton
                            onClick={() => configureModalAndShow('delete')}
                            label="Eliminar"
                        />
                        <PrimaryButton
                            redTheme={true}
                            onClick={() => setIsEditing(true)}
                            label="Editar"
                        />
                    </>
                )
            } else {
                return isEditing ? (
                    <>
                        <PrimaryButton
                            onClick={() =>
                                configureModalAndShow('cancelEdition')
                            }
                            label="Cancelar"
                        />
                        <PrimaryButton
                            redTheme={true}
                            onClick={() => configureModalAndShow('update')}
                            label="Guardar alterações"
                        />
                    </>
                ) : (
                    <>
                        {isWorkshop && (
                            <PrimaryButton
                                goldTheme={true}
                                onClick={() => goToVehicleDetailsPage(vehicle)}
                                label="Detalhes do veículo"
                            />
                        )}
                    </>
                )
            }
        } else {
            // If the user is an admin, he can edit, delete or "DELIVER" the vehicle
            return isEditing ? (
                <>
                    <PrimaryButton
                        onClick={() => configureModalAndShow('cancelEdition')}
                        label="Cancelar"
                    />
                    <PrimaryButton
                        redTheme={true}
                        onClick={() => configureModalAndShow('update')}
                        label="Guardar alterações"
                    />
                </>
            ) : (
                <>
                    <PrimaryButton
                        goldTheme={true}
                        onClick={() => goToVehicleDetailsPage(vehicle)}
                        label="Detalhes do veículo"
                    />
                    <PrimaryButton
                        onClick={() => configureModalAndShow('delete')}
                        label="Eliminar"
                    />
                    <PrimaryButton
                        redTheme={true}
                        onClick={() => setIsEditing(true)}
                        label="Editar venda"
                    />
                    {vehicle.availability === AvailabilityEnum.SOLD && (
                        <PrimaryButton
                            greenTheme={true}
                            onClick={() => configureModalAndShow('deliver')}
                            label="Entregar"
                        />
                    )}
                </>
            )
        }
    }

    return (
        <>
            <FixedPageTitle
                label="Detalhes de venda"
                rightButtons={renderButtons()}
            />
            <FormContent vehicle={vehicle} isEditing={isEditing} />
        </>
    )
}
