import React from 'react'
import {
    Section,
    SectionTitle,
    WrapRow,
    Row,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import Dropdown from '../../shared/components/dropdown.component'
import TextInput from '../../shared/components/text-input.component'
import { useFormikContext } from 'formik'
import { CreateVehicleRequest } from '../admin.interfaces'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'

interface MotorSectionProps {
    isEditing?: boolean
}

const MotorSection = ({ isEditing }: MotorSectionProps) => {
    const { values, errors, handleChange, setFieldValue } = useFormikContext<
        CreateVehicleRequest
    >()

    return (
        <Section>
            <Row>
                <SectionTitle>Motor</SectionTitle>
            </Row>
            <SectionSeparator />
            <WrapRow>
                <RatioContainer ratio={0.5 / 4} style={{ marginRight: 24 }}>
                    <Dropdown
                        inEdition={isEditing}
                        label="Combustível"
                        placeholder="Escolha o combustível"
                        value={values.fuel}
                        selectValue={opt => setFieldValue('fuel', opt.key)}
                        options={DropdownUtils.getFuelDropdownOptions()}
                        error={errors.fuel}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 4} style={{ marginRight: 24 }}>
                    <Dropdown
                        inEdition={isEditing}
                        label="Caixa"
                        placeholder="Escolha o tipo de caixa"
                        value={values.shift}
                        selectValue={opt => setFieldValue('shift', opt.key)}
                        options={DropdownUtils.getShiftOptions()}
                        error={errors.shift}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 4} style={{ marginRight: 24 }}>
                    <TextInput
                        inEdition={isEditing}
                        title="Potência (cv)"
                        name="motorPower"
                        placeholder="Ex: 120"
                        type="number"
                        onBlur={evt => {
                            setFieldValue('motorPower', evt.target.value.trim())
                        }}
                        onChange={handleChange}
                        value={values.motorPower ? values.motorPower : ''}
                        error={errors.motorPower}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 4} style={{ marginRight: 24 }}>
                    <TextInput
                        inEdition={isEditing}
                        title="Cilindrada (cm3)"
                        name="cylinder"
                        placeholder="Ex: 1600"
                        type="number"
                        onBlur={evt => {
                            setFieldValue('cylinder', evt.target.value.trim())
                        }}
                        onChange={handleChange}
                        value={values.cylinder ? values.cylinder : ''}
                        error={errors.cylinder}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 4}>
                    <TextInput
                        inEdition={isEditing}
                        title="Quilometragem (km)"
                        name="mileage"
                        placeholder="Ex: 234000"
                        type="number"
                        onBlur={evt => {
                            setFieldValue('mileage', evt.target.value.trim())
                        }}
                        onChange={handleChange}
                        value={values.mileage ? values.mileage : ''}
                        error={errors.mileage}
                    />
                </RatioContainer>
            </WrapRow>
        </Section>
    )
}

export default MotorSection
