import React from 'react'
import { TableManage } from '../../shared/components/table/table-manage.component'
import { Supplier } from '../../shared/shared.models'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import { ColumnProps } from '../../shared/components/table/table.component'
import { HeaderEnumSupplier } from '../../shared/components/table/table-headers.component'
import { RoutesEnum } from '../../routes.constants'
import { navigate } from 'hookrouter'

interface CompanySuppliersProps {
    suppliers: Supplier[] | undefined
    companyId: string
}

const CompanySuppliers = ({ suppliers, companyId }: CompanySuppliersProps) => {
    const [visibleSuppliers, setVisibleSuppliers] = React.useState(
        suppliers ? suppliers : []
    )

    const editSuppliers = (supplier: Supplier) => {
        navigate(
            RoutesEnum.GET_SUPPLIER.replace(':supplierId', supplier.uuid ?? '')
        )
    }
    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedSuppliers = SortUtils.sortSuppliers(
            visibleSuppliers,
            header,
            order
        )
        setVisibleSuppliers(sortedSuppliers)
    }

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: HeaderEnumSupplier.NAME,
                ratio: 4 / 12,
                label: 'Nome',
                renderFunction: supplier => supplier.name,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumSupplier.FREQUENT,
                label: 'É frequente',
                ratio: 2 / 12,
                renderFunction: supplier =>
                    supplier.isFrequent ? 'Sim' : 'Não',
            },
            {
                key: HeaderEnumSupplier.NUMBER_VEHICLES,
                label: 'Nº de veículos fornecidos',
                ratio: 2 / 12,
                renderFunction: supplier => supplier.numberVehicles,
                onClick: onHeaderClick,
            },
        ]
    }

    return (
        <TableManage
            companyId={companyId}
            isLoadingData={false}
            values={visibleSuppliers}
            onRowClick={editSuppliers}
            columns={getColumns()}
            type="fornecedores"
        />
    )
}

export default CompanySuppliers
