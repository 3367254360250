import React from 'react'

const AnalysisIcon = () => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <path
                        d="M387.938,84.104c-14.737,0-27.097,10.335-30.235,24.137l-33.92,8.48c-5.69-7.062-14.4-11.591-24.154-11.591
			c-17.102,0-31.013,13.912-31.013,31.013c0,4.017,0.776,7.854,2.173,11.381l-42.547,42.547c-4.87-3.184-10.681-5.043-16.922-5.043
			c-11.417,0-21.405,6.206-26.789,15.416l-30.563-18.338c0.035-0.599,0.057-1.202,0.057-1.809c0-17.101-13.912-31.013-31.013-31.013
			c-17.101,0-31.013,13.912-31.013,31.013s13.912,31.013,31.013,31.013c10.773,0,20.276-5.523,25.838-13.885l31.465,18.879
			c0.143,16.98,13.994,30.75,31.007,30.75c17.102,0,31.013-13.912,31.013-31.013c0-5.143-1.266-9.994-3.491-14.268l41.427-41.427
			c5.31,4.256,12.041,6.81,19.36,6.81c17.101,0,31.013-13.912,31.013-31.013c0-1.628-0.128-3.227-0.371-4.79l28.054-7.013
			c3.935,12.61,15.721,21.789,29.612,21.789c17.101,0,31.013-13.912,31.013-31.013S405.039,84.104,387.938,84.104z M123.011,195.541
			c-8.405,0-15.244-6.839-15.244-15.244c0-8.405,6.839-15.244,15.244-15.244c8.405,0,15.244,6.839,15.244,15.244
			C138.255,188.703,131.416,195.541,123.011,195.541z M211.32,231.286c-8.405,0-15.244-6.839-15.244-15.244
			c0-8.405,6.839-15.244,15.244-15.244c8.405,0,15.244,6.839,15.244,15.244C226.564,224.447,219.725,231.286,211.32,231.286z
			 M299.629,151.387c-8.405,0-15.244-6.839-15.244-15.244c0-8.405,6.839-15.244,15.244-15.244c8.405,0,15.244,6.839,15.244,15.244
			C314.873,144.548,308.034,151.387,299.629,151.387z M387.938,130.361c-8.405,0-15.244-6.839-15.244-15.244
			s6.839-15.244,15.244-15.244c8.405,0,15.244,6.839,15.244,15.244S396.343,130.361,387.938,130.361z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M435.246,309.082h-22.603V169.785c0-4.356-3.529-7.885-7.885-7.885h-29.436c-4.356,0-7.885,3.529-7.885,7.885v139.297
			h-44.505V195.016c0-4.356-3.529-7.885-7.885-7.885h-29.436c-4.356,0-7.885,3.529-7.885,7.885v114.066h-44.506v-42.578
			c0-4.356-3.529-7.885-7.885-7.885H195.9c-4.356,0-7.885,3.529-7.885,7.885v42.578h-44.505v-63.604
			c0-4.356-3.529-7.885-7.885-7.885H106.19c-4.356,0-7.885,3.529-7.885,7.885v63.604H75.702c-4.354,0-7.885,3.529-7.885,7.885
			s3.529,7.885,7.885,7.885h359.544c4.356,0,7.885-3.529,7.885-7.885S439.602,309.082,435.246,309.082z M127.742,309.082h-13.667
			v-55.719h13.667V309.082z M217.452,309.082h-13.667v-34.693h13.667V309.082z M307.163,309.082h-13.667V202.901h13.667V309.082z
			 M396.874,309.082h-13.667V177.669h13.667V309.082z"
                    />
                </g>
            </g>
            <g>
                <g>
                    <path
                        d="M491.491,334.313h-17.346V39.424C474.144,17.685,456.459,0,434.721,0H77.279C55.54,0,37.856,17.685,37.856,39.424v203.952
			c0,4.356,3.529,7.885,7.885,7.885s7.885-3.529,7.885-7.885V72.89h322.223c4.356,0,7.885-3.529,7.885-7.885
			c0-4.355-3.529-7.885-7.885-7.885H53.625V39.424c0-13.042,10.612-23.654,23.654-23.654h357.441
			c13.042,0,23.654,10.612,23.654,23.654V57.12H417.9c-4.356,0-7.885,3.529-7.885,7.885s3.529,7.885,7.885,7.885h40.475v261.423
			H53.625v-54.142c0-4.356-3.529-7.885-7.885-7.885s-7.885,3.529-7.885,7.885v54.142H20.509c-4.356,0-7.885,3.529-7.885,7.885
			v33.642c0,4.356,3.529,7.885,7.885,7.885h227.606v51.844l-66.897,62.78c-7.404,6.953,3.384,18.447,10.793,11.499l56.105-52.652
			v42.697c0,4.356,3.529,7.885,7.885,7.885c4.356,0,7.885-3.529,7.885-7.885v-41.126l56.193,51.161
			c7.467,6.798,18.149-4.802,10.616-11.661l-66.809-60.826v-53.717h227.606c4.356,0,7.885-3.529,7.885-7.885v-33.642
			C499.375,337.842,495.846,334.313,491.491,334.313z M483.606,367.954H28.394v-17.872h455.212V367.954z"
                    />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}

export default AnalysisIcon
