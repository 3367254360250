import React, { TextareaHTMLAttributes } from "react";
import styled from "styled-components";
import { Colors, Typography } from "../../configs/styled.config";
import { Column } from "../shared.styled";
import { FormikErrors } from "formik";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  title?: string;
  error?: string | FormikErrors<any>;
  containerStyles?: React.CSSProperties;
  inEdition?: boolean;
}

const TextArea = ({
  title,
  error,
  containerStyles,
  inEdition = true,
  ...rest
}: TextAreaProps) => {
  return (
    <Column style={{ width: "100%", ...containerStyles }}>
      {title ? (
        <StyledTitle isRequired={inEdition && !!rest.required}>
          {title}
        </StyledTitle>
      ) : null}

      <StyledTextArea disabled={!inEdition} error={!!error} {...rest} />
    </Column>
  );
};

export default TextArea;

const StyledTitle = styled("span")<{ isRequired: boolean }>`
  margin-bottom: 8px;
  ${Typography["Body_Text_#1_Regular_Left"]};
  font-size: 18px;
  color: #44566c;
  font-weight: bold;

  ${props =>
    props.isRequired
      ? `
   ::after {
     margin-left: 2px;
     content: '*';
     color: ${Colors["gold-pmauto"]};
   }
   `
      : ""}
`;

const StyledTextArea = styled("textarea")<{ error?: boolean }>`
  border-radius: 6px;
  resize: vertical;
  height: 100px;
  border: solid 1px
    ${props =>
      props.error
        ? Colors["alive-red"]
        : props.value
        ? Colors.slate
        : Colors["light-grey-blue"]};

  ${Typography["Body_Text_#2_Regular_Left"]};

  padding: 8px 12px;
  outline: none;

  :disabled {
    background-color: rgba(216, 216, 216, 0.2);
  }
  :hover {
    border: solid 1px ${Colors.slate};
  }

  :focus {
    border: solid 1px ${Colors.slate};
  }

  ::placeholder {
    ${Typography["Body_Text_#2_Low_Contrast_Left"]};
  }
`;
