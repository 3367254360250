import React from 'react'
import { Column } from '../shared.styled'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'

interface TextDisplayProps {
    title: string
    containerStyles?: React.CSSProperties
}

export const TextDisplay = ({
    title,
    containerStyles,
    children,
}: React.PropsWithChildren<TextDisplayProps>) => {
    return (
        <Column style={containerStyles}>
            <StyledLabel>{title}</StyledLabel>
            <StyledText>{children}</StyledText>
        </Column>
    )
}

const StyledLabel = styled('label')`
    ${Typography['Body_Text_#1_High_Contrast_Left']};
    margin-bottom: 8px;
`

const StyledText = styled('span')`
    ${Typography['Body_Text_#2_Regular_Left']};
    min-height: 20px;
`
