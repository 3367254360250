import React from 'react'
import { useScrollPosition } from '../../shared/hooks/use-scroll-position.hook'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import { Row } from '../../shared/shared.styled'
import { ScrollPosition } from '../../shared/shared.interface'
import ArrowLeftIcon from '../../assets/icons/arrow-left.icon'

interface FixedPageTitleProps {
    label: string
    rightButtons?: React.ReactNode
}

const FixedPageTitle = ({ label, rightButtons }: FixedPageTitleProps) => {
    const position = useScrollPosition()
    return (
        <PageTitleContainer position={position}>
            <Row>
                <IconContainer onClick={() => window.history.back()}>
                    <ArrowLeftIcon />
                </IconContainer>
                <PageTitle>{label}</PageTitle>
            </Row>
            <ButtonsContainer>{rightButtons}</ButtonsContainer>
        </PageTitleContainer>
    )
}

export default FixedPageTitle

const PageTitleContainer = styled(Row as any)<{ position: ScrollPosition }>`
    margin-bottom: 24px;
    background-color: white;
    ${props =>
        props.position.y > 50
            ? `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding-right: calc(32px + 50px);
    padding-left: calc(92px + 50px);
    z-index: 9;
    height: 100px;
    transition: position 5s linear;
    border-bottom: 1px solid ${Colors['very-light-blue']};
    `
            : 'position: relative'}

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`

const PageTitle = styled('h1')`
    margin: 0;
    ${Typography.H1_Regular_Left};
`

const IconContainer = styled('div')`
    width: 24px;
    height: 24px;
    margin-right: 16px;
    cursor: pointer;
`

const ButtonsContainer = styled(Row as any)`
    margin-left: auto;

    button:not(:last-child) {
        margin-right: 12px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 24px;
    }
`
