import React from 'react'
import { useFormikContext } from 'formik'
import { CreateVehicleRequest } from '../admin.interfaces'
import {
    Section,
    SectionTitle,
    WrapRow,
    Row,
    SectionSeparator,
    Column,
} from '../../shared/shared.styled'
import TextInput, {
    StyledTitle,
} from '../../shared/components/text-input.component'
import Dropdown from '../../shared/components/dropdown.component'
import { Colors, Typography } from '../../configs/styled.config'
import Checkbox from '../../shared/components/checkbox.component'
import TextArea from '../../shared/components/text-area.component'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
// import { AdminUtils } from "../admin.utils";
import {
    getFormatedComponentFromDays,
    getVehicleTotalValue,
    getCostsTotalValue,
} from '../../shared/shared.utils'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import styled from 'styled-components'
import { EuroInput } from '../../shared/components/euro-input.component'
import { Stand } from '../../shared/shared.models'
import { AdminApi } from '../admin.api'
import { useAlert } from 'react-alert'
import { useIsEditor3 } from '../../shared/hooks/use-is-editor.hook'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'

interface ManagementSectionProps {
    isEditing?: boolean
}

const ManagementSection = ({ isEditing = true }: ManagementSectionProps) => {
    // Hooks
    const isAdmin = useIsAdmin()
    //const isEditor1_2 = useIsEditor1_2()
    const isEditor3 = useIsEditor3()
    const isWorkshop = useIsWorkshop()

    const { values, errors, handleChange, setFieldValue } = useFormikContext<
        CreateVehicleRequest
    >()
    const alert = useAlert()
    const [stands, setStands] = React.useState<Stand[]>([])

    // Redux state
    const { users, company } = useSelector(
        (state: IStoreInterface) => state.adminReducer
    )

    // LocalState
    const [isRetake, setIsRetake] = React.useState(!!values.retakePerson)

    const getMarginValue = () => {
        const auxSellerPrice = Number(values.sellerPrice) || 0
        const margin = auxSellerPrice - getVehicleTotalValue(values)
        return margin.toFixed(2)
    }

    const getTotalValueColor = () => {
        if (Number(getMarginValue()) < 0) {
            return Colors['alive-red']
        } else {
            return Colors.green
        }
    }

    const _toggleRetakePerson = () => {
        // If this is true, we're toggling to false, so we need to clear the retakePerson key from the formik values
        if (isRetake) {
            setFieldValue('retakePerson', undefined)
        }
        setIsRetake(!isRetake)
    }

    const getTimeInStockComponent = () => {
        const stockTime = parseInt(values.stockDays as string)
        if (stockTime) {
            return getFormatedComponentFromDays(stockTime)
        } else {
            return 0
        }
    }

    React.useEffect(() => {
        try {
            if (company) {
                AdminApi.methods.getStands(company.uuid).then(
                    res => {
                        setStands(res.data.stands)
                    },
                    e => {
                        alert.error('Error fetching the stands list')
                    }
                )
            }
        } catch (e) {
            alert.error('Erro ao listar os stands. Por favor tente mais tarde')
        }
    }, [alert, company])

    return (
        <Section>
            <Row>
                <SectionTitle>Gestão</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow style={{ alignItems: 'flex-start' }}>
                <Column style={{ flex: 0.6 }}>
                    <WrapRow id="row-1">
                        <TextInput
                            inEdition={
                                (isAdmin || isEditor3 || isWorkshop) &&
                                isEditing
                            }
                            onChange={handleChange}
                            value={values.createdAt}
                            name="createdAt"
                            placeholder="yyyy-MM-dd"
                            type="date"
                            title="Data de entrada"
                            error={errors.createdAt}
                            required={true}
                        />

                        <Column>
                            <StyledTitle isRequired={false}>
                                Tempo em stock
                            </StyledTitle>
                            <TimeInStock>
                                {getTimeInStockComponent()}
                            </TimeInStock>
                        </Column>

                        <Dropdown
                            inEdition={
                                (isAdmin || isEditor3 || isWorkshop) &&
                                isEditing
                            }
                            placeholder="Disponibilidade do veículo"
                            label="Disponibilidade do veículo"
                            options={DropdownUtils.getVehicleAvailabilityDropdownOptions()}
                            value={values.availability}
                            selectValue={opt =>
                                setFieldValue('availability', opt.key)
                            }
                            error={errors.availability}
                        />
                        <Dropdown
                            inEdition={isEditing}
                            placeholder="Escolha o stand"
                            label="Localização"
                            options={DropdownUtils.getStandLocationDropdownOptions(
                                stands
                            )}
                            value={values.standLocation}
                            selectValue={opt =>
                                setFieldValue('standLocation', opt.key)
                            }
                            error={errors.standLocation}
                        />
                    </WrapRow>
                    <WrapRow id="row-2">
                        {isAdmin && (
                            <EuroInput
                                inEdition={isAdmin && isEditing}
                                name="buyPrice"
                                placeholder="Ex: 23000"
                                onChange={handleChange}
                                type="number"
                                step="0.01"
                                title="Valor de compra"
                                value={values.buyPrice ? values.buyPrice : ''}
                                error={errors.buyPrice}
                            />
                        )}
                        {isAdmin && (
                            <EuroInput
                                inEdition={false}
                                disabled={true}
                                title="Total de custos"
                                readOnly
                                value={getCostsTotalValue(
                                    values.costs || values.totalCosts || 0
                                )}
                            />
                        )}
                        {isAdmin && (
                            <EuroInput
                                inEdition={false}
                                disabled={true}
                                name="totalValue"
                                title="Valor Total"
                                type="number"
                                step="0.01"
                                readOnly
                                value={getVehicleTotalValue(values)}
                            />
                        )}
                        {isAdmin && (
                            <EuroInput
                                inEdition={isEditing}
                                name="previewCosts"
                                title="Custos previstos"
                                type="number"
                                step="0.01"
                                placeholder="Ex: 500"
                                onChange={handleChange}
                                value={values.previewCosts}
                            />
                        )}
                    </WrapRow>
                    <WrapRow id="row-3">
                        {!isWorkshop && (
                            <EuroInput
                                inEdition={(isAdmin || isEditor3) && isEditing}
                                name="sellerPrice"
                                placeholder="Ex: 23000"
                                onChange={handleChange}
                                title="Preço de tabela"
                                type="number"
                                step="0.01"
                                value={
                                    values.sellerPrice ? values.sellerPrice : 0
                                }
                                error={errors.sellerPrice}
                            />
                        )}

                        {isAdmin && (
                            <EuroInput
                                inEdition={false}
                                disabled={true}
                                title="Margem de lucro"
                                value={getMarginValue()}
                                step="0.01"
                                error={errors.sellerPrice}
                                style={{
                                    fontWeight: 'bold',
                                    color: getTotalValueColor(),
                                }}
                            />
                        )}
                    </WrapRow>
                </Column>
                <Column style={{ flex: 0.4 }}>
                    <Column>
                        <Checkbox
                            containerStyle={{ marginBottom: 24 }}
                            disabled={
                                (!isAdmin && !isEditor3 && !isWorkshop) ||
                                !isEditing
                            }
                            label="Retoma"
                            checked={isRetake}
                            onChange={_toggleRetakePerson}
                        />
                        {!isWorkshop && (
                            <Dropdown
                                containerStyles={{
                                    maxWidth: 250,
                                    marginBottom: 24,
                                }}
                                inEdition={(isAdmin || isEditor3) && isEditing}
                                disabled={!isRetake}
                                required={isEditing && isRetake}
                                label="Vendedor"
                                value={
                                    users?.length > 0
                                        ? users.find(
                                              elem =>
                                                  elem?.uuid ===
                                                      values.retakePerson ||
                                                  elem?.username ===
                                                      values.retakePerson
                                          )?.username
                                        : ''
                                }
                                selectValue={opt =>
                                    setFieldValue('retakePerson', opt.key)
                                }
                                placeholder="Escolher vendedor"
                                options={DropdownUtils.getUsersDropdownOptions(
                                    users
                                )}
                            />
                        )}

                        <TextArea
                            containerStyles={{ marginBottom: 24 }}
                            onChange={handleChange}
                            disabled={!isAdmin || !isEditing}
                            name="observations"
                            value={
                                values.observations ? values.observations : ''
                            }
                            title="Observações"
                        />
                    </Column>
                    {!isWorkshop && (
                        <Column>
                            <Checkbox
                                disabled={
                                    (!isAdmin && !isEditor3) || !isEditing
                                }
                                label="Iva Dedutível?"
                                checked={values.iva}
                                onChange={e =>
                                    setFieldValue('iva', e.target.checked)
                                }
                            />
                        </Column>
                    )}
                </Column>
            </ResponsiveRow>
        </Section>
    )
}

export default ManagementSection

const TimeInStock = styled('div')`
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;

    ${Typography['Body_Text_#2_Regular_Left']};
`

const ResponsiveRow = styled(Row as any)`
    ${WrapRow} {
        > div {
            width: fit-content !important;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`
