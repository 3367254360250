import React from "react";

const ArrowLeftIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Vehicles-/-Create-vehicle---Dropdowns-open"
          transform="translate(-162.000000, -50.000000)"
          fillRule="nonzero"
        >
          <g
            id="ant-design:arrow-left-outline"
            transform="translate(157.000000, 46.000000)"
          >
            <g
              id="Icon"
              transform="translate(5.000000, 4.000000)"
              fill="#EC1C24"
            >
              <path
                d="M23.25,10.8125 L4.965625,10.8125 L15.909375,1.3125 C16.084375,1.159375 15.978125,0.875 15.746875,0.875 L12.98125,0.875 C12.859375,0.875 12.74375,0.91875 12.653125,0.996875 L0.84375,11.24375 C0.624900993,11.4334525 0.499177606,11.7088137 0.499177606,11.9984375 C0.499177606,12.2880613 0.624900993,12.5634225 0.84375,12.753125 L12.721875,23.0625 C12.76875,23.103125 12.825,23.125 12.884375,23.125 L15.74375,23.125 C15.975,23.125 16.08125,22.8375 15.90625,22.6875 L4.965625,13.1875 L23.25,13.1875 C23.3875,13.1875 23.5,13.075 23.5,12.9375 L23.5,11.0625 C23.5,10.925 23.3875,10.8125 23.25,10.8125 Z"
                id="Icon-Path"
              ></path>
            </g>
            <rect id="ViewBox" x="0" y="0" width="32" height="32"></rect>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeftIcon;
