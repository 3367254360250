import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminApi } from '../admin.api'
import { IStoreInterface } from '../../configs/store.config'
import { LoggedUser, Vehicle } from '../../shared/shared.models'
import { RoleEnum, AvailabilityEnum } from '../../shared/shared.enums'
import { PageTitle, Row } from '../../shared/shared.styled'
import { Table } from '../../shared/components/table/table.component'
import { TableUtils } from '../table.utils'
import { SoldVehiclesAnalytics } from '../components/sold-vehicle-analytics.component'
import { useFilter } from '../../shared/hooks/use-filter.hook'
import { SoldVehiclesSearch } from '../components/sold-vehicles-search.component'
import { AdminUtils } from '../admin.utils'
import { navigate } from 'hookrouter'
import { VehicleRoutesEnum } from '../../routes.constants'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import { createSelector } from 'reselect'
import moment from 'moment'
import { VehicleFilters } from '../components/vehicle-search.component'
import { useAlert } from 'react-alert'
import { useHasVehicles } from '../../shared/hooks/use-vehicles-exists.hook'
import { useHasStandsOnly } from '../../shared/hooks/use-just-stand.hook'
import { AdminActions } from '../admin.store'
import PdfGeneratorButton from '../../shared/components/button2.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'

const userSelector = createSelector(
    (state: IStoreInterface) => state.adminReducer.user,
    user => user as LoggedUser
)

export const SoldVehiclesPage = () => {
    // Hooks initialization
    const {
        filters,
        updateFilter,
        updateMultipleFilters,
        clearAllFilters,
        clearFilter,
    } = useFilter()

    const company = useSelector(
        (state: IStoreInterface) => state.adminReducer.company
    )

    const alert = useAlert()
    const isAdmin = useIsAdmin()

    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        if (queryParams.toString() === '') {
            const todaysDate = moment()
            const currentMonth = todaysDate.month() + 1 // Months are zero based
            const currentYear = todaysDate.year()
            updateMultipleFilters([
                {
                    key: VehicleFilters.SALE_YEAR,
                    value: currentYear,
                },
                {
                    key: VehicleFilters.SALE_MONTH,
                    value: currentMonth,
                },
            ])
        }
    }, [updateMultipleFilters])

    // Redux state
    const loggedUser = useSelector(userSelector)
    const standExistOnly = useHasStandsOnly()
    const vehiclesExists = useHasVehicles()

    const dispatch = useDispatch()

    // Local state
    const [vehicles, setVehicles] = React.useState<Vehicle[]>([])
    const [visibleVehicles, setVisibleVehicles] = React.useState(vehicles)
    const [isLoading, setIsLoading] = React.useState(true)

    React.useEffect(() => {
        const newVisibleVehicles = AdminUtils.filterAllVehicles(
            filters,
            vehicles
        )
        setVisibleVehicles(newVisibleVehicles)
    }, [vehicles, filters])

    // Helper function to calculate the queryParams depending on the user
    const getQueryParamsDependingOnUser = React.useCallback(() => {
        let queryParams = '?availability=SOLD,DELIVERED'
        if (
            loggedUser.role === RoleEnum.SELLER ||
            loggedUser.role === RoleEnum.EDITOR1 ||
            loggedUser.role === RoleEnum.EDITOR2 ||
            loggedUser.role === RoleEnum.EDITOR3
        ) {
            queryParams += `&seller_uuid=${loggedUser.uuid}`
        }
        return queryParams
    }, [loggedUser])

    React.useEffect(() => {
        AdminApi.methods
            .getFinancials(loggedUser.companyUuid)
            .then(res => {
                dispatch(
                    AdminActions.methods.getFinancialsSuccessAction(
                        res.data.financials
                    )
                )
            })
            .catch(e => {
                alert.error(
                    'Houve um erro a ir buscar a lista de financeiras, por favor tente mais tarde'
                )
            })
    }, [alert, dispatch, loggedUser.companyUuid])

    // Effect to fetch the list of vehicles
    React.useEffect(() => {
        const queryParams = getQueryParamsDependingOnUser()
        AdminApi.methods
            .getVehiclesCompany(loggedUser.companyUuid, queryParams)
            .finally(() => setIsLoading(false))
            .then(
                res => {
                    setVehicles(res.data.vehicles)
                    dispatch(
                        AdminActions.methods.getVehiclesSuccessAction(
                            res.data.vehicles
                        )
                    )
                },
                e => {
                    alert.error('Error fetching the vehicles list')
                }
            )
    }, [getQueryParamsDependingOnUser, alert, loggedUser.companyUuid, dispatch])

    // const goToVehicleDetailsPage = (vehicle: Vehicle) => {
    //   navigate(RoutesEnum.VEHICLE_PAGE.replace(":vehicleId", vehicle.uuid));
    // };

    const goToVehicleSalePage = (vehicle: Vehicle) => {
        navigate(
            VehicleRoutesEnum.SALE_DETAILS.replace(':vehicleId', vehicle.uuid)
        )
    }

    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedVehicles = SortUtils.sortVehicles(
            visibleVehicles,
            header,
            order
        )
        setVisibleVehicles(sortedVehicles)
    }

    const downloadCSVSales = async (companyUuid: string, companyName: string) =>
        new Promise<Blob>(async () => {
            try {
                const response = await AdminApi.methods.getCSVSales(companyUuid)

                alert.success('O CSV será transferido automaticamente')
                // Create a link element to download the PDF
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(response.data)

                link.download = `Lista de Vendas - ${companyName}`

                dispatch(AdminActions.methods.getCSVSales(response.data))

                // Append the link to the document and trigger the click event
                document.body.appendChild(link)
                link.click()

                setTimeout(() => {
                    document.body.removeChild(link)
                }, 1000)
            } catch (error) {
                console.error('Error generating CSV:', error)
            }
        })

    return (
        <>
            <Row style={{ justifyContent: 'space-between' }}>
                <PageTitle>Veículos vendidos</PageTitle>
                {isAdmin && (
                    <PdfGeneratorButton
                        icon={'/icons/csv.png'}
                        action={() =>
                            downloadCSVSales(
                                company ? company.uuid : '',
                                company ? company.name : ''
                            )
                        }
                        title={''}
                    />
                )}
            </Row>
            <SoldVehiclesSearch
                updateFilter={updateFilter}
                filters={filters}
                clearAllFilters={clearAllFilters}
                clearFilter={clearFilter}
            />
            <SoldVehiclesAnalytics visibleVehicles={visibleVehicles} />
            <Table<Vehicle>
                isLoadingData={isLoading}
                rowStyles={value =>
                    value.availability === AvailabilityEnum.SOLD
                        ? { backgroundColor: '#fcdede' }
                        : {}
                }
                values={visibleVehicles}
                columns={TableUtils.getSoldVehiclesColumns(
                    loggedUser,
                    onHeaderClick
                )}
                onRowClick={goToVehicleSalePage}
                standExistOnly={standExistOnly}
                vehiclesExists={vehiclesExists}
            />
        </>
    )
}
