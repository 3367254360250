import React from "react";
import { WrapRow, SmallSectionTitle } from "../../../../shared/shared.styled";
import TextInput from "../../../../shared/components/text-input.component";
import { useFormikContext } from "formik";
import { SaleRequest } from "../../../sales.interfaces";
import { euroFormatter } from "../../../../shared/shared.utils";
import { SharedSectionProps } from "../../../../shared/shared.interface";

const PaymentSection = ({ isEditing }: SharedSectionProps) => {
  const { values, handleChange } = useFormikContext<SaleRequest>();

  const getTotalValueToPay = () => {
    const auxSignedValue = Number(values.onSignedContractValue) || 0;
    const auxDeliverValue = Number(values.onVehicleDeliveryValue) || 0;

    return auxSignedValue + auxDeliverValue;
  };

  return (
    <>
      <SmallSectionTitle>Pagamento</SmallSectionTitle>
      <WrapRow>
        <TextInput
          inEdition={isEditing}
          name="onSignedContractValue"
          onChange={handleChange}
          value={values.onSignedContractValue}
          title="Valor de confirmação de contrato"
          containerStyle={{ maxWidth: 300 }}
        />
        <TextInput
          inEdition={isEditing}
          name="onVehicleDeliveryValue"
          onChange={handleChange}
          value={values.onVehicleDeliveryValue}
          title="Valor na entrega da viatura"
          containerStyle={{ maxWidth: 250 }}
        />
        <TextInput
          inEdition={false}
          value={euroFormatter.format(getTotalValueToPay())}
          title="Valor total"
          containerStyle={{ maxWidth: 250 }}
        />
      </WrapRow>
    </>
  );
};

export default PaymentSection;
