import React, { InputHTMLAttributes } from "react";
import styled from "styled-components";
import { Column } from "../shared.styled";
import { Typography, Colors } from "../../configs/styled.config";
import { FormikErrors } from "formik";

export interface TextPasswordProps
  extends InputHTMLAttributes<HTMLInputElement> {
  title?: string;
  error?: string | FormikErrors<any>;
  containerStyle?: React.CSSProperties;
  inEdition?: boolean;
}

const TextPassword = ({
  title,
  error,
  required,
  containerStyle = {},
  inEdition = true,
  ...rest
}: TextPasswordProps) => {
  return (
    <Column
      style={{
        ...containerStyle,
        width: "100%",
      }}
    >
      {title ? (
        <StyledTitle isRequired={inEdition && !!required}>{title}</StyledTitle>
      ) : null}
      {inEdition ? (
        <StyledInput error={!!error} {...rest} />
      ) : (
        <DisplayInput {...rest}>{rest.value}</DisplayInput>
      )}
      {error && (
        <StyledError>
          {typeof error === "object" ? error[0] : error}
        </StyledError>
      )}
    </Column>
  );
};

export default TextPassword;

export const StyledTitle = styled("span")<{ isRequired: boolean }>`
  font-weight: bold;
  margin-bottom: 8px;
  ${Typography["Body_Text_#1_Regular_Left"]};
  font-size: 18px;
  color: #44566c;

  ${(props) =>
    props.isRequired
      ? `
   ::after {
     margin-left: 2px;
     content: '*';
     color: ${Colors["gold-pmauto"]};
   }
   `
      : ""}
`;

const StyledInput = styled.input.attrs(({ type }) => ({
  type: type || "password",
}))<{ error?: boolean }>`
  height: 36px;
  border-radius: 6px;
  border: solid 1px
    ${(props) =>
      props.error
        ? Colors["alive-red"]
        : props.value
        ? Colors.slate
        : Colors["light-grey-blue"]};

  ${Typography["Body_Text_#2_Regular_Left"]};

  padding: 8px 12px;
  outline: none;

  :disabled {
    background-color: rgba(216, 216, 216, 0.2);
  }
  :hover {
    border: solid 1px ${Colors.slate};
  }

  :focus {
    border: solid 1px ${Colors.slate};
  }

  ::placeholder {
    ${Typography["Body_Text_#2_Low_Contrast_Left"]};
    font-size: 16px;
  }
`;

const StyledError = styled("span")`
  ${Typography.Tiny_Text_Regular_Left};
  color: ${Colors["alive-red"]};
  margin-top: 4px;
  font-size: 12px;
`;

const DisplayInput = styled("div")`
  height: 36px;
  padding: 8px 0;

  ${Typography["Body_Text_#2_Regular_Left"]};
`;
