import React from 'react'
import { useFormikContext } from 'formik'
import { CreateVehicleRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import Dropdown from '../../shared/components/dropdown.component'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
import { DateInput } from '../../shared/components/date-input.component'
import styled from 'styled-components'

interface PublicFeaturesProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
}

const PublicFeatures = ({
    isEditing = true,
    rightElement = null,
}: PublicFeaturesProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateVehicleRequest
    >()

    return (
        <Section>
            <Row>
                <SectionTitle>Características Gerais</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow>
                <Column style={{ width: '60%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Marca"
                                name="brand"
                                placeholder="Ex: Porsche"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'brand',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.brand.replace(/  +/g, ' ')}
                                error={errors.brand}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                placeholder="Ex: Carrera"
                                name="model"
                                title="Modelo"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'model',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.model.replace(/  +/g, ' ')}
                                error={errors.model}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                placeholder="Ex: XM"
                                name="version"
                                title="Versão"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'version',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={
                                    values.version
                                        ? values.version.replace(/  +/g, ' ')
                                        : ''
                                }
                                error={errors.version}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Matrícula"
                                placeholder="Ex: 23-AA-14"
                                name="licensePlate"
                                maxLength={8}
                                onChange={evt => {
                                    const aux = evt.target.value.toUpperCase()
                                    setFieldValue('licensePlate', aux)
                                }}
                                value={values.licensePlate.replace(/ +/g, '')}
                                error={errors.licensePlate}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 3}>
                            <DateInput
                                inEdition={isEditing}
                                placeholder="DD-MM-AAAA"
                                name="dateVehicle"
                                title="Data da matrícula"
                                onChange={evt => {
                                    setFieldValue(
                                        'dateVehicle',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={
                                    values.dateVehicle ? values.dateVehicle : ''
                                }
                                error={errors.dateVehicle}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <Dropdown
                                inEdition={isEditing}
                                label="Estado"
                                placeholder="Escolha o estado do veículo"
                                value={values.state || ''}
                                selectValue={opt =>
                                    setFieldValue('state', opt.key)
                                }
                                options={DropdownUtils.getVehicleStateDropdownOptions()}
                                error={errors.state}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <Dropdown
                                inEdition={isEditing}
                                label="Tipo de veículo"
                                placeholder="Escolha o tipo de veículo"
                                value={values.typeVehicle || ''}
                                selectValue={opt =>
                                    setFieldValue('typeVehicle', opt.key)
                                }
                                options={DropdownUtils.getVehicleTypesDropdownOptions()}
                                error={errors.typeVehicle}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                title="Cor"
                                placeholder="Ex: Preto"
                                name="color"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'color',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={
                                    values.color
                                        ? values.color.replace(/  +/g, ' ')
                                        : ''
                                }
                                error={errors.color}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                onChange={handleChange}
                                name="numberDoors"
                                placeholder="Nº de portas"
                                title="Nº de portas"
                                type="number"
                                value={
                                    values.numberDoors ? values.numberDoors : ''
                                }
                                error={errors.numberDoors}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                onChange={handleChange}
                                placeholder="Nº de lugares"
                                name="numberSeats"
                                title="Nº de lugares"
                                type="number"
                                onBlur={evt => {
                                    setFieldValue(
                                        'numberSeats',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={
                                    values.numberSeats ? values.numberSeats : ''
                                }
                                error={errors.numberSeats}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                title="Nº do quadro"
                                placeholder="Ex: WBD201230F202312"
                                name="numBoard"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'numBoard',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={
                                    values.numBoard
                                        ? values.numBoard.replace(/  +/g, ' ')
                                        : ''
                                }
                                error={errors.numBoard}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                onChange={handleChange}
                                name="grossWeight"
                                placeholder="Ex: 2570"
                                title="Peso bruto (Kg)"
                                type="number"
                                value={
                                    values.grossWeight ? values.grossWeight : ''
                                }
                                error={errors.grossWeight}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 3}>
                            <TextInput
                                inEdition={isEditing}
                                onChange={handleChange}
                                placeholder="Ex: 1750"
                                name="tara"
                                title="Tara (Kg)"
                                type="number"
                                onBlur={evt => {
                                    setFieldValue(
                                        'tara',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.tara ? values.tara : ''}
                                error={errors.tara}
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default PublicFeatures

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
