import React from 'react'
import { RatioContainer, Column, WrapRow } from '../../shared/shared.styled'
import Dropdown from '../../shared/components/dropdown.component'
import SearchBar from '../../shared/components/search-bar.component'
import styled from 'styled-components'
import SmallButton from '../../shared/components/small-button.component'
import CloseIcon from '../../assets/icons/close.icon'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
import { AdminApi } from '../admin.api'
import { useAlert } from 'react-alert'
import { LoggedUser } from '../../shared/shared.models'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { AdminActions } from '../admin.store'
import { RoleEnum } from '../../shared/shared.enums'
import { FilterContactProps } from '../../shared/shared.interface'
import TextInput from '../../shared/components/text-input.component'

export enum ContactFilters {
    NAME = 'NAME',
    STATUS = 'STATUS',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    SELLER = 'SELLER',
}

const ContactSearch = ({
    updateFilter,
    filters,
    clearAllFilters,
    clearFilter,
}: FilterContactProps) => {
    const alert = useAlert()
    const dispatch = useDispatch()
    const [sellers, setSellers] = React.useState<LoggedUser[]>([])
    const { company } = useSelector(
        (state: IStoreInterface) => state.adminReducer
    )
    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )

    React.useEffect(() => {
        // Parse URL parameters
        const queryParams = new URLSearchParams(window.location.search)

        // Iterate over each parameter and apply filters
        queryParams.forEach((value, key) => {
            updateFilter(key as ContactFilters, value)
        })
    }, [updateFilter])

    const handleFilterChange = (filterKey: any, filterValue: any) => {
        // Update filter in state
        updateFilter(filterKey, filterValue)

        // Construct new URL with filter parameter
        const queryParams = new URLSearchParams(window.location.search)
        if (filterValue?.length === 0) {
            queryParams.delete(filterKey)
        } else {
            queryParams.set(filterKey, filterValue)
        }

        // Update URL without reloading the page
        window.history.replaceState(
            {},
            '',
            `${window.location.pathname}?${queryParams.toString()}`
        )
    }

    React.useEffect(() => {
        try {
            if (
                company &&
                (loggedUser.role === RoleEnum.ADMIN ||
                    loggedUser.role === RoleEnum.MANAGER)
            ) {
                AdminApi.methods.getUsers(company.uuid).then(
                    res => {
                        dispatch(
                            AdminActions.methods.getUsersSuccessAction(
                                res.data.users
                            )
                        )
                        const sellersResponse: LoggedUser[] =
                            res?.data?.users.length > 0
                                ? res?.data?.users.filter(user => {
                                      if (user.role !== RoleEnum.MANAGER) {
                                          return user
                                      }
                                  })
                                : []
                        setSellers(sellersResponse)
                    },
                    e => {
                        alert.error('Error fetching the users list')
                    }
                )
            }
        } catch (e) {
            alert.error(
                'Erro ao listar os utilizadores. Por favor tente mais tarde'
            )
        }
    }, [alert, company, dispatch, loggedUser.role])

    return (
        <SearchContainer>
            <WrapRow>
                <RatioContainer ratio={2 / 5}>
                    <SearchBar
                        handleChange={value =>
                            handleFilterChange(ContactFilters.NAME, value)
                        }
                        value={filters[ContactFilters.NAME]}
                        placeholder="Nome"
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 5}>
                    <Dropdown
                        placeholder="Situação"
                        selectValue={val =>
                            handleFilterChange(ContactFilters.STATUS, val.key)
                        }
                        value={filters[ContactFilters.STATUS]}
                        clearValue={() =>
                            handleFilterChange(ContactFilters.STATUS, '')
                        }
                        options={DropdownUtils.getContactStatusdDropdownOptions()}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 5}>
                    <TextInput
                        onChange={e =>
                            handleFilterChange(
                                ContactFilters.MONTH,
                                e.target.value
                            )
                        }
                        value={filters[ContactFilters.MONTH] || ''}
                        placeholder="Mês"
                        type="number"
                    />
                </RatioContainer>
                <RatioContainer ratio={0.5 / 5}>
                    <TextInput
                        onChange={e =>
                            handleFilterChange(
                                ContactFilters.YEAR,
                                e.target.value
                            )
                        }
                        value={filters[ContactFilters.YEAR] || ''}
                        placeholder="Ano"
                        type="number"
                    />
                </RatioContainer>
            </WrapRow>
            <WrapRow>
                {(loggedUser.role === RoleEnum.ADMIN ||
                    loggedUser.role === RoleEnum.MANAGER) && (
                    <RatioContainer ratio={1 / 5}>
                        <Dropdown
                            placeholder="Vendedor"
                            selectValue={val =>
                                handleFilterChange(
                                    ContactFilters.SELLER,
                                    val.key
                                )
                            }
                            value={filters[ContactFilters.SELLER] || undefined}
                            clearValue={() =>
                                handleFilterChange(ContactFilters.SELLER, '')
                            }
                            options={DropdownUtils.getUsersDropdownOptionsSale(
                                sellers
                            )}
                        />
                    </RatioContainer>
                )}
                <RatioContainer ratio={2 / 5}>
                    {Object.keys(filters).length > 0 ? (
                        <SmallButton
                            iconComponent={<CloseIcon />}
                            label="Limpar filtros"
                            onClick={() => {
                                clearAllFilters()
                                window.history.replaceState(
                                    {},
                                    '',
                                    `${window.location.pathname}`
                                )
                            }}
                        />
                    ) : null}
                </RatioContainer>
            </WrapRow>
        </SearchContainer>
    )
}

export default ContactSearch

const SearchContainer = styled(Column as any)`
    ${WrapRow} {
        > div {
            margin-bottom: 36px;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }
`
