import React from 'react'
import styled from 'styled-components'
import { Row, RatioContainer } from '../../shared/shared.styled'
import produce from 'immer'

export enum HeaderEnum {
    BRAND_AND_MODEL = 'BRAND_AND_MODEL',
    LICENSE_PLATE = 'LICENSE_PLATE',
    STOCK = 'STOCK',
    STATE = 'STATE',
}

interface ListHeadersProps {
    sortByHeader?: (header: HeaderEnum, newStatus: 'ASC' | 'DESC') => void
}

const ListHeadersSuperAdmin = ({ sortByHeader }: ListHeadersProps) => {
    const [orderStatus, setOrderStatus] = React.useState<{
        [key: string]: 'ASC' | 'DESC' | undefined
    }>({
        [HeaderEnum.BRAND_AND_MODEL]: undefined,
        [HeaderEnum.LICENSE_PLATE]: undefined,
        [HeaderEnum.STOCK]: undefined,
        [HeaderEnum.STATE]: undefined,
    })

    const _handleSort = (header: HeaderEnum) => {
        const newStatus =
            orderStatus[header] === undefined || orderStatus[header] === 'DESC'
                ? 'ASC'
                : 'DESC'
        const newState = produce(orderStatus, draftState => {
            draftState[header] = newStatus
            return
        })
        if (sortByHeader) {
            sortByHeader(header, newStatus)
        }
        setOrderStatus(newState)
    }

    return (
        <ListHeadersContainer>
            <RatioContainer ratio={2 / 10}>
                <ListHeadersText
                    onClick={() => _handleSort(HeaderEnum.BRAND_AND_MODEL)}
                >
                    Marca/Modelo/Versão
                </ListHeadersText>
            </RatioContainer>
            <RatioContainer ratio={1.5 / 10}>
                <ListHeadersText
                    onClick={() => _handleSort(HeaderEnum.LICENSE_PLATE)}
                >
                    Matrícula
                </ListHeadersText>
            </RatioContainer>
            <RatioContainer ratio={2 / 10}>
                <ListHeadersText onClick={() => _handleSort(HeaderEnum.STOCK)}>
                    Tempo em stock
                </ListHeadersText>
            </RatioContainer>
            <RatioContainer ratio={2 / 10}>
                <ListHeadersText onClick={() => _handleSort(HeaderEnum.STATE)}>
                    Estado
                </ListHeadersText>
            </RatioContainer>
        </ListHeadersContainer>
    )
}

export default ListHeadersSuperAdmin

const ListHeadersContainer = styled(Row as any)`
    align-items: center;
    border-bottom: 1px solid #b0bbc7;
    margin-bottom: 24px;
    padding-bottom: 8px;

    ${RatioContainer}:nth-child(n + 3) {
        text-align: center;
    }
`

const ListHeadersText = styled('span')`
    font-family: Merriweather-Light;
    font-size: 18px;
    font-weight: bold;
    color: #44566c;
    cursor: pointer;
`
