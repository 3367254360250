import { SaleRequest } from './sales.interfaces'

export const SalesUtils = {
    /**
     * Returns the total value of the sale minus the retake value
     */
    getTotalValue: (values: SaleRequest) => {
        const auxSalePrice = values.salePrice || 0
        const auxRetakeValue =
            values.retakeVehicle && values.retakeVehicle.retakeValue
                ? values.retakeVehicle.retakeValue
                : 0

        return auxSalePrice - auxRetakeValue
    },
}
