import React from 'react'
import { useFormikContext } from 'formik'
import { CreateContactRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import styled from 'styled-components'
import Checkbox from '../../shared/components/checkbox.component'

interface ContactUserInfoProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
}

const ContactUserInfo = ({
    isEditing = true,
    rightElement = null,
}: ContactUserInfoProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateContactRequest
    >()

    return (
        <Section>
            <Row>
                <SectionTitle>Dados Gerais do Contato</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Nome"
                                name="name"
                                placeholder="Ex: José Silva"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'name',
                                        evt.target.value.trimStart().trimEnd()
                                    )
                                }}
                                value={values.name.replace(/  +/g, ' ')}
                                error={errors.name}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                placeholder="Ex: Carvalhal"
                                name="parish"
                                title="Freguesia"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'parish',
                                        evt.target.value.trimStart().trimEnd()
                                    )
                                }}
                                value={values?.parish?.replace(/  +/g, ' ')}
                                error={errors?.parish}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Concelho"
                                placeholder="Ex: Águeda"
                                name="county"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'county',
                                        evt.target.value.trimStart().trimEnd()
                                    )
                                }}
                                value={values?.county?.replace(/  +/g, ' ')}
                                error={errors?.county}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Distrito"
                                placeholder="Ex: Aveiro"
                                name="district"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'district',
                                        evt.target.value.trimStart().trimEnd()
                                    )
                                }}
                                value={values?.district?.replace(/  +/g, ' ')}
                                error={errors?.district}
                            />
                        </RatioContainer>
                    </WrapRow>
                    <WrapRow style={{ alignItems: 'flex-end ' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                required={false}
                                title="Telemóvel"
                                placeholder="Ex: 912323212"
                                name="phone"
                                maxLength={9}
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'phone',
                                        evt.target.value.toString().trim()
                                    )
                                }}
                                value={values?.phone}
                                error={errors?.phone}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={2 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                title="Email"
                                required={false}
                                placeholder="Ex: cliente@ex.com"
                                name="email"
                                type="email"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'email',
                                        evt.target.value.trim().length > 0
                                            ? evt.target.value.trim()
                                            : null
                                    )
                                }}
                                value={values?.email}
                                error={errors?.email}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <Checkbox
                                disabled={!isEditing}
                                label="Pediu Crédito"
                                checked={values.credit}
                                onChange={e =>
                                    setFieldValue('credit', e.target.checked)
                                }
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default ContactUserInfo

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
