import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { RoleEnum } from '../shared.enums'

export const useIsAdmin = () => {
    const isAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            (state.adminReducer.user.role === RoleEnum.MANAGER ||
                state.adminReducer.user.role === RoleEnum.ADMIN)
    )

    return isAdmin
}
