import React from 'react'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../../../configs/store.config'
import { LoggedUser } from '../../../../shared/shared.models'
import { useFormikContext } from 'formik'
import {
    Section,
    SectionTitle,
    WrapRow,
} from '../../../../shared/shared.styled'
import TextInput from '../../../../shared/components/text-input.component'
import Dropdown from '../../../../shared/components/dropdown.component'
import { DropdownUtils } from '../../../../shared/utils/dropdowns.utils'
import moment from 'moment'
import { SaleRequest } from '../../../sales.interfaces'
import { useIsAdmin } from '../../../../shared/hooks/use-is-admin.hook'
import { DateInput } from '../../../../shared/components/date-input.component'
import { SharedSectionProps } from '../../../../shared/shared.interface'

export const ProcessSection = ({ isEditing }: SharedSectionProps) => {
    // Hooks
    const isAdmin = useIsAdmin()

    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )

    const users = useSelector(
        (state: IStoreInterface) => state.adminReducer.users
    )
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        SaleRequest
    >()
    return (
        <Section>
            <SectionTitle>Dados gerais</SectionTitle>
            <WrapRow>
                <TextInput
                    inEdition={isEditing}
                    name="processDate"
                    required={true}
                    value={moment(new Date()).format('DD-MM-YYYY')}
                    error={errors.processDate}
                    disabled={true}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Data de processo"
                />

                <TextInput
                    inEdition={isEditing}
                    name="processCreator"
                    required={true}
                    value={loggedUser.username}
                    disabled={true}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Criador do processo"
                />
            </WrapRow>
            <WrapRow>
                <TextInput
                    inEdition={isEditing}
                    name="dateOfSale"
                    required={true}
                    isReadOnly={true}
                    error={errors.dateOfSale}
                    value={values.dateOfSale.toString()}
                    type="date"
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Data de venda"
                />
                <Dropdown
                    inEdition={isEditing}
                    label="Vendedor"
                    required={true}
                    disabled={!isAdmin}
                    value={values.sellerUuid}
                    error={errors.sellerUuid}
                    selectValue={
                        !isAdmin
                            ? () => setFieldValue('sellerUuid', loggedUser.uuid)
                            : opt => setFieldValue('sellerUuid', opt.key)
                    }
                    clearValue={() => setFieldValue('sellerUuid', undefined)}
                    placeholder={
                        !isAdmin
                            ? loggedUser.firstName + ' ' + loggedUser.lastName
                            : 'Escolher vendedor'
                    }
                    options={
                        !isAdmin
                            ? DropdownUtils.getUsersDropdownOptionsSale([
                                  loggedUser,
                              ])
                            : DropdownUtils.getUsersDropdownOptionsSale(users)
                    }
                />
                <DateInput
                    inEdition={isEditing}
                    onChange={handleChange}
                    name="deliverDate"
                    value={values.deliverDate}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Data de entrega de viatura"
                />
            </WrapRow>
            <WrapRow>
                <TextInput
                    inEdition={isEditing}
                    onChange={handleChange}
                    type="number"
                    name="monthWarranty"
                    value={values.monthWarranty}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Garantia em meses"
                />
                <TextInput
                    inEdition={isEditing}
                    onChange={handleChange}
                    type="number"
                    name="mileageWarranty"
                    value={values.mileageWarranty}
                    containerStyle={{ maxWidth: '250px' }}
                    title="Garantia em quilómetros"
                />
            </WrapRow>
        </Section>
    )
}
