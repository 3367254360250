import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Column, Row } from '../../../shared/shared.styled'
import { Formik } from 'formik'
import TextInput from '../../../shared/components/text-input.component'
import PrimaryButton from '../../../shared/components/button.component'
import * as Yup from 'yup'
import styled from 'styled-components'
import { Typography, Colors } from '../../../configs/styled.config'
import CloseIcon from '../../../assets/icons/close.icon'
import { Financial } from '../../../shared/shared.models'
import { SelectedFinancial } from '../financials-section.component'

interface AddFinancialModalProps {
    addFinancial: (values: Financial) => void
    editFinancial: (values: SelectedFinancial) => void
    hideModal: () => void
    selectedFinancial: SelectedFinancial | undefined
}

const formikValidation = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    code: Yup.string().required('Campo obrigatório'),
})

export const AddFinancialModal = ({
    addFinancial,
    editFinancial,
    hideModal,
    selectedFinancial,
}: AddFinancialModalProps) => {
    // Redux state

    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const [executing, setExecuting] = useState(false)

    // Initial values
    const initialValues = selectedFinancial
        ? selectedFinancial.financial
        : {
              name: '',
              code: '',
          }

    async function submitFinancial(values: Financial) {
        setExecuting(true)
        if (selectedFinancial) {
            editFinancial({
                financial: values,
                financialIdx: selectedFinancial.financialIdx,
            })
        } else {
            addFinancial(values)
        }
        await sleep(1000)
        setExecuting(false)
    }

    return (
        <ReactModal
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: { zIndex: 1000 },
                content: { width: 500, margin: 'auto', height: 'fit-content' },
            }}
            isOpen={true}
        >
            <Column>
                <Row>
                    <StyledTitle>Nova Financeira</StyledTitle>
                    <CloseIcon
                        onClick={() => hideModal()}
                        style={{
                            marginLeft: 'auto',
                            width: 18,
                            height: 18,
                            color: Colors['gold-pmauto'],
                        }}
                    />
                </Row>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submitFinancial}
                    validationSchema={formikValidation}
                    validateOnChange={false}
                >
                    {({ handleChange, handleSubmit, errors, values }) => {
                        return (
                            <StyledColumn>
                                <TextInput
                                    value={values.name}
                                    onChange={handleChange}
                                    name="name"
                                    title="Nome"
                                    error={errors.name}
                                />
                                <TextInput
                                    value={values.code}
                                    onChange={handleChange}
                                    name="code"
                                    title="Código (BIC)"
                                    error={errors.code}
                                />
                                <PrimaryButton
                                    label="Guardar financeira"
                                    type="button"
                                    redTheme={true}
                                    onClick={() => handleSubmit()}
                                    disabled={executing || false}
                                />
                            </StyledColumn>
                        )
                    }}
                </Formik>
            </Column>
        </ReactModal>
    )
}

const StyledTitle = styled('h1')`
    ${Typography.H2_Regular_Left}
`

const StyledColumn = styled(Column as any)`
    padding: 24px 40px;

    > div {
        margin-bottom: 24px;
    }
`
