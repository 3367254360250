import React from 'react'
import {
    Section,
    SectionTitle,
    Row,
    Column,
    SectionSeparator,
    WrapRow,
    InfoPhrase,
} from '../../shared/shared.styled'
import Checkbox from '../../shared/components/checkbox.component'
import TextArea from '../../shared/components/text-area.component'
import { useFormikContext } from 'formik'
import { CreateVehicleRequest } from '../admin.interfaces'
import styled from 'styled-components'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { AvailabilityEnum } from '../../shared/shared.enums'
import {
    useIsEditor2,
    useIsEditor3,
} from '../../shared/hooks/use-is-editor.hook'

interface OnlineSectionProps {
    isEditing?: boolean
}

const OnlineSection = ({ isEditing }: OnlineSectionProps) => {
    const isAdmin = useIsAdmin()
    //const isEditor1 = useIsEditor1()
    const isEditor2 = useIsEditor2()
    const isEditor3 = useIsEditor3()
    const { values, handleChange, setFieldValue } = useFormikContext<
        CreateVehicleRequest
    >()
    return (
        <Section>
            <Row>
                <SectionTitle>Online</SectionTitle>
            </Row>
            <Row>
                <InfoPhrase>
                    Para ativar as opções de 'Publicar Online' e 'Destacar
                    veículo' o veículo necessita de estar 'Pronto para venda'
                </InfoPhrase>
            </Row>
            <SectionSeparator />
            <ResponsiveRow style={{ alignItems: 'flex-start' }}>
                <Column style={{ flex: 0.2, marginBottom: '10px' }}>
                    <Checkbox
                        disabled={
                            (!isAdmin && !isEditor3 && !isEditor2) ||
                            !isEditing ||
                            values.availability !==
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        label="Publicar Online"
                        checked={
                            values.online &&
                            values.availability ===
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        onChange={e =>
                            setFieldValue('online', e.target.checked)
                        }
                    />
                </Column>
                <Column style={{ flex: 0.8, marginBottom: '10px' }}>
                    <Checkbox
                        disabled={
                            (!isAdmin && !isEditor3 && !isEditor2) ||
                            !isEditing ||
                            values.availability !==
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        label="Destacar veículo"
                        checked={
                            values.isFeatured &&
                            values.availability ===
                                AvailabilityEnum.READY_FOR_SALE
                        }
                        onChange={e =>
                            setFieldValue('isFeatured', e.target.checked)
                        }
                    />
                </Column>
            </ResponsiveRow>
            <ResponsiveRow style={{ alignItems: 'flex-start' }}>
                <Column style={{ flex: 0.35 }}>
                    <TextArea
                        inEdition={isEditing}
                        containerStyles={{ marginBottom: 24 }}
                        onChange={handleChange}
                        name="shortDescription"
                        title="Pequena descrição"
                        value={values.shortDescription}
                    />
                </Column>
                <Column style={{ flex: 0.65, marginLeft: '20px' }}>
                    <TextArea
                        inEdition={isEditing}
                        containerStyles={{ marginBottom: 24 }}
                        onChange={handleChange}
                        title="Longa descrição"
                        name="longDescription"
                        value={values.longDescription}
                    />
                </Column>
            </ResponsiveRow>
        </Section>
    )
}

export default OnlineSection

const ResponsiveRow = styled(Row as any)`
    ${WrapRow} {
        > div {
            width: fit-content !important;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }

    @media screen and (max-width: 767px) {
        flex-direction: column;
    }
`
