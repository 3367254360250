import React from 'react'
import { Cost } from '../admin.interfaces'
import { Column, RatioContainer, Row } from '../../shared/shared.styled'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { Typography, Colors } from '../../configs/styled.config'
import { euroFormatter } from '../../shared/shared.utils'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'
import TranslationUtils from '../../shared/utils/translations.utils'
import { useIsWorkshop } from '../../shared/hooks/use-is-workshop.hook'

interface CostsTableProps {
    costs: Cost[]
    editCost: (costIdx: number) => void
    deleteCost: (costIdx: number) => void
    isEditing?: boolean
}

export const CostsTable = ({
    costs,
    editCost,
    deleteCost,
    isEditing = true,
}: CostsTableProps) => {
    const isAdmin = useIsAdmin()
    const isWorkshop = useIsWorkshop()
    return (
        <Column>
            <div style={{ marginBottom: 16 }}>
                <Row>
                    <RatioContainer ratio={1 / 8}>
                        <HeaderText>Tipo de custo</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={3 / 8}>
                        <HeaderText>Observações</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8}>
                        <HeaderText>Data de entrada</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={2 / 8}>
                        <HeaderText>Ordem de reparação</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8}>
                        <HeaderText>Valor</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8} />
                </Row>
                <Separator />
            </div>
            {costs.map((cost, idx) => (
                <TableRow key={`cost-${idx}`}>
                    <RatioContainer ratio={1 / 8}>
                        <RowText>
                            {TranslationUtils.getCostTypesTranslation(
                                cost.costType
                            )}
                        </RowText>
                    </RatioContainer>
                    <EllipsedContainer ratio={3 / 8}>
                        <EllipsedRowText>
                            {cost.description ? cost.description : '-'}
                        </EllipsedRowText>
                    </EllipsedContainer>
                    <RatioContainer ratio={1 / 8}>
                        <RowText>{cost.date}</RowText>
                    </RatioContainer>
                    <RatioContainer ratio={2 / 8}>
                        <RowText>
                            {cost.associatedInvoice
                                ? cost.associatedInvoice
                                : '-'}
                        </RowText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8}>
                        <RowText>{euroFormatter.format(cost.value)}</RowText>
                    </RatioContainer>
                    <ButtonsContainer ratio={1 / 8}>
                        {(isAdmin || isWorkshop) && isEditing ? (
                            <>
                                <EditIcon onClick={() => editCost(idx)} />
                                <DeleteForeverIcon
                                    onClick={() => deleteCost(idx)}
                                    style={{
                                        fill: Colors['gold-pmauto'],
                                    }}
                                />
                            </>
                        ) : null}
                    </ButtonsContainer>
                </TableRow>
            ))}
        </Column>
    )
}

const HeaderText = styled('span')`
    ${Typography['Body_Text_#1_High_Contrast_Left']}
`

const RowText = styled('div')`
    ${Typography['Body_Text_#2_Regular_Left']}
`

const EllipsedRowText = styled(RowText as any)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 24px;
`

const EllipsedContainer = styled(RatioContainer as any)`
    text-overflow: ellipsis;
    overflow: hidden;
`

const ButtonsContainer = styled(RatioContainer as any)`
    justify-content: flex-end;

    svg {
        width: 20px;
        height: 20px;
        fill: inherit;
        cursor: pointer;
    }

    svg:not(:last-child) {
        margin-right: 16px;
    }
`

const TableRow = styled(Row as any)`
    height: 36px;

    :nth-child(odd) {
        background-color: #fafafa;
    }
`

const Separator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`
