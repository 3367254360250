import React from 'react'
import { WrapRow, SmallSectionTitle } from '../../../../shared/shared.styled'
import Checkbox from '../../../../shared/components/checkbox.component'
import TextInput from '../../../../shared/components/text-input.component'
import { useFormikContext } from 'formik'
import { SaleRequest } from '../../../sales.interfaces'
import { SharedSectionProps } from '../../../../shared/shared.interface'
import { useIsWorkshop } from '../../../../shared/hooks/use-is-workshop.hook'

export const RetakeSection = ({ isEditing }: SharedSectionProps) => {
    const isWorkshop = useIsWorkshop()
    const { values, handleChange, setFieldValue } = useFormikContext<
        SaleRequest
    >()

    const [retakeCar, setRetakeCar] = React.useState<boolean>(
        !!values.retakeVehicle
    )

    const setRetakeCarAndRemoveValues = () => {
        // If retakeCar is true, when we pass it to false, we want to remove the info from the formik values
        if (retakeCar) {
            setFieldValue('retakeVehicle', null)
        }
        setRetakeCar(rc => !rc)
    }

    return (
        <>
            <SmallSectionTitle>Retoma</SmallSectionTitle>
            <Checkbox
                disabled={!isEditing}
                checked={retakeCar}
                onChange={() => setRetakeCarAndRemoveValues()}
                containerStyle={{ marginBottom: 16 }}
                label="Retoma"
            />
            <WrapRow>
                <TextInput
                    inEdition={isEditing}
                    title="Marca"
                    name="retakeVehicle.brand"
                    value={values.retakeVehicle?.brand ?? ''}
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                    disabled={!retakeCar}
                />
                <TextInput
                    inEdition={isEditing}
                    title="Modelo"
                    name="retakeVehicle.model"
                    value={values.retakeVehicle?.model ?? ''}
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                    disabled={!retakeCar}
                />
                <TextInput
                    inEdition={isEditing}
                    title="Matrícula"
                    name="retakeVehicle.licensePlate"
                    value={values.retakeVehicle?.licensePlate ?? ''}
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                    disabled={!retakeCar}
                />
                <TextInput
                    inEdition={isEditing}
                    title="Quilómetros"
                    name="retakeVehicle.mileage"
                    value={values.retakeVehicle?.mileage ?? ''}
                    onChange={handleChange}
                    containerStyle={{ maxWidth: '250px' }}
                    disabled={!retakeCar}
                />
                {!isWorkshop && (
                    <TextInput
                        inEdition={isEditing}
                        title="Valor da retoma"
                        name="retakeVehicle.retakeValue"
                        value={values.retakeVehicle?.retakeValue ?? ''}
                        onChange={handleChange}
                        type="number"
                        containerStyle={{ maxWidth: '250px' }}
                        disabled={!retakeCar}
                    />
                )}
            </WrapRow>
        </>
    )
}
