import React from 'react'
import { SaleRequest } from '../sales.interfaces'
import ReactModal from 'react-modal'
import {
    Column,
    SmallSectionTitle,
    WrapRow,
    SectionTitle,
    Row,
} from '../../shared/shared.styled'
import { TextDisplay } from '../../shared/components/text-display.component'
import { SalesUtils } from '../sales.util'
import PrimaryButton from '../../shared/components/button.component'
import styled from 'styled-components'
import moment from 'moment'
import { Vehicle, Stand } from '../../shared/shared.models'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { getUserFullName, euroFormatter } from '../../shared/shared.utils'
import TranslationUtils from '../../shared/utils/translations.utils'

interface SaleResumeModalProps {
    values: SaleRequest
    vehicle: Vehicle
    onCancel: () => void
    onSubmit: (values: SaleRequest) => void
    isLoading: boolean
    stands: Stand[]
}

export const SaleResumeModal = ({
    values,
    vehicle,
    onCancel,
    onSubmit,
    isLoading,
    stands,
}: SaleResumeModalProps) => {
    const users = useSelector(
        (state: IStoreInterface) => state.adminReducer.users
    )

    const seller = useSelector(
        (state: IStoreInterface) => state.adminReducer.user
    )

    const getSellerByUuid = (uuid: string) => {
        const user = users.find(u => u.uuid === uuid)
        if (user) {
            return getUserFullName(user)
        } else {
            return seller?.username
        }
    }
    const getStandByUuid = (uuid: string) => {
        const stand = stands.find(s => s.uuid === uuid)
        if (stand) {
            return stand.name
        } else {
            return 'N/A'
        }
    }

    return (
        <ReactModal
            isOpen={true}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: { zIndex: 1000 },
                content: { width: 640, margin: 'auto', overflowY: 'auto' },
            }}
        >
            <Column style={{ overflowY: 'auto' }}>
                <h1>Resumo de venda</h1>
                <SectionTitle style={{ marginTop: 0 }}>Veículo</SectionTitle>
                <WrapRow>
                    <TextDisplay title="Marca/Modelo/Versão">
                        {`${vehicle.brand} ${vehicle.model} ${vehicle.version ??
                            ''}`}
                    </TextDisplay>
                    <TextDisplay title="Matrícula">
                        {vehicle.licensePlate}
                    </TextDisplay>
                    <TextDisplay title="Ano/Mês">
                        {vehicle.dateVehicle || 'N/A'}
                    </TextDisplay>
                    <TextDisplay title="Origem">
                        {values.origin
                            ? TranslationUtils.getOriginTranslation(
                                  values.origin
                              )
                            : 'N/A'}
                    </TextDisplay>
                    <TextDisplay title="Quilómetros à data de venda">
                        {values.vehicleKilometersOnSaleDate || 'N/A'}
                    </TextDisplay>
                    <TextDisplay title="Stand">
                        {values.standLocation
                            ? getStandByUuid(values.standLocation)
                            : 'N/A'}
                    </TextDisplay>
                </WrapRow>
                <SectionTitle style={{ marginTop: 0 }}>Cliente</SectionTitle>
                <WrapRow>
                    <TextDisplay title="Nome">{values.client.name}</TextDisplay>
                    <TextDisplay title="Nº CC">
                        {values.client.citizenshipNumber || 'N/A'}
                    </TextDisplay>
                </WrapRow>
                <SectionTitle style={{ marginTop: 0, marginBottom: 24 }}>
                    Valores
                </SectionTitle>

                <TextDisplay
                    containerStyles={{ marginBottom: 12 }}
                    title="Preço de tabela"
                >
                    {euroFormatter.format(vehicle.sellerPrice || 0)}
                </TextDisplay>
                {values.retakeVehicle ? (
                    <Column>
                        <SmallSectionTitle>Retoma</SmallSectionTitle>
                        <WrapRow>
                            <TextDisplay title="Marca">
                                {values.retakeVehicle.brand}
                            </TextDisplay>
                            <TextDisplay title="Modelo">
                                {values.retakeVehicle.model}
                            </TextDisplay>
                            <TextDisplay title="Matrícula">
                                {values.retakeVehicle.licensePlate}
                            </TextDisplay>
                            <TextDisplay title="Quilómetros">
                                {values.retakeVehicle.mileage}
                            </TextDisplay>
                        </WrapRow>
                    </Column>
                ) : null}
                <TextDisplay title="Valor total">
                    {euroFormatter.format(SalesUtils.getTotalValue(values))}
                </TextDisplay>
                {values.loanValue ? (
                    <TextDisplay title="Valor financiado">
                        {euroFormatter.format(values.loanValue)}
                    </TextDisplay>
                ) : null}
                <SectionTitle>Vendedor</SectionTitle>
                <WrapRow>
                    <TextDisplay title="Data de venda">
                        {moment(values.dateOfSale).format('DD-MM-YYYY')}
                    </TextDisplay>
                    <TextDisplay title="Vendedor">
                        {getSellerByUuid(values.sellerUuid)}
                    </TextDisplay>
                </WrapRow>
            </Column>

            <ButtonsRow>
                <PrimaryButton
                    type="button"
                    label="Cancelar"
                    onClick={onCancel}
                />
                <PrimaryButton
                    type="submit"
                    label="Vender"
                    redTheme={true}
                    isLoading={isLoading}
                    onClick={() => onSubmit(values)}
                />
            </ButtonsRow>
        </ReactModal>
    )
}

const ButtonsRow = styled(Row as any)`
    justify-content: flex-end;

    button:not(:last-child) {
        margin-right: 24px;
    }
`
