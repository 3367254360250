import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { AdminApi } from '../admin.api'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import CreateVehicleExtras from '../components/create-vehicle-extras.component'
import produce from 'immer'
import { CreateVehicleRequest } from '../admin.interfaces'
import MotorSection from '../components/motor-section.component'
import PublicFeatures from '../components/public-features.component'
import ManagementSection from '../components/management-section.component'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import FixedPageTitle from '../components/fixed-page-title.component'
import { CostsSection } from '../components/costs-section.component'
import { createVehicleSchema } from '../validation.schemas'
import { Container } from '../../shared/shared.styled'
import PrimaryButton from '../../shared/components/button.component'
import { useAlert } from 'react-alert'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'

const CreateVehiclePage = () => {
    // Hooks Initialization
    const dispatch = useDispatch()
    const isAdmin = useIsAdmin()
    const alert = useAlert()

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    hideConfirmationModal()
                    window.history.back()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )

    /**
     * Helper function to get the initial formikValues
     * @param vehicle Vehicle | undefined
     */
    const getFormikInitialValues = () => {
        return {
            brand: '',
            model: '',
            version: undefined,
            licensePlate: '',
            fuel: undefined,
            state: undefined,
            dateVehicle: undefined,
            motorPower: undefined,
            numberDoors: undefined,
            numberSeats: undefined,
            color: undefined,
            mileage: undefined,
            cylinder: undefined,
            sellerPrice: undefined,
            standLocation: undefined,
            availability: undefined,
            vehicleExtras: [],
            photos: [],
            buyPrice: undefined,
            previewCosts: undefined,
            costs: [],
        }
    }

    /**
     * Calls the API endpoint to save a vehicle, and handles the loading/error states
     * of the form
     * @param values CreateVehicleRequest
     * @param actions FormikHelpers<CreateVehicleRequest>
     */
    const createVehicle = async (
        values: CreateVehicleRequest,
        actions: FormikHelpers<CreateVehicleRequest>
    ) => {
        try {
            actions.setSubmitting(true)
            const res = await AdminApi.methods.createVehicle(values)
            dispatch(
                AdminActions.methods.createVehicleSuccessAction(
                    res.data.vehicle
                )
            )
            navigate(
                RoutesEnum.VEHICLE_PAGE.replace(
                    ':vehicleId',
                    res.data.vehicle.uuid
                )
            )
        } catch (e) {
            alert.error('Erro ao criar o veículo. Por favor tente mais tarde')
        }
    }

    const checkIfFormIsDirtyAndClose = (isDirty: boolean) => {
        if (isDirty) {
            showConfirmationModal()
        } else {
            window.history.back()
        }
    }

    const renderButtons = (isSubmitting: boolean, isDirty: boolean) => (
        <>
            <PrimaryButton
                label="Cancelar"
                onClick={() => checkIfFormIsDirtyAndClose(isDirty)}
            />
            <PrimaryButton
                isLoading={isSubmitting}
                type="submit"
                label="Criar veículo"
                redTheme={true}
            />
        </>
    )

    return (
        <Container>
            <Formik
                onSubmit={createVehicle}
                initialValues={getFormikInitialValues()}
                validateOnChange={false}
                validationSchema={createVehicleSchema()}
                enableReinitialize={true}
            >
                {({
                    values,
                    setFieldValue,
                    isSubmitting,
                    handleSubmit,
                    dirty,
                }) => (
                    <Form
                        translate="yes"
                        onSubmit={isSubmitting ? () => null : handleSubmit}
                    >
                        <FixedPageTitle
                            label="Criar um novo veículo"
                            rightButtons={renderButtons(isSubmitting, dirty)}
                        />

                        <PublicFeatures isEditing={true} />
                        {/* {vehicle && <MediaSection vehicleId={vehicle.uuid} />} */}

                        <MotorSection isEditing={true} />

                        <ManagementSection isEditing={true} />
                        {isAdmin && <CostsSection isEditing={true} />}

                        <CreateVehicleExtras
                            isEditing={true}
                            handleChange={(value: string) => {
                                setFieldValue(
                                    'vehicleExtras',
                                    produce<string[]>(
                                        values.vehicleExtras,
                                        draftState => {
                                            const extraIdx = draftState.findIndex(
                                                s => s === value
                                            )
                                            if (extraIdx > -1) {
                                                draftState.splice(extraIdx, 1)
                                            } else {
                                                draftState.push(value)
                                            }
                                        }
                                    )
                                )
                            }}
                            selectedExtras={values.vehicleExtras}
                        />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CreateVehiclePage
