import React from 'react'
import { RatioContainer, Column, WrapRow } from '../../shared/shared.styled'
import Dropdown from '../../shared/components/dropdown.component'
import SearchBar from '../../shared/components/search-bar.component'
import styled from 'styled-components'
import MinMaxFilter from '../../shared/components/min-max-filter.component'
import SmallButton from '../../shared/components/small-button.component'
import CloseIcon from '../../assets/icons/close.icon'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
import { FilterProps } from '../../shared/shared.interface'
import { AdminApi } from '../admin.api'
import { useAlert } from 'react-alert'
import { Stand } from '../../shared/shared.models'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

export enum VehicleFilters {
    BRAND_OR_MODEL = 'BRAND_OR_MODEL',
    LICENSE_PLATE = 'LICENSE_PLATE',
    LOCATION = 'LOCATION',
    AVAILABILITY = 'AVAILABILITY',
    PRICE_RANGE = 'PRICE_RANGE',
    VEHICLE_TYPE = 'VEHICLE_TYPE',
    SALE_MONTH = 'SALE_MONTH',
    SALE_YEAR = 'SALE_YEAR',
    FINANCED_VEHICLE = 'FINANCED_VEHICLE',
    LOAN_COMPANY = 'LOAN_COMPANY',
    SELLER = 'SELLER',
    REPAIR_VEHICLE = 'REPAIR_VEHICLE',
    NO_PRICE_VEHICLE = 'NO_PRICE_VEHICLE',
    SHIFT = 'SHIFT',
    STAND_LOCATION = 'STAND_LOCATION',
}

const VehicleSearch = ({
    updateFilter,
    filters,
    clearAllFilters,
    clearFilter,
}: FilterProps) => {
    const alert = useAlert()
    const [stands, setStands] = React.useState<Stand[]>([])
    const { company } = useSelector(
        (state: IStoreInterface) => state.adminReducer
    )

    React.useEffect(() => {
        try {
            if (company) {
                AdminApi.methods.getStands(company.uuid).then(
                    res => {
                        setStands(res.data.stands)
                    },
                    e => {
                        alert.error('Error fetching the stands list')
                    }
                )
            }
        } catch (e) {
            alert.error('Erro ao listar os stands. Por favor tente mais tarde')
        }
    }, [alert, company])

    React.useEffect(() => {
        // Parse URL parameters
        const queryParams = new URLSearchParams(window.location.search)

        // Iterate over each parameter and apply filters
        queryParams.forEach((value, key) => {
            updateFilter(key as VehicleFilters, value)
        })
    }, [updateFilter])

    const handleFilterChange = (filterKey: any, filterValue: any) => {
        // Update filter in state
        updateFilter(filterKey, filterValue)

        // Construct new URL with filter parameter
        const queryParams = new URLSearchParams(window.location.search)
        if (filterValue?.length === 0) {
            queryParams.delete(filterKey)
        } else {
            queryParams.set(filterKey, filterValue)
        }

        // Update URL without reloading the page
        window.history.replaceState(
            {},
            '',
            `${window.location.pathname}?${queryParams.toString()}`
        )
    }

    return (
        <SearchContainer>
            <WrapRow>
                <RatioContainer ratio={1.25 / 5}>
                    <SearchBar
                        handleChange={value =>
                            handleFilterChange(
                                VehicleFilters.BRAND_OR_MODEL,
                                value
                            )
                        }
                        value={filters[VehicleFilters.BRAND_OR_MODEL]}
                        placeholder="Marca ou Modelo"
                    />
                </RatioContainer>
                <RatioContainer ratio={1.25 / 5}>
                    <SearchBar
                        handleChange={value =>
                            handleFilterChange(
                                VehicleFilters.LICENSE_PLATE,
                                value
                            )
                        }
                        value={filters[VehicleFilters.LICENSE_PLATE]}
                        placeholder="Matrícula"
                    />
                </RatioContainer>
                <RatioContainer ratio={0.75 / 5}>
                    <Dropdown
                        placeholder="Localização"
                        selectValue={val =>
                            handleFilterChange(VehicleFilters.LOCATION, val.key)
                        }
                        value={filters[VehicleFilters.LOCATION] || undefined}
                        clearValue={() =>
                            handleFilterChange(VehicleFilters.LOCATION, '')
                        }
                        options={DropdownUtils.getStandLocationDropdownOptions(
                            stands
                        )}
                    />
                </RatioContainer>
                <RatioContainer ratio={0.75 / 5}>
                    <Dropdown
                        placeholder="Caixa"
                        selectValue={val =>
                            handleFilterChange(VehicleFilters.SHIFT, val.key)
                        }
                        value={filters[VehicleFilters.SHIFT] || undefined}
                        clearValue={() =>
                            handleFilterChange(VehicleFilters.SHIFT, '')
                        }
                        options={DropdownUtils.getShiftOptions()}
                    />
                </RatioContainer>
                <RatioContainer ratio={1.12 / 5}>
                    <Dropdown
                        placeholder="Tipo de veículo"
                        selectValue={val =>
                            handleFilterChange(
                                VehicleFilters.VEHICLE_TYPE,
                                val.key
                            )
                        }
                        value={
                            filters[VehicleFilters.VEHICLE_TYPE] || undefined
                        }
                        options={DropdownUtils.getVehicleTypesDropdownOptions()}
                        clearValue={() => {
                            clearFilter(VehicleFilters.VEHICLE_TYPE)
                            handleFilterChange(VehicleFilters.VEHICLE_TYPE, '')
                        }}
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 5}>
                    <MinMaxFilter
                        values={filters[VehicleFilters.PRICE_RANGE] || {}}
                        handleChangeCb={val =>
                            handleFilterChange(VehicleFilters.PRICE_RANGE, val)
                        }
                    />
                </RatioContainer>
                <RatioContainer ratio={1.5 / 5}>
                    {Object.keys(filters).length > 0 ? (
                        <SmallButton
                            iconComponent={<CloseIcon />}
                            label="Limpar filtros"
                            onClick={() => {
                                clearAllFilters()
                                window.history.replaceState(
                                    {},
                                    '',
                                    `${window.location.pathname}`
                                )
                            }}
                        />
                    ) : null}
                </RatioContainer>
            </WrapRow>
        </SearchContainer>
    )
}

export default VehicleSearch

const SearchContainer = styled(Column as any)`
    ${WrapRow} {
        > div {
            margin-bottom: 36px;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }
`
