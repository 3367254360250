import React from 'react'
import styled from 'styled-components'
import { SmallSectionTitle } from '../../shared/shared.styled'
import { Colors } from '../../configs/styled.config'
import { useFormikContext } from 'formik'
import { Vehicle, VehiclePhoto } from '../../shared/shared.models'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { AdminApi } from '../admin.api'
import { HelperMenu } from './helper-menu.component'
import { FullSizeGallery } from './full-screen-gallery.component'
import { useAlert } from 'react-alert'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import {
    useIsEditor1_2,
    useIsEditor3,
} from '../../shared/hooks/use-is-editor.hook'
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd'

interface ImageGalleryProps {
    inEdition: boolean
}

const ImageGallery = ({ inEdition = true }: ImageGalleryProps) => {
    // Hooks
    const {
        values: { photos },
        setFieldValue,
    } = useFormikContext<Vehicle>()

    const alert = useAlert()
    const isAdmin = useIsAdmin()
    const isEditor1_2 = useIsEditor1_2()
    const isEditor3 = useIsEditor3()

    // Local state
    const [currentPhoto, setCurrentPhoto] = React.useState<VehiclePhoto | null>(
        null
    )
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const [isFullscreenVisibile, setIsFullscreenVisible] = React.useState(false)

    const deletePhoto = React.useCallback(async () => {
        if (currentPhoto) {
            try {
                await AdminApi.methods.deletePhoto(currentPhoto.uuid)
                let newPhotos: VehiclePhoto[] = []
                if (photos) {
                    newPhotos = [...photos]
                }
                newPhotos = newPhotos.filter(
                    photo => photo.uuid !== currentPhoto.uuid
                )
                setFieldValue('photos', newPhotos)
                hideConfirmationModal()
                alert.success('Imagem eliminada com sucesso')
            } catch (e) {
                alert.error(
                    'Erro ao eliminar a imagem. Por favor tente mais tarde'
                )
            }
        }
    }, [currentPhoto, photos, alert, setFieldValue])

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Está prestes a eliminar uma foto"
                description="Tem a certeza que quer eliminar esta foto?"
                onCancel={hideConfirmationModal}
                onClick={deletePhoto}
            />
        ),
        [deletePhoto]
    )

    async function setPhotoAsFeatured() {
        if (currentPhoto) {
            try {
                const res = await AdminApi.methods.updatePhoto(
                    currentPhoto.uuid,
                    {
                        isFeatured: !currentPhoto.isFeatured,
                    }
                )
                setFieldValue('photos', res.data.photos)
                alert.success('Imagem mudada com sucesso')
            } catch (e) {
                alert.error(
                    'Erro ao modificar esta foto. Por favor tente mais tarde'
                )
            }
        }
    }

    const resetState = () => {
        setAnchorEl(null)
        setCurrentPhoto(null)
    }

    // Handlers
    const handleClick = (
        event: React.MouseEvent<HTMLImageElement>,
        photo: VehiclePhoto
    ) => {
        if (anchorEl && anchorEl.id === event.currentTarget.id) {
            setAnchorEl(null)
        } else {
            setAnchorEl(event.currentTarget)
        }
        if (currentPhoto && currentPhoto.uuid === photo.uuid) {
            setCurrentPhoto(null)
        } else {
            setCurrentPhoto(photo)
        }
    }

    const getListStyle = (isDraggingOver: boolean) =>
        ({
            background: isDraggingOver ? Colors['gold-pmauto'] : 'lightgrey',
            display: 'flex',
            padding: photos?.length,
            overflow: 'auto',
        } as React.CSSProperties)

    const reorder = (
        list: VehiclePhoto[],
        startIndex: number,
        endIndex: number
    ) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)

        for (var i = 0; i < result.length; i++) {
            result[i].orderNum = i + 1
        }

        return result
    }

    //const grid = 8

    // const getItemStyle = (isDragging: any, draggableStyle: any) => ({
    //     // some basic styles to make the items look a bit nicer
    //     userSelect: 'none',
    //     padding: grid * 2,
    //     margin: `0 ${grid}px 0 0`,

    //     // change background colour if dragging
    //     background: isDragging ? 'lightgrey' : 'grey',

    //     // styles we need to apply on draggables
    //     ...draggableStyle,
    // })

    const orderedPhotos = photos
        ? [...photos].sort(({ orderNum: id1 }, { orderNum: id2 }) => id1 - id2)
        : []

    function onDragEnd(result: DropResult) {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items = reorder(
            orderedPhotos ? orderedPhotos : [],
            result.source.index,
            result.destination.index
        )
        setFieldValue('photos', items)
    }
    if (photos) {
        return photos.length > 0 ? (
            <ImageGalleryContainer inEdition={inEdition}>
                <SmallSectionTitle style={{ marginBottom: 36 }}>
                    Galeria de imagens
                </SmallSectionTitle>
                {inEdition ? (
                    <Description>
                        Pode arrastar as imagens de forma a mudar a sua ordem e,
                        ao mesmo tempo, destacar, pré-visualizar e apagar as
                        mesmas.
                    </Description>
                ) : null}
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable
                        isDropDisabled={inEdition ? false : true}
                        droppableId="droppable"
                        direction="horizontal"
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                                {orderedPhotos.map((photo, index) => (
                                    <Draggable
                                        key={photo.uuid}
                                        draggableId={photo.uuid}
                                        index={index}
                                    >
                                        {(provided, snapshot) => (
                                            <PhotoThumbnail
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                isFeatured={photo.isFeatured}
                                                id={`photo-${photo.uuid}`}
                                                src={photo.url}
                                                alt={photo.url}
                                                onClick={
                                                    (!isAdmin &&
                                                        !isEditor1_2 &&
                                                        !isEditor3) ||
                                                    !inEdition
                                                        ? () => {
                                                              setCurrentPhoto(
                                                                  photo
                                                              )
                                                              setIsFullscreenVisible(
                                                                  true
                                                              )
                                                          }
                                                        : (e: any) =>
                                                              handleClick(
                                                                  e,
                                                                  photo
                                                              )
                                                }
                                            />
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                {isFullscreenVisibile && currentPhoto && (
                    <FullSizeGallery
                        photos={photos}
                        currentPhoto={currentPhoto}
                        closeOverlay={() => {
                            resetState()
                            setIsFullscreenVisible(false)
                        }}
                    />
                )}
                {currentPhoto && anchorEl && (
                    <HelperMenu
                        showConfirmationModal={showConfirmationModal}
                        closePopper={resetState}
                        photo={currentPhoto}
                        anchorEl={anchorEl}
                        setImageAsFeatured={setPhotoAsFeatured}
                        showFullScreenImage={() => setIsFullscreenVisible(true)}
                    />
                )}
            </ImageGalleryContainer>
        ) : (
            <NoImages>Este carro ainda não tem imagens</NoImages>
        )
    } else {
        return <NoImages>Este carro ainda não tem imagens</NoImages>
    }
}

export default ImageGallery

const ImageGalleryContainer = styled('div')<{ inEdition: boolean }>(
    props => `
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${props.inEdition ? 'max-width: 50vw;' : ''};
  
`
)

const PhotoThumbnail = styled('img' as any)<{ isFeatured: boolean }>(
    props => `
  width: 150px;
  height: 150px;
  margin-right: 16px;
  cursor: pointer;
  border-radius: 4px;
  padding:8px;

  ${props.isFeatured ? 'border: 3px solid red' : ''};

`
)

const NoImages = styled('div')`
    font-family: Merriweather-Light;
    font-size: 16px;
`

const Description = styled('span')`
    font-family: Merriweather-Light;
    margin-bottom: 24px;
    font-size: 16px;

    > span {
        font-weight: bold;
    }
`
