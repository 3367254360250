import React from 'react'
import { useFormikContext } from 'formik'
import {
    Column,
    Row,
    WrapRow,
    RatioContainer,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import ColorInput from '../../shared/components/color-input.component'
import styled from 'styled-components'
import { CompanyPhoto } from './company-photo.component'
import CompanyStands from './company-stands.component'
import CompanyUsers from './company-users.component'
import { Company } from '../../shared/shared.models'
import { FinancialsSection } from './financials-section.component'
import { Colors, Typography } from '../../configs/styled.config'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import { Tabs, AppBar, Tab } from '@material-ui/core'
import { TabPanel } from '../../shared/components/tabs/tabs-tab-panel.component'
import CompanySuppliers from './company-suppliers.component'

interface CompanyFeaturesProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
}

const CompanyFeatures = ({
    isEditing = true,
    rightElement = null,
}: CompanyFeaturesProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        Company
    >()

    const tabs = [
        { id: 'general-details', name: 'Detalhes Gerais' },
        { id: 'stand-list', name: 'Lista de Stands' },
        { id: 'user-list', name: 'Lista de Utilizadores' },
        { id: 'supplier-list', name: 'Lista de Fornecedores' },
    ]

    const [tabSelected, setTabSelected] = React.useState(0)

    const theme = createMuiTheme({
        overrides: {
            MuiAppBar: {
                colorPrimary: {
                    backgroundColor: 'transparent', // Transparent background for AppBar
                    borderBottom: '1px solid #e8e8e8',
                    boxShadow: 'none',
                },
            },
            MuiTab: {
                root: {
                    // Custom styling for the Tab label
                    '& .MuiTab-label': {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    },
                },
            },
        },
    })

    function a11yProps(index: number) {
        return {
            id: `management-tab-${index}`,
            'aria-controls': `management-tabpanel-${index}`,
        }
    }

    return (
        <SectionCompany>
            <ThemeProvider theme={theme}>
                <AppBar position="static">
                    <Tabs
                        value={tabSelected}
                        onChange={(event, newValue) => setTabSelected(newValue)}
                        aria-label="management tabs"
                    >
                        {tabs?.map((elem, index) => (
                            <Tab
                                label={
                                    <TabsRowLabel>{elem?.name}</TabsRowLabel>
                                }
                                {...a11yProps(index)}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                <TabPanel value={tabSelected} index={0}>
                    <ResponsiveRow>
                        <Column style={{ width: '100%' }}>
                            <WrapRow style={{ alignItems: 'flex-start' }}>
                                <RatioContainer ratio={1 / 2}>
                                    <CompanyPhoto
                                        company={values}
                                        title="Logo da Empresa"
                                        inEdition={isEditing}
                                    />
                                </RatioContainer>
                                <RatioContainer ratio={1 / 3}>
                                    <TextInput
                                        inEdition={isEditing}
                                        required={true}
                                        title="Nome"
                                        name="name"
                                        placeholder="Ex: Empresa do José"
                                        onChange={handleChange}
                                        value={values.name}
                                        error={errors.name}
                                    />
                                </RatioContainer>
                                <RatioContainer ratio={1 / 3}>
                                    <ColorInput
                                        inEdition={isEditing}
                                        required={true}
                                        name="color"
                                        title="Cor primária"
                                        onChange={evt => {
                                            const aux = evt.target.value.toLowerCase()
                                            setFieldValue('color', aux)
                                        }}
                                        value={values.color}
                                        error={errors.color}
                                    />
                                </RatioContainer>
                                <RatioContainer ratio={1 / 2}>
                                    <FinancialsSection isEditing={isEditing} />
                                </RatioContainer>
                            </WrapRow>
                        </Column>
                        <div style={{ maxWidth: '50%' }}>{rightElement}</div>
                    </ResponsiveRow>
                </TabPanel>
                <TabPanel value={tabSelected} index={1}>
                    {!isEditing && (
                        <CompanyStands
                            stands={values.stands ? values.stands : undefined}
                            companyId={values.uuid}
                        />
                    )}
                </TabPanel>
                <TabPanel value={tabSelected} index={2}>
                    {!isEditing && <CompanyUsers companyId={values.uuid} />}
                </TabPanel>
                <TabPanel value={tabSelected} index={3}>
                    {!isEditing && (
                        <CompanySuppliers
                            suppliers={values.suppliers ?? undefined}
                            companyId={values.uuid}
                        />
                    )}
                </TabPanel>
            </ThemeProvider>
        </SectionCompany>
    )
}

export default CompanyFeatures

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`

export const StyledTitle = styled('span')<{ isRequired: boolean }>`
    font-weight: bold;
    margin-bottom: 8px;
    ${Typography['Body_Text_#1_Regular_Left']};
    font-size: 18px;
    color: #44566c;

    ${props =>
        props.isRequired
            ? `
   ::after {
     margin-left: 2px;
     content: '*';
     color: ${Colors['gold-pmauto']};
   }
   `
            : ''}
`

export const SectionCompany = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    width: 100%;
`

const TabsRowLabel = styled('span')`
    ${Typography.H2_Regular_Left}
    font-size: 18px;
    padding: 4px;
    text-transform: none;
`
