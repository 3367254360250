import {
    Company,
    Contact,
    Financial,
    LoggedUser,
    Stand,
    Vehicle,
    Supplier,
} from './shared.models'
import { AdminUtils } from '../admin/admin.utils'
import {
    HeaderEnum,
    HeaderEnumCompany,
    HeaderEnumStand,
    HeaderEnumUsers,
    HeaderEnumFinancial,
    HeaderContactsEnum,
    HeaderEnumSupplier,
} from './components/table/table-headers.component'
import { getCostsTotalValue, getVehicleTotalValue } from './shared.utils'

export enum SortOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC',
}

export const SortUtils = {
    sortVehicles: (
        vehicles: Vehicle[],
        header: string,
        order: SortOrderEnum
    ) => {
        let sortedVehicles: Vehicle[] = []
        switch (header) {
            case HeaderEnum.BRAND:
                sortedVehicles = SortUtils.sortByBrandAndModel(vehicles, order)
                break
            case HeaderEnum.STOCK:
                sortedVehicles = SortUtils.sortByDaysInStock(vehicles, order)
                break
            case HeaderEnum.DATE_VEHICLE:
                sortedVehicles = SortUtils.sortByLicensePlateDate(
                    vehicles,
                    order
                )
                break
            case HeaderEnum.SELLER_PRICE:
                sortedVehicles = SortUtils.sortBySellerPrice(vehicles, order)
                break
            case HeaderEnum.BUY_PRICE:
                sortedVehicles = SortUtils.sortByBuyPrice(vehicles, order)
                break
            case HeaderEnum.MILEAGE:
                sortedVehicles = SortUtils.sortByMileage(vehicles, order)
                break
            case HeaderEnum.DATE_OF_SALE:
                sortedVehicles = SortUtils.sortByDateOfSale(vehicles, order)
                break
            case HeaderEnum.SALE_PRICE:
                sortedVehicles = SortUtils.sortBySalePrice(vehicles, order)
                break
            case HeaderEnum.SALE_MARGIN:
                sortedVehicles = SortUtils.sortBySaleMargin(vehicles, order)
                break
            case HeaderEnum.LOAN_VALUE:
                sortedVehicles = SortUtils.sortByLoanValue(vehicles, order)
                break
            default:
                sortedVehicles = vehicles
        }
        return sortedVehicles
    },
    sortByBrandAndModel: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        let auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            return order === SortOrderEnum.ASC
                ? v1['brand'].localeCompare(v2['brand']) ||
                      v1['model'].localeCompare(v2['model'])
                : v2['brand'].localeCompare(v1['brand']) ||
                      v1['model'].localeCompare(v2['model'])
        })

        return auxVehicles
    },
    sortByDaysInStock: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        let auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            return order === SortOrderEnum.ASC
                ? AdminUtils.getDaysInStock(v1.createdAt) -
                      AdminUtils.getDaysInStock(v2.createdAt)
                : AdminUtils.getDaysInStock(v2.createdAt) -
                      AdminUtils.getDaysInStock(v1.createdAt)
        })
        return auxVehicles
    },
    sortByLicensePlateDate: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        // TODO: Needs to be changed when we refactor the data handling
        let auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            const v1AuxDate = AdminUtils.getVehicleMonthAndDate(v1)
            const v2AuxDate = AdminUtils.getVehicleMonthAndDate(v2)
            if (v1AuxDate === '-') {
                return 1
            }
            if (v2AuxDate === '-') {
                return -1
            } else {
                const v1DateArr = v1AuxDate.split('/').map(n => Number(n))
                const v2DateArr = v2AuxDate.split('/').map(n => Number(n))
                return order === SortOrderEnum.ASC
                    ? v1DateArr[1] - v2DateArr[1] || v1DateArr[0] - v2DateArr[0]
                    : v2DateArr[1] - v1DateArr[1] || v2DateArr[0] - v1DateArr[0]
            }
        })
        return auxVehicles
    },
    sortBySellerPrice: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            return order === SortOrderEnum.ASC
                ? v1.sellerPrice - v2.sellerPrice
                : v2.sellerPrice - v1.sellerPrice
        })

        return auxVehicles
    },
    sortByBuyPrice: (vehicles: Vehicle[], order: SortOrderEnum): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            return order === SortOrderEnum.ASC
                ? v1.buyPrice +
                      getCostsTotalValue(
                          v1.costs ? v1.costs : v1.totalCosts || 0
                      ) -
                      (v2.buyPrice +
                          getCostsTotalValue(
                              v2.costs ? v2.costs : v2.totalCosts || 0
                          ))
                : v2.buyPrice +
                      getCostsTotalValue(
                          v2.costs ? v2.costs : v2.totalCosts || 0
                      ) -
                      (v1.buyPrice +
                          getCostsTotalValue(
                              v1.costs ? v1.costs : v1.totalCosts || 0
                          ))
        })
        return auxVehicles
    },
    sortByMileage: (vehicles: Vehicle[], order: SortOrderEnum): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            if (!v1.mileage) return 1
            if (!v2.mileage) return -1
            return order === SortOrderEnum.ASC
                ? v1.mileage - v2.mileage
                : v2.mileage - v1.mileage
        })
        return auxVehicles
    },
    sortByDateOfSale: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            if (v1.saleDate && v2.saleDate) {
                const v1AuxDate = AdminUtils.getSaleMonthAndDate(v1)
                const v2AuxDate = AdminUtils.getSaleMonthAndDate(v2)
                if (v1AuxDate === '-') {
                    return 1
                }
                if (v2AuxDate === '-') {
                    return -1
                } else {
                    // TODO: Needs to be changed when we refactor the data handling
                    const v1DateArr = v1AuxDate.split('/').map(a => Number(a))
                    const v2DateArr = v2AuxDate.split('/').map(a => Number(a))
                    return order === SortOrderEnum.ASC
                        ? v1DateArr[1] - v2DateArr[1] ||
                              v1DateArr[0] - v2DateArr[0]
                        : v2DateArr[1] - v1DateArr[1] ||
                              v2DateArr[0] - v1DateArr[0]
                }
            }
            return -1
        })

        return auxVehicles
    },
    sortBySalePrice: (vehicles: Vehicle[], order: SortOrderEnum): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            if (v1.salePrice && v2.salePrice) {
                return order === SortOrderEnum.ASC
                    ? v1.salePrice - v2.salePrice
                    : v2.salePrice - v1.salePrice
            } else {
                return -1
            }
        })
        return auxVehicles
    },
    sortBySaleMargin: (
        vehicles: Vehicle[],
        order: SortOrderEnum
    ): Vehicle[] => {
        const auxVehicles = [...vehicles]
        auxVehicles.sort((v1, v2) => {
            if (v1.salePrice && v2.salePrice) {
                const v1Costs = getVehicleTotalValue(v1)
                const v2Costs = getVehicleTotalValue(v2)
                const v1Margin = v1.salePrice - v1Costs
                const v2Margin = v2.salePrice - v2Costs
                return order === SortOrderEnum.ASC
                    ? v1Margin - v2Margin
                    : v2Margin - v1Margin
            } else {
                return -1
            }
        })
        return auxVehicles
    },
    sortByLoanValue: (vehicles: Vehicle[], order: SortOrderEnum): Vehicle[] => {
        const auxVehicles = [...vehicles]
        const vehiclesWithLoanValue = auxVehicles.filter(v => v.loanValue)
        vehiclesWithLoanValue.sort((v1, v2) => {
            if (v1.loanValue && v2.loanValue) {
                return order === SortOrderEnum.ASC
                    ? v1.loanValue - v2.loanValue
                    : v2.loanValue - v1.loanValue
            }
            // It should never get here
            return -0
        })
        const vehiclesWithoutLoanValue = auxVehicles.filter(
            v => v.sale && !v.sale.loanValue
        )
        vehiclesWithLoanValue.push(...vehiclesWithoutLoanValue)
        return vehiclesWithLoanValue
    },
    sortCompanies: (
        companies: Company[],
        header: string,
        order: SortOrderEnum
    ) => {
        let sortedCompanies: Company[] = []
        switch (header) {
            case HeaderEnumCompany.NAME:
                sortedCompanies = SortUtils.sortByCompanyName(companies, order)
                break
            case HeaderEnumCompany.COLOR:
                sortedCompanies = SortUtils.sortByColor(companies, order)
                break
            case HeaderEnumCompany.NUMBER_OF_STANDS:
                sortedCompanies = SortUtils.sortByNumberStands(companies, order)
                break
            case HeaderEnumCompany.NUMBER_OF_CARS:
                sortedCompanies = SortUtils.sortByNumbersCars(companies, order)
                break
            default:
                sortedCompanies = companies
        }
        return sortedCompanies
    },
    sortStands: (stands: Stand[], header: string, order: SortOrderEnum) => {
        let sortedStands: Stand[] = []
        switch (header) {
            case HeaderEnumStand.NAME:
                sortedStands = SortUtils.sortByStandName(stands, order)
                break
            case HeaderEnumStand.LOCATION:
                sortedStands = SortUtils.sortByStandLocation(stands, order)
                break
            case HeaderEnumStand.ON_SALE:
                sortedStands = SortUtils.sortByStandOnSale(stands, order)
                break
            case HeaderEnumStand.SOLD:
                sortedStands = SortUtils.sortByStandSold(stands, order)
                break
            case HeaderEnumStand.OTHER:
                sortedStands = SortUtils.sortByStandOther(stands, order)
                break
            case HeaderEnumStand.ACTIVE:
                sortedStands = SortUtils.sortByStandActive(stands, order)
                break
            default:
                sortedStands = stands
        }
        return sortedStands
    },
    sortUsers: (users: LoggedUser[], header: string, order: SortOrderEnum) => {
        let sortedUsers: LoggedUser[] = []
        switch (header) {
            case HeaderEnumUsers.USERNAME:
                sortedUsers = SortUtils.sortByUsername(users, order)
                break
            case HeaderEnumUsers.FIRSTNAME:
                sortedUsers = SortUtils.sortByFirstname(users, order)
                break
            case HeaderEnumUsers.LASTNAME:
                sortedUsers = SortUtils.sortByLastname(users, order)
                break
            case HeaderEnumUsers.ROLE:
                sortedUsers = SortUtils.sortByRole(users, order)
                break
            case HeaderEnumUsers.ACTIVE:
                sortedUsers = SortUtils.sortByActive(users, order)
                break
            default:
                sortedUsers = users
        }
        return sortedUsers
    },
    sortByCompanyName: (
        companies: Company[],
        order: SortOrderEnum
    ): Company[] => {
        let auxCompanies = [...companies]
        auxCompanies.sort((v1, v2) => {
            return order === SortOrderEnum.ASC
                ? v1['name'].localeCompare(v2['name'])
                : v2['name'].localeCompare(v1['name'])
        })

        return auxCompanies
    },
    sortByStandName: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1['name'] && v2['name']) {
                return order === SortOrderEnum.ASC
                    ? v1['name'].localeCompare(v2['name'])
                    : v2['name'].localeCompare(v1['name'])
            } else {
                return -1
            }
        })

        return auxStands
    },
    sortByColor: (companies: Company[], order: SortOrderEnum): Company[] => {
        let auxCompanies = [...companies]
        auxCompanies.sort((v1, v2) => {
            if (v1['color'] && v2['color']) {
                return order === SortOrderEnum.ASC
                    ? v1['color'].localeCompare(v2['color'])
                    : v2['color'].localeCompare(v1['color'])
            } else {
                return -1
            }
        })

        return auxCompanies
    },
    sortByStandOnSale: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1.vehiclesLengthInSale && v2.vehiclesLengthInSale) {
                return order === SortOrderEnum.ASC
                    ? v1.vehiclesLengthInSale - v2.vehiclesLengthInSale
                    : v2.vehiclesLengthInSale - v1.vehiclesLengthInSale
            } else {
                return -1
            }
        })
        return auxStands
    },
    sortByStandSold: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1.vehiclesLengthSold && v2.vehiclesLengthSold) {
                return order === SortOrderEnum.ASC
                    ? v1.vehiclesLengthSold - v2.vehiclesLengthSold
                    : v2.vehiclesLengthSold - v1.vehiclesLengthSold
            } else {
                return -1
            }
        })
        return auxStands
    },
    sortByStandOther: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1.vehiclesLengthOthers && v2.vehiclesLengthOthers) {
                return order === SortOrderEnum.ASC
                    ? v1.vehiclesLengthOthers - v2.vehiclesLengthOthers
                    : v2.vehiclesLengthOthers - v1.vehiclesLengthOthers
            } else {
                return -1
            }
        })
        return auxStands
    },
    sortByNumberStands: (
        companies: Company[],
        order: SortOrderEnum
    ): Company[] => {
        const auxCompanies = [...companies]
        auxCompanies.sort((v1, v2) => {
            if (v1.stands && v2.stands) {
                return order === SortOrderEnum.ASC
                    ? v1.stands.length - v2.stands.length
                    : v2.stands.length - v1.stands.length
            } else {
                return -1
            }
        })
        return auxCompanies
    },
    sortByNumbersCars: (
        companies: Company[],
        order: SortOrderEnum
    ): Company[] => {
        const auxCompanies = [...companies]
        auxCompanies.sort((v1, v2) => {
            if (v1.stands && v2.stands) {
                const v1_total = v1.stands.map(c => c.vehicles).length
                const v2_total = v2.stands.map(c => c.vehicles).length
                return order === SortOrderEnum.ASC
                    ? v1_total - v2_total
                    : v2_total - v1_total
            } else {
                return -1
            }
        })
        return auxCompanies
    },
    sortByStandLocation: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1['location'] && v2['location']) {
                return order === SortOrderEnum.ASC
                    ? v1['location'].localeCompare(v2['location'])
                    : v2['location'].localeCompare(v1['location'])
            } else {
                return -1
            }
        })

        return auxStands
    },
    sortByStandActive: (stands: Stand[], order: SortOrderEnum): Stand[] => {
        let auxStands = [...stands]
        auxStands.sort((v1, v2) => {
            if (v1['active'] && v2['active']) {
                const v1_activeness = v1['active'] ? 'Ativo' : 'Inativo'
                const v2_activeness = v2['active'] ? 'Ativo' : 'Inativo'
                return order === SortOrderEnum.ASC
                    ? v1_activeness.localeCompare(v2_activeness)
                    : v2_activeness.localeCompare(v1_activeness)
            } else {
                return -1
            }
        })

        return auxStands
    },
    sortByActive: (users: LoggedUser[], order: SortOrderEnum): LoggedUser[] => {
        let auxUsers = [...users]
        auxUsers.sort((v1, v2) => {
            if (v1['isActive'] && v2['isActive']) {
                const v1_activeness = v1['isActive'] ? 'Ativo' : 'Inativo'
                const v2_activeness = v2['isActive'] ? 'Ativo' : 'Inativo'
                return order === SortOrderEnum.ASC
                    ? v1_activeness.localeCompare(v2_activeness)
                    : v2_activeness.localeCompare(v1_activeness)
            } else {
                return -1
            }
        })

        return auxUsers
    },
    sortByUsername: (
        users: LoggedUser[],
        order: SortOrderEnum
    ): LoggedUser[] => {
        let auxUsers = [...users]
        auxUsers.sort((v1, v2) => {
            if (v1['username'] && v2['username']) {
                return order === SortOrderEnum.ASC
                    ? v1['username'].localeCompare(v2['username'])
                    : v2['username'].localeCompare(v1['username'])
            } else {
                return -1
            }
        })

        return auxUsers
    },
    sortByFirstname: (
        users: LoggedUser[],
        order: SortOrderEnum
    ): LoggedUser[] => {
        let auxUsers = [...users]
        auxUsers.sort((v1, v2) => {
            if (v1['firstName'] && v2['firstName']) {
                return order === SortOrderEnum.ASC
                    ? v1['firstName'].localeCompare(v2['firstName'])
                    : v2['firstName'].localeCompare(v1['firstName'])
            } else {
                return -1
            }
        })

        return auxUsers
    },
    sortByLastname: (
        users: LoggedUser[],
        order: SortOrderEnum
    ): LoggedUser[] => {
        let auxUsers = [...users]
        auxUsers.sort((v1, v2) => {
            if (v1['lastName'] && v2['lastName']) {
                return order === SortOrderEnum.ASC
                    ? v1['lastName'].localeCompare(v2['lastName'])
                    : v2['lastName'].localeCompare(v1['lastName'])
            } else {
                return -1
            }
        })

        return auxUsers
    },
    sortByRole: (users: LoggedUser[], order: SortOrderEnum): LoggedUser[] => {
        let auxUsers = [...users]
        auxUsers.sort((v1, v2) => {
            if (v1['role'] && v2['role']) {
                return order === SortOrderEnum.ASC
                    ? v1['role'].localeCompare(v2['role'])
                    : v2['role'].localeCompare(v1['role'])
            } else {
                return -1
            }
        })

        return auxUsers
    },
    sortFinancials: (
        financials: Financial[],
        header: string,
        order: SortOrderEnum
    ) => {
        let sortedFinancials: Financial[] = []
        switch (header) {
            case HeaderEnumFinancial.NAME:
                sortedFinancials = SortUtils.sortByFinancialName(
                    financials,
                    order
                )
                break
            case HeaderEnumFinancial.CODE:
                sortedFinancials = SortUtils.sortByFinancialCode(
                    financials,
                    order
                )
                break
        }

        return sortedFinancials
    },
    sortByFinancialName: (
        financials: Financial[],
        order: SortOrderEnum
    ): Financial[] => {
        let auxFinancial = [...financials]
        auxFinancial.sort((v1, v2) => {
            if (v1['name'] && v2['name']) {
                return order === SortOrderEnum.ASC
                    ? v1['name'].localeCompare(v2['name'])
                    : v2['name'].localeCompare(v1['name'])
            } else {
                return -1
            }
        })

        return auxFinancial
    },
    sortByFinancialCode: (
        financials: Financial[],
        order: SortOrderEnum
    ): Financial[] => {
        let auxFinancial = [...financials]
        auxFinancial.sort((v1, v2) => {
            if (v1['code'] && v2['code']) {
                return order === SortOrderEnum.ASC
                    ? v1['code'].localeCompare(v2['code'])
                    : v2['code'].localeCompare(v1['code'])
            } else {
                return -1
            }
        })

        return auxFinancial
    },
    sortByContactName: (
        contacts: Contact[],
        order: SortOrderEnum
    ): Contact[] => {
        let auxContacts = [...contacts]
        auxContacts.sort((v1, v2) => {
            if (v1['name'] && v2['name']) {
                return order === SortOrderEnum.ASC
                    ? v1['name'].localeCompare(v2['name'])
                    : v2['name'].localeCompare(v1['name'])
            } else {
                return -1
            }
        })

        return auxContacts
    },
    sortByContactDate: (
        contacts: Contact[],
        order: SortOrderEnum
    ): Contact[] => {
        const auxContacts = [...contacts]
        auxContacts.sort((v1, v2) => {
            if (v1.date && v2.date) {
                const v1AuxDate = AdminUtils.getContactMonthAndDate(v1)
                const v2AuxDate = AdminUtils.getContactMonthAndDate(v2)
                if (v1AuxDate === '-') {
                    return 1
                }
                if (v2AuxDate === '-') {
                    return -1
                } else {
                    // TODO: Needs to be changed when we refactor the data handling
                    const v1DateArr = v1AuxDate.split('/').map(a => Number(a))
                    const v2DateArr = v2AuxDate.split('/').map(a => Number(a))
                    return order === SortOrderEnum.ASC
                        ? v1DateArr[1] - v2DateArr[1] ||
                              v1DateArr[0] - v2DateArr[0]
                        : v2DateArr[1] - v1DateArr[1] ||
                              v2DateArr[0] - v1DateArr[0]
                }
            }
            return -1
        })

        return auxContacts
    },
    sortByContactStatus: (
        contacts: Contact[],
        order: SortOrderEnum
    ): Contact[] => {
        let auxContacts = [...contacts]
        auxContacts.sort((v1, v2) => {
            if (v1['status'] && v2['status']) {
                return order === SortOrderEnum.ASC
                    ? v1['status'].localeCompare(v2['status'])
                    : v2['status'].localeCompare(v1['status'])
            } else {
                return -1
            }
        })

        return auxContacts
    },
    sortContactSellerByUsername: (
        contacts: Contact[],
        order: SortOrderEnum
    ) => {
        let auxContacts = [...contacts]
        auxContacts.sort((v1, v2) => {
            if (v1['contactUserName'] && v2['contactUserName']) {
                return order === SortOrderEnum.ASC
                    ? v1['contactUserName'].localeCompare(v2['contactUserName'])
                    : v2['contactUserName'].localeCompare(v1['contactUserName'])
            } else {
                return -1
            }
        })

        return auxContacts
    },
    sortContacts: (
        contacts: Contact[],
        header: string,
        order: SortOrderEnum
    ) => {
        let sortedContacts: Contact[] = []
        switch (header) {
            case HeaderContactsEnum.NAME:
                sortedContacts = SortUtils.sortByContactName(contacts, order)
                break
            case HeaderContactsEnum.DATE:
                sortedContacts = SortUtils.sortByContactDate(contacts, order)
                break
            case HeaderContactsEnum.SELLER:
                sortedContacts = SortUtils.sortContactSellerByUsername(
                    contacts,
                    order
                )
                break
            // case HeaderContactsEnum.LAST_ITERACTION:
            //     sortedContacts = SortUtils.sortByContactLastIteraction(
            //         contacts,
            //         order
            //     )
            //     break
            // case HeaderContactsEnum.PHONE:
            //     sortedContacts = SortUtils.sortByContactPhone(contacts, order)
            //     break
            // case HeaderContactsEnum.EMAIL:
            //     sortedContacts = SortUtils.sortByContactEmail(contacts, order)
            //     break
            case HeaderContactsEnum.STATUS:
                sortedContacts = SortUtils.sortByContactStatus(contacts, order)
                break
            default:
                sortedContacts = contacts
        }
        return sortedContacts
    },
    sortBySupplierName: (
        suppliers: Supplier[],
        order: SortOrderEnum
    ): Supplier[] => {
        let auxSuppliers = [...suppliers]
        auxSuppliers.sort((v1, v2) => {
            if (v1['name'] && v2['name']) {
                return order === SortOrderEnum.ASC
                    ? v1['name'].localeCompare(v2['name'])
                    : v2['name'].localeCompare(v1['name'])
            } else {
                return -1
            }
        })

        return auxSuppliers
    },
    sortByNumberVehicles: (
        suppliers: Supplier[],
        order: SortOrderEnum
    ): Supplier[] => {
        let auxSuppliers = [...suppliers]
        auxSuppliers.sort((v1, v2) => {
            const v1_total = v1.numberVehicles as number
            const v2_total = v2.numberVehicles as number
            return order === SortOrderEnum.ASC
                ? v1_total - v2_total
                : v2_total - v1_total
        })

        return auxSuppliers
    },
    sortSuppliers: (
        suppliers: Supplier[],
        header: string,
        order: SortOrderEnum
    ) => {
        let sortedSuppliers: Supplier[] = []
        switch (header) {
            case HeaderEnumSupplier.NAME:
                sortedSuppliers = SortUtils.sortBySupplierName(suppliers, order)
                break
            case HeaderEnumSupplier.NUMBER_VEHICLES:
                sortedSuppliers = SortUtils.sortByNumberVehicles(
                    suppliers,
                    order
                )
                break

            default:
                sortedSuppliers = suppliers
        }
        return sortedSuppliers
    },
}
