import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AdminApi } from '../admin.api'
import { IStoreInterface } from '../../configs/store.config'
import {
    AnalyticValues,
    LoggedUser,
    AnalyticCharts,
} from '../../shared/shared.models'
import { Column, PageTitle } from '../../shared/shared.styled'
import { createSelector } from 'reselect'
import { useAlert } from 'react-alert'
import { AdminActions } from '../admin.store'
import { Analytics } from '../components/analysis-list.component'
import Loader from 'react-spinners/DotLoader'
import { Typography, Colors } from '../../configs/styled.config'
import styled from 'styled-components'
import moment from 'moment'

const userSelector = createSelector(
    (state: IStoreInterface) => state.adminReducer.user,
    user => user as LoggedUser
)

export const AnalyticsPage = () => {
    const companyUsers = useSelector(
        (state: IStoreInterface) => state.adminReducer.users
    )
    // Hooks initialization

    const alert = useAlert()

    // Redux state
    const loggedUser = useSelector(userSelector)

    const dispatch = useDispatch()
    const today = moment().format('YYYY-MM-DD')

    // Local state
    const [analytics, setAnalytics] = React.useState<
        AnalyticValues | undefined
    >(undefined)
    const [contactsChartData, setContactsChartData] = React.useState<
        AnalyticCharts[]
    >()
    const [filterUser, setFilterUser] = React.useState<string>('')
    const [filterInitialDate, setFilterInitialDate] = React.useState<string>(
        today
    )
    const [filterEndDate, setFilterEndDate] = React.useState<string>(today)
    const [filterGroupBy, setFilterGroupBy] = React.useState<string>('')
    const [isLoading, setIsLoading] = React.useState(true)

    // Effect to fetch the list of vehicles
    React.useEffect(() => {
        AdminApi.methods
            .getAnalysis(loggedUser.companyUuid)
            .finally(() => setIsLoading(false))
            .then(
                res => {
                    setAnalytics(res.data.analysis)
                    dispatch(
                        AdminActions.methods.getAnalysisSuccessAction(
                            res.data.analysis
                        )
                    )
                },
                e => {
                    alert.error('Error fetching the analysis')
                }
            )
    }, [alert, dispatch, loggedUser.companyUuid])

    React.useEffect(() => {
        if (
            filterEndDate?.length > 0 ||
            filterInitialDate?.length > 0 ||
            filterUser?.length > 0
        ) {
            AdminApi.methods
                .getContactChartData(
                    loggedUser.companyUuid,
                    filterInitialDate,
                    filterEndDate,
                    filterUser,
                    filterGroupBy
                )
                .finally(() => setIsLoading(false))
                .then(
                    res => {
                        setContactsChartData(res?.data?.results)
                        dispatch(
                            AdminActions.methods.getContactChartDataSuccessAction(
                                res.data.results
                            )
                        )
                    },
                    e => {
                        alert.error('Error fetching contact chart data')
                    }
                )
        }
    }, [
        alert,
        dispatch,
        filterEndDate,
        filterInitialDate,
        filterUser,
        filterGroupBy,
        loggedUser.companyUuid,
    ])

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>
                    A processar os cálculos necessários...
                </LoadingText>
            </Column>
        )
    }

    return (
        <>
            <PageTitle>Análise e Gestão de Dados</PageTitle>
            <Analytics
                analytics={analytics}
                contactsChartData={contactsChartData}
                filterUser={filterUser}
                setFilterUser={setFilterUser}
                filterInitialDate={filterInitialDate}
                setFilterInitialDate={setFilterInitialDate}
                filterEndDate={filterEndDate}
                setFilterEndDate={setFilterEndDate}
                filterGroupBy={filterGroupBy}
                setFilterGroupBy={setFilterGroupBy}
                companyUsers={companyUsers}
            />
        </>
    )
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
