import React from 'react'
import { LoggedUser } from '../../shared/shared.models'
import { useRoutes } from 'hookrouter'
import { UserDetailsPage } from './user-details.page'
import { useDispatch } from 'react-redux'
// import { IStoreInterface } from "../../configs/store.config";
// import { createSelector } from "reselect";
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'

export interface IBaseInterface {
    user: LoggedUser
}

const routes = {
    '/': () => (user: LoggedUser) => <UserDetailsPage user={user} />,
}

interface UserPageProps {
    userId: string
}

/**
 * Function to get the stand from the redux store
 */
// const standSelector = () =>
//   createSelector(
//     (state: IStoreInterface) => state.adminReducer.stands,
//     (state: IStoreInterface, standId: string | undefined) => standId,
//     (stands, standId) => stands.find((s) => s.uuid === standId)
//   );

/**
 * This page handles the routing of the UserPage
 * @param userId {string}
 */
export const UserPage = ({ userId }: UserPageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()

    const [user, setUser] = React.useState<LoggedUser>()
    const [isLoading, setIsLoading] = React.useState(true)

    /**
     * Calls the endpoint to fetch the stand information
     */
    const fetchUserData = React.useCallback(async () => {
        try {
            const res = await AdminApi.methods.getUser(userId)
            dispatch(
                AdminActions.methods.updateUserSuccessAction(res.data.user)
            )
            setUser(res.data.user)
        } catch (e) {
            alert.error(
                'Houve um erro a ir buscar a informação deste utilizador. Por favor tente mais tarde'
            )
        } finally {
            setIsLoading(false)
        }
    }, [userId, dispatch, alert])

    React.useEffect(() => {
        fetchUserData()
    }, [fetchUserData])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação do utilizador</LoadingText>
            </Column>
        )
    }

    if (!user) {
        throw new Error('Este user não existe')
    } else {
        return match(user)
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
