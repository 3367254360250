import React from 'react'
import PrimaryButton from '../../shared/components/button.component'
import { SaleRequest } from '../sales.interfaces'
import { useFormikContext } from 'formik'
import { useModal } from 'react-modal-hook'
import { SaleResumeModal } from '../components/sale-resume.modal'
import FixedPageTitle from '../../admin/components/fixed-page-title.component'
import { ProcessSection } from '../pages/create-sale/sections/process.section'
import { FinanceSection } from '../pages/create-sale/sections/finance.section'
import { ClientSection } from '../pages/create-sale/sections/client.section'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { Vehicle, Stand, Company } from '../../shared/shared.models'
import { RepairsSection } from '../pages/create-sale/sections/repairs.section'
import { DocumentsSection } from '../pages/create-sale/sections/documents.section'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

interface CreateSaleFormProps {
    vehicle: Vehicle
    submitValues: (values: SaleRequest) => void
    isLoading: boolean
    stands: Stand[]
}

export const CreateSaleForm = ({
    vehicle,
    submitValues,
    isLoading,
    stands,
}: CreateSaleFormProps) => {
    const { values, validateForm } = useFormikContext<SaleRequest>()
    const [showModal, hideModal] = useModal(
        () => (
            <SaleResumeModal
                isLoading={isLoading}
                vehicle={vehicle}
                values={values}
                onCancel={hideModal}
                onSubmit={submitValues}
                stands={stands}
            />
        ),
        [values]
    )

    const [showConfirmationModal, hideConfirmationModal] = useModal(() => (
        <ConfirmationModal
            onClick={() => window.history.back()}
            onCancel={hideConfirmationModal}
        />
    ))

    const showResumeModal = () => {
        validateForm().then(res => {
            if (Object.keys(res).length === 0) {
                showModal()
            }
        })
    }

    const showCancelConfirmationModal = () => {
        showConfirmationModal()
    }

    const renderButtons = () => (
        <>
            <PrimaryButton
                label="Cancelar"
                type="button"
                onClick={() => showCancelConfirmationModal()}
            />
            <PrimaryButton
                redTheme={true}
                label="Confirmar venda"
                type="submit"
                onClick={() => showResumeModal()}
            />
        </>
    )

    return (
        <>
            <FixedPageTitle
                label="Venda de veículo"
                rightButtons={renderButtons()}
            />
            <FormContent vehicle={vehicle} isEditing={true} />
        </>
    )
}

export const FormContent = ({
    vehicle,
    isEditing,
}: {
    company?: Company
    vehicle: Vehicle
    isEditing: boolean
}) => {
    const company = useSelector(
        (state: IStoreInterface) => state.adminReducer.company
    )
    return (
        <>
            <ProcessSection isEditing={isEditing} />
            <FinanceSection isEditing={isEditing} vehicle={vehicle} />
            <ClientSection isEditing={isEditing} />
            <RepairsSection isEditing={isEditing} />
            {company?.uuid === 'f09e34fe-d5e8-427e-a1ca-4965387cd60c' &&
                !isEditing && <DocumentsSection vehicle={vehicle} />}
        </>
    )
}
