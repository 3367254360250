import React from 'react'
import styled from 'styled-components'
import { TableHeaders } from './table-headers.component'
import { TableRowManage } from './table-row-manage.component'
import { SortOrderEnum } from '../../sort.utils'
import DotLoader from 'react-spinners/DotLoader'
import { Colors, Typography } from '../../../configs/styled.config'
import { VisibilityStatusEnum } from '../../shared.enums'
import { RoutesEnum } from '../../../routes.constants'
import { navigate } from 'hookrouter'
import { useIsAdmin } from '../../hooks/use-is-admin.hook'

export interface ColumnManageProps {
    label: string
    key: string
    ratio: number
    visibility?: VisibilityStatusEnum
    renderFunction?: (value: any) => React.ReactNode
    onClick?: (header: string, order: SortOrderEnum) => void
    alignment?: 'flex-start' | 'center' | 'flex-end'
}

export interface TableManageValue extends Object {
    [key: string]: any
}

interface TableManageProps<T> {
    companyId: string
    columns: ColumnManageProps[]
    values: T[]
    rowStyles?: (value: T) => React.CSSProperties
    onRowClick?: (value: T) => void
    isLoadingData?: boolean
    type: string
}

// Functions
const navigateToCreateStand = (companyId: string) => {
    if (companyId)
        navigate(RoutesEnum.CREATE_STAND.replace(':companyId', companyId))
    else console.log('error')
}

const navigateToCreateUser = (companyId: string) => {
    if (companyId)
        navigate(RoutesEnum.CREATE_USER.replace(':companyId', companyId))
    else console.log('error')
}

const navigateToCreateSupplier = (companyId: string) => {
    console.log('here to navigate', companyId)
    if (companyId)
        navigate(RoutesEnum.CREATE_SUPPLIER.replace(':companyId', companyId))
    else console.log('error')
}

export const TableManage = <T extends TableManageValue>({
    companyId,
    columns,
    rowStyles,
    onRowClick,
    values,
    isLoadingData = false,
    type,
}: TableManageProps<T>) => {
    const isAdmin = useIsAdmin()
    return (
        <Container>
            <TableHeaders columns={columns} />
            {isLoadingData ? (
                <LoaderContainer>
                    <DotLoader color={Colors['gold-pmauto']} size={100} />
                </LoaderContainer>
            ) : values.length === 0 ? (
                <ContainerRows>
                    <StyledNoCompanies>
                        Não existem {type} associados a esta empresa
                    </StyledNoCompanies>
                    <StyledClickCreate
                        onClick={() => {
                            if (type === 'utilizadores')
                                navigateToCreateUser(companyId)
                            else if (type === 'stands')
                                navigateToCreateStand(companyId)
                            else if (type === 'fornecedores')
                                navigateToCreateSupplier(companyId)
                        }}
                    >
                        {' '}
                        Criar {type}{' '}
                    </StyledClickCreate>
                </ContainerRows>
            ) : (
                <>
                    <ContainerRows>
                        {values.map((entity, idx) => (
                            <TableRowManage
                                rowStyles={entity =>
                                    'isActive' in entity || 'active' in entity
                                        ? entity['isActive']
                                            ? { backgroundColor: 'white' }
                                            : entity['active']
                                            ? { backgroundColor: 'white' }
                                            : {
                                                  backgroundColor: '#edc0c0',
                                                  disabled: 'disabled',
                                              }
                                        : { backgroundColor: 'white' }
                                }
                                key={`row-${idx}`}
                                onClick={onRowClick}
                                columns={columns}
                                entity={entity}
                                adminAlso={
                                    type === 'fornecedores' && isAdmin
                                        ? true
                                        : false
                                }
                            />
                        ))}
                    </ContainerRows>
                    <StyledClickCreate
                        onClick={() => {
                            if (type === 'utilizadores')
                                navigateToCreateUser(companyId)
                            else if (type === 'stands')
                                navigateToCreateStand(companyId)
                            else if (type === 'fornecedores')
                                navigateToCreateSupplier(companyId)
                        }}
                    >
                        {' '}
                        Criar {type}{' '}
                    </StyledClickCreate>
                </>
            )}
        </Container>
    )
}

const Container = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`

const ContainerRows = styled('div')`
    margin-bottom: 32px;
    max-height: 400px;
    overflow: auto;
`

const LoaderContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 200px;
    max-height: 200px;
`

const StyledNoCompanies = styled('div')`
    ${Typography.H1_Regular_Center};
    font-size: 20px;
`

const StyledClickCreate = styled('button')`
    ${Typography.H1_Regular_Center};
    padding: 8px 16px;
    border: solid 1px #afafaf;
    border-radius: 18px;
    outline: 0;
    margin-top: 16px;
    font-size: 20px;
    cursor: pointer;
    background-color: white;
    width: 100%;

    :hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
`
