import React from 'react'
import { Stand } from '../../shared/shared.models'
import { useRoutes } from 'hookrouter'
import { StandDetailsPage } from './stand-details.page'
import { useDispatch } from 'react-redux'
// import { IStoreInterface } from "../../configs/store.config";
// import { createSelector } from "reselect";
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'

export interface IBaseInterface {
    stand: Stand
}

const routes = {
    '/': () => (stand: Stand) => <StandDetailsPage stand={stand} />,
}

interface StandPageProps {
    standId: string
}

/**
 * Function to get the stand from the redux store
 */
// const standSelector = () =>
//   createSelector(
//     (state: IStoreInterface) => state.adminReducer.stands,
//     (state: IStoreInterface, standId: string | undefined) => standId,
//     (stands, standId) => stands.find((s) => s.uuid === standId)
//   );

/**
 * This page handles the routing of the StandPage
 * @param standId {string}
 */
export const StandPage = ({ standId }: StandPageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()

    /**
     * Memoize the selector. This means that we only change this selector in case the standId changes
     */
    // const memoedSelector = React.useMemo(standSelector, [standId]);
    /**
     * Gets the stand from the redux store
     */
    // const stand = cloneDeep(
    //   useSelector((state: IStoreInterface) => memoedSelector(state, standId))
    // );
    const [stand, setStand] = React.useState<Stand>()
    const [isLoading, setIsLoading] = React.useState(true)

    /**
     * Calls the endpoint to fetch the stand information
     */
    const fetchStandData = React.useCallback(async () => {
        try {
            const res = await AdminApi.methods.getStand(standId)
            dispatch(
                AdminActions.methods.updateStandSuccessAction(res.data.stand)
            )
            setStand(res.data.stand)
        } catch (e) {
            alert.error(
                'Houve um erro a ir buscar a informação deste stand. Por favor tente mais tarde'
            )
        } finally {
            setIsLoading(false)
        }
    }, [standId, dispatch, alert])

    React.useEffect(() => {
        fetchStandData()
    }, [fetchStandData])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação do stand</LoadingText>
            </Column>
        )
    }

    if (!stand) {
        throw new Error('Este stand não existe')
    } else {
        return match(stand)
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
