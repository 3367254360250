import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { CircleLoader } from 'react-spinners'
import { Row } from '../../shared/shared.styled'
import { Typography } from '../../configs/styled.config'

interface PdfGeneratorButtonProps extends HTMLAttributes<HTMLButtonElement> {
    icon: string
    action: () => Promise<Blob>
    isLoading?: boolean
    disabled?: boolean
    title: string
}

const PdfGeneratorButton = ({
    icon,
    action,
    isLoading = false,
    disabled = false,
    title,
    ...rest
}: PdfGeneratorButtonProps) => {
    return (
        <StyledButton
            onClick={action}
            disabled={disabled}
            type="button"
            {...rest}
        >
            <CustomRow>
                <Row>
                    {isLoading ? (
                        <LoadingContainer>
                            <CircleLoader color="white" size={16} />
                        </LoadingContainer>
                    ) : (
                        <img
                            style={{ width: 20, height: 20 }}
                            src={icon}
                            alt="Icon alt"
                        />
                    )}
                    {title && <RowLabel>{title}</RowLabel>}
                </Row>
            </CustomRow>
        </StyledButton>
    )
}

export default PdfGeneratorButton

const CustomRow = styled(Row as any)`
    ${Row} {
        gap: 8px;
    }
`

export const StyledButton = styled('button')`
    background-color: white;
    padding: 4px 4px;
    border-radius: 4px;
    outline: 0;
    height: 36px;
    cursor: pointer;

    :hover {
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    }
`

export const LoadingContainer = styled('div')`
    margin: auto;
    width: fit-content;
`

const RowLabel = styled('span')`
    ${Typography.H3_Regular_Left}
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`
