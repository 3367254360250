import React from 'react'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    SectionSeparator,
} from '../../shared/shared.styled'
import styled from 'styled-components'
import { Vehicle } from '../../shared/shared.models'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import {
    ColumnProps,
    Table,
} from '../../shared/components/table/table.component'
import { HeaderEnum } from './list-headers-superadmin.component'
import {
    getBrandAndModelComponent,
    getFormatedComponentFromDays,
} from '../../shared/shared.utils'
import { AdminUtils } from '../admin.utils'
import TranslationUtils from '../../shared/utils/translations.utils'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'

interface ListVehiclesInStandProps {
    vehicles: Vehicle[]
}

export enum VehicleFiltersSuperAdmin {
    BRAND_OR_MODEL = 'BRAND_OR_MODEL',
    LICENSE_PLATE = 'LICENSE_PLATE',
}

const editVehicle = (vehicle: Vehicle) => {
    navigate(RoutesEnum.VEHICLE_PAGE.replace(':vehicleId', vehicle.uuid))
}

const ListVehiclesInStand = ({ vehicles }: ListVehiclesInStandProps) => {
    // Hooks initialization
    const dispatch = useDispatch()

    const [visibleVehicles, setVisibleVehicles] = React.useState<Vehicle[]>([])
    // Redux State
    dispatch(AdminActions.methods.getVehiclesSuccessAction(vehicles))

    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedVehicles = SortUtils.sortVehicles(
            visibleVehicles,
            header,
            order
        )
        setVisibleVehicles(sortedVehicles)
    }

    React.useEffect(() => {
        setVisibleVehicles(vehicles)
    }, [vehicles])

    // const { filters, updateFilter, clearAllFilters, clearFilter } = useFilter();

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: HeaderEnum.BRAND_AND_MODEL,
                ratio: 2 / 10,
                label: 'Marca / Modelo / Versão',
                renderFunction: (vehicle: Vehicle) =>
                    getBrandAndModelComponent(vehicle),
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnum.LICENSE_PLATE,
                label: 'Matrícula',
                ratio: 2 / 10,
                renderFunction: (vehicle: Vehicle) => vehicle.licensePlate,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnum.STOCK,
                label: 'Tempo em stock',
                ratio: 2 / 10,
                renderFunction: (vehicle: Vehicle) => {
                    const daysInStock = AdminUtils.getDaysInStock(
                        vehicle.createdAt
                    )
                    return getFormatedComponentFromDays(daysInStock)
                },
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnum.STATE,
                label: 'Estado do Veículo',
                ratio: 3 / 10,
                renderFunction: (vehicle: Vehicle) => {
                    const availability = TranslationUtils.getAvailabilityTranslation(
                        vehicle.availability
                    )
                    return availability
                },
                alignment: 'flex-end',
                onClick: onHeaderClick,
            },
        ]
    }

    return (
        <Section>
            <Row>
                <SectionTitle>Veículos</SectionTitle>
                {/* <WrapRow>
          <RatioContainer ratio={1.25 / 5}>
            <SearchBar
              handleChange={(value) =>
                updateFilter(VehicleFiltersSuperAdmin.BRAND_OR_MODEL, value)
              }
              value={filters[VehicleFiltersSuperAdmin.BRAND_OR_MODEL]}
              placeholder="Marca ou Modelo"
            />
          </RatioContainer>
          <RatioContainer ratio={1.25 / 5}>
            <SearchBar
              handleChange={(value) =>
                updateFilter(VehicleFiltersSuperAdmin.LICENSE_PLATE, value)
              }
              value={filters[VehicleFiltersSuperAdmin.LICENSE_PLATE]}
              placeholder="Matrícula"
            />
          </RatioContainer>
        </WrapRow> */}
            </Row>
            <SectionSeparator />
            <VehiclesCompo>
                <Table
                    isLoadingData={false}
                    values={visibleVehicles}
                    onRowClick={editVehicle}
                    columns={getColumns()}
                    standExistOnly={true}
                    vehiclesExists={false}
                />
            </VehiclesCompo>
        </Section>
    )
}

export default ListVehiclesInStand

const VehiclesCompo = styled(Row as any)`
    margin-top: 8px;
    > ${Column} {
        width: 100% !important;
    }
`
