import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

export const useHasStandsOnly = () => {
    const standExistOnly = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.stands.length > 0 &&
            state.adminReducer.stands.some(stand => stand.active === true)
    )
    return standExistOnly
}
