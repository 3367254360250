import React from 'react'
import {
    Section,
    SectionTitle,
    Row,
    Column,
    SectionSeparator,
    SmallSectionTitle,
} from '../../shared/shared.styled'
import UploadPhotos from './upload-photos.component'
import UploadVideo from './upload-video.component'
import styled from 'styled-components'
import ImageGallery from './image-gallery.component'
import VideoGallery from './video-gallery.components'
import { AdminApi } from '../admin.api'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { CreateVehicleRequest } from '../admin.interfaces'
import { useFormikContext } from 'formik'

interface MediaSectionProps {
    vehicleId: string
    isEditing?: boolean
}

export const MediaSection = ({
    vehicleId,
    isEditing = true,
}: MediaSectionProps) => {
    // Hooks
    const dispatch = useDispatch()
    const { setFieldValue } = useFormikContext<CreateVehicleRequest>()

    // Local state
    const [isUploading, setIsUploading] = React.useState(false)

    function uploadFiles(files: Array<File>) {
        // This will return all the photos of a specific vehicle
        return new Promise<void>(async (resolve, reject) => {
            setIsUploading(true)
            try {
                const res = await AdminApi.methods.uploadFiles(vehicleId, files)
                // Update the photos in the redux store
                // This will trigger a re-render on our form, since our form is connected to the redux store
                setFieldValue('photos', res.data.photos)
                dispatch(
                    AdminActions.methods.updateVehiclePhotosSuccessAction(
                        vehicleId,
                        res.data.photos
                    )
                )
                resolve()
            } catch (e) {
                reject()
            } finally {
                setIsUploading(false)
            }
        })
    }

    function uploadFile(file: File) {
        // This will return the video of a specific vehicle
        return new Promise<void>(async (resolve, reject) => {
            setIsUploading(true)
            try {
                const res = await AdminApi.methods.uploadFile(vehicleId, file)
                // Update the photos in the redux store
                // This will trigger a re-render on our form, since our form is connected to the redux store
                setFieldValue('video', res.data.video)
                dispatch(
                    AdminActions.methods.updateVehicleVideoSuccessAction(
                        vehicleId,
                        res.data.video
                    )
                )
                resolve()
            } catch (e) {
                reject()
            } finally {
                setIsUploading(false)
            }
        })
    }

    return (
        <Section>
            <SectionTitle>Media</SectionTitle>
            <SectionSeparator />
            <ResponsiveRowColumn style={{ justifyContent: 'space-between' }}>
                {isEditing ? (
                    <Column style={{ flex: 0.4, alignSelf: 'flex-start' }}>
                        <SmallSectionTitle style={{ marginBottom: 36 }}>
                            Upload de ficheiros
                        </SmallSectionTitle>
                        <Description>
                            Nesta secção podes gerir todo o tipo de media
                            relativo a este veículo.{' '}
                            <p>
                                Para fazer upload de novas imagens, basta
                                carregar no botão de editar, e depois carregar
                                no botão <span>+</span> ou então arrastar as
                                imagens para a área de transferência.
                            </p>
                            <p>
                                Só é possivel selecionar a foto principal depois
                                de guardar a informação do veículo
                            </p>
                        </Description>
                        {isEditing ? (
                            <UploadPhotos
                                isUploading={isUploading}
                                handleUpload={uploadFiles}
                            />
                        ) : null}
                    </Column>
                ) : null}
                <Column
                    style={{
                        alignSelf: 'flex-start',
                        flex: isEditing ? 0.5 : 1,
                        overflow: 'auto',
                    }}
                >
                    <ImageGallery inEdition={isEditing} />
                </Column>
            </ResponsiveRowColumn>

            <SectionTitle>Vídeo</SectionTitle>
            <SectionSeparator />
            <ResponsiveRowColumn style={{ justifyContent: 'space-between' }}>
                {isEditing ? (
                    <Column style={{ flex: 0.4, alignSelf: 'flex-start' }}>
                        <SmallSectionTitle style={{ marginBottom: 36 }}>
                            Upload de vídeo
                        </SmallSectionTitle>
                        <Description>
                            Nesta secção podes gerir o vídeo relativo a este
                            veículo.{' '}
                            <p>
                                Para fazer upload do vídeo, basta carregar no
                                botão de editar, e depois carregar no botão{' '}
                                <span>+</span> ou então arrastar o vídeo para a
                                área de transferência.
                            </p>
                        </Description>
                        {isEditing ? (
                            <UploadVideo
                                isUploading={isUploading}
                                handleUpload={uploadFile}
                            />
                        ) : null}
                    </Column>
                ) : null}
                <Column
                    style={{
                        alignSelf: 'flex-start',
                        flex: isEditing ? 0.5 : 1,
                    }}
                >
                    <VideoGallery inEdition={isEditing} />
                </Column>
            </ResponsiveRowColumn>
        </Section>
    )
}

const Description = styled('span')`
    font-family: Merriweather-Light;
    font-size: 16px;

    > span {
        font-weight: bold;
    }
`

const ResponsiveRowColumn = styled(Row as any)`
    @media screen and (max-width: 767px) {
        flex-direction: column-reverse;
    }
`
