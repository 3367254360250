import React, { InputHTMLAttributes } from 'react'
import { Row } from '../shared.styled'
import { uuidv4 } from '../shared.utils'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'
import CheckBox from '@material-ui/core/Checkbox'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string
    containerStyle?: React.CSSProperties
}

const Checkbox = ({ label, containerStyle, ...rest }: CheckboxProps) => {
    const uniqueId = uuidv4()
    return (
        <CheckboxContainer isEditing={!rest.disabled} style={containerStyle}>
            <CheckBox
                id={uniqueId}
                disableRipple
                style={{
                    color: rest.disabled ? '#d8d8d8' : '#333',
                }}
                disabled={rest.disabled}
                checked={rest.checked}
                onChange={rest.onChange}
                value={rest.value}
            />
            <StyledLabel htmlFor={uniqueId}>{label}</StyledLabel>
        </CheckboxContainer>
    )
}

export default Checkbox

const StyledLabel = styled('label')`
    margin-left: 4px;
    ${Typography['Body_Text_#1_Regular_Left']}
    font-size: 16px;
`

const CheckboxContainer = styled(Row as any)<{ isEditing: boolean }>`
    ${props =>
        !props.isEditing &&
        `
  * {
   cursor: not-allowed;
  }`}
`
