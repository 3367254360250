import React from 'react'
import { VehiclePhoto } from '../../shared/shared.models'
import styled from 'styled-components'
import { Colors } from '../../configs/styled.config'
import { Column } from '../../shared/shared.styled'

interface PrimaryPhotoProps {
    photo?: VehiclePhoto | undefined
}

export const PrimaryPhoto = ({ photo }: PrimaryPhotoProps) => {
    return photo ? (
        <Image src={photo.url} />
    ) : (
        <Container>
            <Description>
                Este carro ainda não tem imagens. Só depois de atribuir imagens
                a este carro é que pode escolher qual a imagem principal do
                veículo
            </Description>
        </Container>
    )
}

const Container = styled(Column as any)`
    flex-shrink: 1;
    width: 350px;
    height: 350px;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    margin-right: 48px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

const Description = styled('div')`
    font-family: Merriweather-Light;
    font-size: 16px;
    margin: 24px;
    color: ${Colors.dark};
`

const Image = styled('img')`
    margin-left: auto;
    max-width: 100%;
`
