import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { RoleEnum } from '../shared.enums'

export const useIsEditor1_2 = () => {
    const isSuperAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            (state.adminReducer.user.role === RoleEnum.EDITOR1 ||
                state.adminReducer.user.role === RoleEnum.EDITOR2)
    )

    return isSuperAdmin
}

export const useIsEditor1 = () => {
    const isSuperAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.user.role === RoleEnum.EDITOR1
    )

    return isSuperAdmin
}

export const useIsEditor2 = () => {
    const isSuperAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.user.role === RoleEnum.EDITOR2
    )

    return isSuperAdmin
}

export const useIsEditor3 = () => {
    const isSuperAdmin = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.user.role === RoleEnum.EDITOR3
    )

    return isSuperAdmin
}
