import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { AdminApi } from '../admin.api'
import { useDispatch, useSelector } from 'react-redux'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import FixedPageTitle from '../components/fixed-page-title.component'
import { createContactSchema } from '../validation.schemas'
import { Container } from '../../shared/shared.styled'
import PrimaryButton from '../../shared/components/button.component'
import { useAlert } from 'react-alert'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { CreateContactRequest } from '../admin.interfaces'
import { StatusEnum } from '../../shared/shared.enums'
import ContactUserInfo from '../components/contact-user-info.component'
import ContactManagement from '../components/contact-management.component'
import { ContactIteractionsSection } from '../components/contact-iteractions.component'
import { LoggedUser } from '../../shared/shared.models'
import { IStoreInterface } from '../../configs/store.config'

const CreateContactPage = () => {
    // Hooks Initialization
    const dispatch = useDispatch()
    const alert = useAlert()

    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    hideConfirmationModal()
                    window.history.back()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )

    /**
     * Helper function to get the initial formikValues
     * @param contact Contact | undefined
     */
    const getFormikInitialValues = () => {
        return {
            creatorUserUuid: loggedUser.uuid,
            contactUserUuid: loggedUser.uuid,
            date: undefined,
            hour: undefined,
            name: '',
            credit: false,
            parish: undefined,
            county: undefined,
            district: undefined,
            phone: undefined,
            email: undefined,
            font: undefined,
            observation: undefined,
            iteractions: [],
            status: StatusEnum.OPEN,
        }
    }

    /**
     * Calls the API endpoint to save a contact, and handles the loading/error states
     * of the form
     * @param values CreateContactRequest
     * @param actions FormikHelpers<CreateContactRequest>
     */
    const createContact = async (
        values: CreateContactRequest,
        actions: FormikHelpers<CreateContactRequest>
    ) => {
        try {
            actions.setSubmitting(true)
            const res = await AdminApi.methods.createContact(values)
            dispatch(
                AdminActions.methods.createContactSuccessAction(
                    res.data.contact
                )
            )
            navigate(
                RoutesEnum.CONTACTS
                // RoutesEnum.CONTACT_PAGE.replace(
                //     ':contactId',
                //     res.data.contact.uuid
                // )
            )
        } catch (e) {
            alert.error('Erro ao criar o veículo. Por favor tente mais tarde')
        }
    }

    const checkIfFormIsDirtyAndClose = (isDirty: boolean) => {
        if (isDirty) {
            showConfirmationModal()
        } else {
            window.history.back()
        }
    }

    const renderButtons = (isSubmitting: boolean, isDirty: boolean) => (
        <>
            <PrimaryButton
                label="Cancelar"
                onClick={() => checkIfFormIsDirtyAndClose(isDirty)}
            />
            <PrimaryButton
                isLoading={isSubmitting}
                type="submit"
                label="Criar contato"
                redTheme={true}
            />
        </>
    )

    return (
        <Container>
            <Formik<CreateContactRequest>
                onSubmit={createContact}
                initialValues={getFormikInitialValues()}
                validateOnChange={false}
                validationSchema={createContactSchema()}
                enableReinitialize={true}
            >
                {({ isSubmitting, handleSubmit, dirty }) => (
                    <Form
                        translate="yes"
                        onSubmit={isSubmitting ? () => null : handleSubmit}
                    >
                        <FixedPageTitle
                            label="Criar um novo contato"
                            rightButtons={renderButtons(isSubmitting, dirty)}
                        />

                        <ContactUserInfo isEditing={true} />

                        <ContactManagement isEditing={true} />

                        <ContactIteractionsSection isEditing={true} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CreateContactPage
