import React from 'react'
import styled from 'styled-components'
import { Colors } from '../../configs/styled.config'
import { Column } from '../../shared/shared.styled'
import { Typography } from '../../configs/styled.config'
import UploadLogo from './upload-logo.component'
import { Company } from '../../shared/shared.models'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { AdminApi } from '../admin.api'
import { useFormikContext } from 'formik'

interface CompanyPhotoProps {
    company: Company
    title?: string
    containerStyle?: React.CSSProperties
    inEdition?: boolean
}

export const CompanyPhoto = ({
    company,
    title,
    containerStyle,
    inEdition,
}: CompanyPhotoProps) => {
    // Hooks
    const dispatch = useDispatch()
    const { setFieldValue } = useFormikContext<Company>()
    // Local state
    const [isUploading, setIsUploading] = React.useState(false)

    function uploadFile(file: File) {
        // This will return the video of a specific vehicle
        return new Promise<void>(async (resolve, reject) => {
            setIsUploading(true)
            try {
                const res = await AdminApi.methods.uploadLogo(
                    company.uuid,
                    file
                )
                // Update the photos in the redux store
                // This will trigger a re-render on our form, since our form is connected to the redux store
                setFieldValue('logo', res.data.company.logo)
                dispatch(
                    AdminActions.methods.updateLogoSuccessAction(
                        company.uuid,
                        res.data.company.logo ? res.data.company.logo : ''
                    )
                )
                resolve()
            } catch (e) {
                reject()
            } finally {
                setIsUploading(false)
            }
        })
    }
    return (
        <Column
            style={{
                ...containerStyle,
                width: '100%',
            }}
        >
            {title ? <StyledTitle>{title}</StyledTitle> : null}
            {company.logo ? (
                <>
                    {inEdition ? (
                        <ContainerLogo>
                            <Image src={company.logo} />
                            <UploadLogo
                                isUploading={isUploading}
                                handleUpload={uploadFile}
                            />
                        </ContainerLogo>
                    ) : (
                        <Image src={company.logo} />
                    )}
                </>
            ) : (
                <Container>
                    <Description>Esta empresa ainda não tem logo.</Description>
                    <Description>Por favor faça upload do seu logo</Description>
                    {inEdition ? (
                        <UploadLogo
                            isUploading={isUploading}
                            handleUpload={uploadFile}
                        />
                    ) : null}
                </Container>
            )}
        </Column>
    )
}

const ContainerLogo = styled(Column as any)`
    flex-shrink: 1;
    max-width: 200px;
    height: auto;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    margin-right: 48px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

const Container = styled(Column as any)`
    flex-shrink: 1;
    max-width: 200px;
    height: auto;
    border-radius: 6px;
    border: 1px solid #d8d8d8;
    margin-right: 48px;

    @media screen and (max-width: 767px) {
        width: 100%;
    }
`

const Description = styled('div')`
    font-family: Merriweather-Light;
    text-align: center;
    font-size: 16px;
    margin: 24px;
    color: ${Colors.dark};
`

const Image = styled('img')`
    max-width: 200px;
`

export const StyledTitle = styled('span')`
    font-weight: bold;
    margin-bottom: 8px;
    ${Typography['Body_Text_#1_Regular_Left']};
    font-size: 18px;
    color: #44566c;
`

// const UploadLogoContainer = styled("label")`
//   border: dashed 1px #979797;
//   background-color: #f8f8f8;
//   position: relative;
//   cursor: pointer;
//   width: 100%;
//   height: 130px;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;

//   > div {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     justify-content: center;
//   }
// `;

// const IconContainer = styled("div")`
//   width: 20px;
//   height: 20px;
//   margin-bottom: 16px;

//   stroke: ${Colors["gold-pmauto"]};
//   fill: ${Colors["gold-pmauto"]};
// `;

// const UploadLogoPlaceholder = styled("div")`
//   ${Typography["Body_Text_#2_High_Contrast_Center"]};
// `;
