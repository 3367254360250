import React from 'react'
import { useFormikContext } from 'formik'
import { CreateStandRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import styled from 'styled-components'

interface PublicStandFeaturesProps {
    isEditing?: boolean
    rightElement?: React.ReactNode
}

const PublicStandFeatures = ({
    isEditing = true,
    rightElement = null,
}: PublicStandFeaturesProps) => {
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        CreateStandRequest
    >()

    return (
        <Section>
            <Row>
                <SectionTitle>Detalhes Gerais</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Nome"
                                name="name"
                                placeholder="Ex: Stand dos Carros"
                                onChange={handleChange}
                                value={values.name}
                                error={errors.name}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 2}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                placeholder="Ex: Aveiro"
                                name="location"
                                title="Localização"
                                onChange={evt => {
                                    const aux = evt.target.value.toUpperCase()
                                    setFieldValue('location', aux)
                                }}
                                value={values.location}
                                error={errors.location}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 2}>
                            <TextInput
                                inEdition={isEditing}
                                required={true}
                                title="Morada"
                                placeholder="Ex: Rua Amílio Rodrigues Nº7 - Glória"
                                name="address"
                                onChange={handleChange}
                                value={values.address}
                                error={errors.address}
                            />
                        </RatioContainer>
                    </WrapRow>
                    {/* <WrapRow style={{ alignItems: "flex-start" }}>
            <RatioContainer ratio={1 / 4}>
              <TextInput
                inEdition={isEditing}
                title="Veículos sem stand associado"
                placeholder="Ex: CHOOSE"
                name="vehicles"
                onChange={handleChange}
                value={values.vehicles ? values.vehicles : ""}
                error={errors.vehicles}
              />
            </RatioContainer>
          </WrapRow> */}
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default PublicStandFeatures

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;

    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
