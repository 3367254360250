import {
    LoginRequest,
    CreateVehicleRequest,
    CreateStandRequest,
    CreateCompanyRequest,
    CreateUserRequest,
    CreateFinancialRequest,
    CreateContactRequest,
    CreateSupplierRequest,
} from './admin.interfaces'
import { AxiosInstance } from '../configs/axios.config'
import { AxiosPromise, AxiosRequestConfig } from 'axios'
import {
    LoggedUser,
    Vehicle,
    CustomNotification,
    VehiclePhoto,
    Category,
    VehicleVideo,
    Stand,
    Company,
    Financial,
    AnalyticValues,
    Contact,
    AnalyticCharts,
    Supplier,
} from '../shared/shared.models'
import { SaleRequest } from '../sales/sales.interfaces'
export const AdminApi = {
    routes: {
        LOGIN: '/auth/login',
        GET_USERS: '/users/company/:companyId',
        GET_VEHICLES: '/vehicles/',
        GET_VEHICLES_USER: '/vehicles/company/:companyId',
        GET_VEHICLE: '/vehicles/:vehicleId',
        CREATE_VEHICLES: '/vehicles/',
        UPDATE_VEHICLE: '/vehicles/:vehicleId',
        UPLOAD_PHOTOS: '/vehicles/:vehicleId/photos',
        DELETE_PHOTO: '/vehicle-photos/photoId',
        DELETE_VIDEO: '/vehicle-video/videoId',
        NOTIFICATIONS: '/notifications/',
        READ_NOTIFICATION: '/user-notifications/:notificationId',
        DELETE_NOTIFICATION: '/notifications/:notificationId',
        DELETE_ALL_NOTIFICATIONS: '/users/:userId/notifications',
        CATEGORIES: '/categories/',
        COST_TYPES: '/helpers/vehicle-cost-types',
        SALES: '/sales/',
        STANDS: '/stands/company/:companyId',
        CREATE_STANDS: '/stands/',
        GET_STAND: '/stands/:standId',
        GET_VEHICLES_STAND: '/stands/:standId/vehicles',
        UPDATE_STAND: '/stands/:standId',
        DEACTIVATE_STAND: 'stands/:standId',
        GET_COMPANIES: '/companies/',
        GET_COMPANY: '/companies/:companyId',
        CREATE_COMPANIES: '/companies/',
        UPDATE_COMPANY: '/companies/:companyId',
        DELETE_COMPANY: '/companies/:companyId',
        CREATE_USERS: '/auth/register',
        GET_USER: '/users/:userId',
        UPDATE_USER_PASSWORD: '/users/password/:userId',
        UPDATE_USER_ROLE: '/users/role/:userId',
        UPDATE_USER_STATE: '/users/state/:userId',
        DELETE_USER: '/users/:userId',
        GET_FINANCIALS: '/financials/company/:companyId',
        GET_FINANCIAL: '/financials/:financialId',
        UPDATE_FINANCIAL: '/financials/:financialId',
        DELETE_FINANCIAL: 'financials/:financialId',
        CREATE_FINANCIAL: '/financials/company/:companyUuid',
        GET_ANALYSIS: '/vehicles/company/:companyId/analysis',
        GET_ANALYTIC_CONTACT_CHARTS:
            '/contacts/company/:companyId/?dateInit=:dateInit&dateEnd=:dateEnd&user=:user&groupBy=:groupBy',
        GET_CONTACTS: '/contacts/',
        CREATE_CONTACTS: '/contacts/',
        GET_CONTACT: '/contacts/:contactId',
        UPDATE_CONTACT: '/contacts/:contactId',
        UPDATE_CONTACT_STATUS: '/contacts/:contactId',
        DELETE_CONTACT: '/contacts/:contactId',
        GET_PDF_SALE: '/sales/:saleId/generate-pdf',
        GET_PDF_CIRCULATION_AUTHORIZATION: 'documents/sale/:saleId/circulation',
        GET_PDF_CONTRACT: 'documents/sale/:saleId/contract',
        GET_PDF_WARRANTY: 'documents/sale/:saleId/warranty',
        GET_PDF_MATERIAL: 'documents/sale/:saleId/material',
        GET_PDF_FINES: 'documents/sale/:saleId/fines',
        GET_PDF_PRIVACY: 'documents/sale/:saleId/privacy',
        GET_PDF_RETAKE: 'documents/sale/:saleId/retake',
        GET_CSV_STOCK: 'documents-company/company/:companyUuid/stock',
        GET_CSV_SALES: 'documents-company/company/:companyUuid/sales',
        GET_CSV_CONTACTS: 'documents-company/company/:companyUuid/contacts',
        CREATE_SUPPLIERS: '/suppliers/',
        GET_SUPPLIER: '/suppliers/:supplierId',
        UPDATE_SUPPLIER: '/suppliers/:supplierId',
        DELETE_SUPPLIER: '/suppliers/:supplierId',
        GET_SUPPLIERS: '/suppliers/company/:companyId',
    },
    methods: {
        login: (
            values: LoginRequest
        ): AxiosPromise<{
            user: LoggedUser
            token: string
            company: Company
        }> => {
            return AxiosInstance.post(AdminApi.routes.LOGIN, values)
        },
        getUsers: (
            companyId: string
        ): AxiosPromise<{ users: LoggedUser[] }> => {
            return AxiosInstance.get(
                AdminApi.routes.GET_USERS.replace(':companyId', companyId)
            )
        },
        getVehicles: (
            queryParams?: string
        ): AxiosPromise<{ vehicles: Vehicle[] }> => {
            let url = AdminApi.routes.GET_VEHICLES
            if (queryParams) {
                url += queryParams
            }
            return AxiosInstance.get(url)
        },
        getVehiclesStand: (
            standId: string
        ): AxiosPromise<{ vehicles: Vehicle[] }> => {
            const url = AdminApi.routes.GET_VEHICLES_STAND.replace(
                ':standId',
                standId
            )
            return AxiosInstance.get(url)
        },
        getVehiclesCompany: (
            companyId: string,
            queryParams?: string
        ): AxiosPromise<{ vehicles: Vehicle[] }> => {
            let url = AdminApi.routes.GET_VEHICLES_USER.replace(
                ':companyId',
                companyId
            )
            if (queryParams) {
                url += queryParams
            }
            return AxiosInstance.get(url)
        },
        getVehicle: (vehicleId: string): AxiosPromise<{ vehicle: Vehicle }> => {
            const url = AdminApi.routes.GET_VEHICLE.replace(
                ':vehicleId',
                vehicleId
            )
            return AxiosInstance.get(url)
        },
        createVehicle: (
            values: CreateVehicleRequest
        ): AxiosPromise<{ vehicle: Vehicle }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_VEHICLES, values)
        },
        uploadFiles: (
            vehicleUuid: string,
            files: File[]
        ): AxiosPromise<{ photos: VehiclePhoto[] }> => {
            const config: AxiosRequestConfig = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }
            const url = `${AdminApi.routes.CREATE_VEHICLES}${vehicleUuid}/photos`
            const formData = new FormData()
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('photos', files[i])
                }
            }
            return AxiosInstance.post(url, formData, config)
        },
        updatePhoto: (
            photoId: string,
            values: Partial<VehiclePhoto>
        ): AxiosPromise<{ photos: VehiclePhoto[] }> => {
            const url = AdminApi.routes.DELETE_PHOTO.replace('photoId', photoId)
            return AxiosInstance.patch(url, values)
        },
        deletePhoto: (photoId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_PHOTO.replace('photoId', photoId)
            return AxiosInstance.delete(url)
        },
        sellVehicle: (
            vehicleUuid: string
        ): AxiosPromise<{ vehicle: Vehicle }> => {
            const url = AdminApi.routes.UPDATE_VEHICLE.replace(
                ':vehicleId',
                vehicleUuid
            )
            return AxiosInstance.patch(url, {})
        },
        getNotifications: (): AxiosPromise<{
            notifications: CustomNotification[]
        }> => {
            return AxiosInstance.get(AdminApi.routes.NOTIFICATIONS)
        },
        readNotification: (
            notificationId: string,
            is_read: boolean
        ): AxiosPromise<void> => {
            const url = AdminApi.routes.READ_NOTIFICATION.replace(
                ':notificationId',
                notificationId
            )
            return AxiosInstance.put(url, { is_read })
        },
        getCategories: (): AxiosPromise<{ categories: Category[] }> => {
            return AxiosInstance.get(AdminApi.routes.CATEGORIES)
        },
        deleteNotification: (notificationId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_NOTIFICATION.replace(
                ':notificationId',
                notificationId
            )
            return AxiosInstance.delete(url)
        },
        deleteAllNotifications: (userId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_ALL_NOTIFICATIONS.replace(
                ':userId',
                userId
            )
            return AxiosInstance.delete(url)
        },
        updateVehicle: (
            vehicleId: string,
            values: CreateVehicleRequest
        ): AxiosPromise<{ vehicle: Vehicle }> => {
            const url = AdminApi.routes.UPDATE_VEHICLE.replace(
                ':vehicleId',
                vehicleId
            )
            /* const formData = generateFormDataFromValues(values, files); */
            return AxiosInstance.put(url, values)
        },
        updateVehicle_PATCH: (
            vehicleId: string,
            values: Partial<Vehicle>
        ): AxiosPromise<{ vehicle: Vehicle }> => {
            const url = AdminApi.routes.UPDATE_VEHICLE.replace(
                ':vehicleId',
                vehicleId
            )
            return AxiosInstance.patch(url, values)
        },
        getCostTypes: (): AxiosPromise<{ costTypes: string[] }> => {
            const url = AdminApi.routes.COST_TYPES
            return AxiosInstance.get(url)
        },
        createSale: (values: SaleRequest): AxiosPromise<void> => {
            const url = AdminApi.routes.SALES
            return AxiosInstance.post(url, values)
        },
        uploadFile: (
            vehicleUuid: string,
            file: File
        ): AxiosPromise<{ video: VehicleVideo }> => {
            const config: AxiosRequestConfig = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
                timeout: 180000,
            }
            const url = `${AdminApi.routes.CREATE_VEHICLES}${vehicleUuid}/video`
            const formData = new FormData()
            if (file) {
                formData.append('video', file)
            }
            return AxiosInstance.post(url, formData, config)
        },
        deleteVideo: (videoId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_VIDEO.replace('videoId', videoId)
            return AxiosInstance.delete(url)
        },
        getStands: (companyId: string): AxiosPromise<{ stands: Stand[] }> => {
            const url = AdminApi.routes.STANDS.replace(':companyId', companyId)
            return AxiosInstance.get(url)
        },
        getStand: (standId: string): AxiosPromise<{ stand: Stand }> => {
            const url = AdminApi.routes.GET_STAND.replace(':standId', standId)
            return AxiosInstance.get(url)
        },
        createStand: (
            values: CreateStandRequest
        ): AxiosPromise<{ stand: Stand }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_STANDS, values)
        },
        updateStand: (
            standId: string,
            values: CreateStandRequest
        ): AxiosPromise<{ stand: Stand }> => {
            const url = AdminApi.routes.UPDATE_STAND.replace(
                ':standId',
                standId
            )
            /* const formData = generateFormDataFromValues(values, files); */
            return AxiosInstance.put(url, values)
        },
        updateStandActive: (
            standId: string,
            companyId: string
        ): AxiosPromise<{ stand: Stand }> => {
            const url = AdminApi.routes.UPDATE_STAND.replace(
                ':standId',
                standId
            )
            const values = { active: true, companyUuid: companyId }
            /* const formData = generateFormDataFromValues(values, files); */
            return AxiosInstance.put(url, values)
        },
        deactivateStand: (
            standId: string,
            companyId: string
        ): AxiosPromise<{ stand: Stand }> => {
            const url = AdminApi.routes.UPDATE_STAND.replace(
                ':standId',
                standId
            )
            const values = { active: false, companyUuid: companyId }
            return AxiosInstance.put(url, values)
        },
        getCompanies: (): AxiosPromise<{ companies: Company[] }> => {
            return AxiosInstance.get(AdminApi.routes.GET_COMPANIES)
        },
        getCompany: (companyId: string): AxiosPromise<{ company: Company }> => {
            const url = AdminApi.routes.GET_COMPANY.replace(
                ':companyId',
                companyId
            )
            return AxiosInstance.get(url)
        },
        createCompany: (
            values: CreateCompanyRequest
        ): AxiosPromise<{ company: Company }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_COMPANIES, values)
        },
        updateCompany: (
            companyId: string,
            values: CreateCompanyRequest
        ): AxiosPromise<{ company: Company }> => {
            const url = AdminApi.routes.UPDATE_COMPANY.replace(
                ':companyId',
                companyId
            )
            /* const formData = generateFormDataFromValues(values, files); */
            return AxiosInstance.put(url, values)
        },
        deleteCompany: (companyId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_COMPANY.replace(
                ':companyId',
                companyId
            )
            return AxiosInstance.delete(url)
        },
        uploadLogo: (
            companyUuid: string,
            file: File
        ): AxiosPromise<{ company: Company }> => {
            const config: AxiosRequestConfig = {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            }
            const url = `${AdminApi.routes.CREATE_COMPANIES}${companyUuid}/logo`
            const formData = new FormData()
            if (file) {
                formData.append('photo', file)
            }
            return AxiosInstance.post(url, formData, config)
        },
        createUser: (
            values: CreateUserRequest
        ): AxiosPromise<{ user: LoggedUser }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_USERS, values)
        },
        updateUserPassword: (
            userId: string,
            values: CreateUserRequest
        ): AxiosPromise<{ user: LoggedUser }> => {
            const url = AdminApi.routes.UPDATE_USER_PASSWORD.replace(
                ':userId',
                userId
            )
            return AxiosInstance.patch(url, values)
        },
        updateUserRole: (
            userId: string,
            values: CreateUserRequest
        ): AxiosPromise<{ user: LoggedUser }> => {
            const url = AdminApi.routes.UPDATE_USER_ROLE.replace(
                ':userId',
                userId
            )
            return AxiosInstance.patch(url, values)
        },
        activateUser: (
            userId: string,
            companyId: string
        ): AxiosPromise<{ user: LoggedUser }> => {
            const url = AdminApi.routes.UPDATE_USER_STATE.replace(
                ':userId',
                userId
            )
            const values = { isActive: true, companyUuid: companyId }
            /* const formData = generateFormDataFromValues(values, files); */
            return AxiosInstance.patch(url, values)
        },
        deactivateUser: (
            userId: string,
            companyId: string
        ): AxiosPromise<{ user: LoggedUser }> => {
            const url = AdminApi.routes.UPDATE_USER_STATE.replace(
                ':userId',
                userId
            )
            const values = { isActive: false, companyUuid: companyId }
            return AxiosInstance.patch(url, values)
        },
        getUser: (userId: string): AxiosPromise<{ user: LoggedUser }> => {
            const url = AdminApi.routes.GET_USER.replace(':userId', userId)
            return AxiosInstance.get(url)
        },
        getFinancials: (
            companyId: string
        ): AxiosPromise<{ financials: Financial[] }> => {
            return AxiosInstance.get(
                AdminApi.routes.GET_FINANCIALS.replace(':companyId', companyId)
            )
        },
        getFinancial: (
            financialId: string
        ): AxiosPromise<{ financial: Financial }> => {
            const url = AdminApi.routes.GET_USER.replace(
                ':financialId',
                financialId
            )
            return AxiosInstance.get(url)
        },
        createFinancial: (
            companyId: string,
            values: CreateFinancialRequest
        ): AxiosPromise<{ financial: Financial }> => {
            return AxiosInstance.post(
                AdminApi.routes.CREATE_FINANCIAL.replace(
                    ':companyId',
                    companyId
                ),
                values
            )
        },
        deleteFinancial: (financialId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_FINANCIAL.replace(
                ':financialId',
                financialId
            )
            return AxiosInstance.delete(url)
        },
        updateFinancial: (
            financialId: string,
            values: CreateFinancialRequest
        ): AxiosPromise<{ financial: Financial }> => {
            const url = AdminApi.routes.UPDATE_FINANCIAL.replace(
                ':financialId',
                financialId
            )
            return AxiosInstance.put(url, values)
        },
        getAnalysis: (
            companyId: string
        ): AxiosPromise<{ analysis: AnalyticValues }> => {
            const url = AdminApi.routes.GET_ANALYSIS.replace(
                ':companyId',
                companyId
            )
            return AxiosInstance.get(url)
        },
        getContactChartData: (
            companyId: string,
            filterInitialDate: string,
            filterEndDate: string,
            filterUser: string,
            filterGroupBy: string
        ): AxiosPromise<{ results: AnalyticCharts[] }> => {
            const url = AdminApi.routes.GET_ANALYTIC_CONTACT_CHARTS.replace(
                ':companyId',
                companyId
            )
                .replace(':dateInit', filterInitialDate)
                .replace(':dateEnd', filterEndDate)
                .replace(':user', filterUser)
                .replace(':groupBy', filterGroupBy)
            return AxiosInstance.get(url)
        },
        getContacts: (
            companyId: string
        ): AxiosPromise<{ contacts: Contact[] }> => {
            const url = AdminApi.routes.GET_CONTACTS.replace(
                ':companyId',
                companyId
            )
            return AxiosInstance.get(url)
        },
        createContact: (
            values: CreateContactRequest
        ): AxiosPromise<{ contact: Contact }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_CONTACTS, values)
        },
        getContact: (contactId: string): AxiosPromise<{ contact: Contact }> => {
            const url = AdminApi.routes.GET_CONTACT.replace(
                ':contactId',
                contactId
            )
            return AxiosInstance.get(url)
        },
        updateContact: (
            contactId: string,
            values: CreateContactRequest
        ): AxiosPromise<{ contact: Contact }> => {
            const url = AdminApi.routes.UPDATE_CONTACT.replace(
                ':contactId',
                contactId
            )
            return AxiosInstance.put(url, values)
        },
        patchContact: (
            contactId: string,
            status: string
        ): AxiosPromise<{ contact: Contact }> => {
            const url = AdminApi.routes.UPDATE_CONTACT_STATUS.replace(
                ':contactId',
                contactId
            )
            const values = { status: status }
            return AxiosInstance.patch(url, values)
        },
        deleteContact: (contactId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_CONTACT.replace(
                ':contactId',
                contactId
            )
            return AxiosInstance.delete(url)
        },
        getPDFCirculationAuthorization: (
            saleId: string
        ): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_CIRCULATION_AUTHORIZATION.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFContract: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_CONTRACT.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFWarranty: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_WARRANTY.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFMaterial: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_MATERIAL.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFFines: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_FINES.replace(':saleId', saleId)
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFPrivacy: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_PRIVACY.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getPDFRetake: (saleId: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_PDF_RETAKE.replace(
                ':saleId',
                saleId
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getCSVStock: (companyUuid: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_CSV_STOCK.replace(
                ':companyUuid',
                companyUuid
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getCSVSales: (companyUuid: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_CSV_SALES.replace(
                ':companyUuid',
                companyUuid
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        getCSVContacts: (companyUuid: string): AxiosPromise<Blob> => {
            const url = AdminApi.routes.GET_CSV_CONTACTS.replace(
                ':companyUuid',
                companyUuid
            )
            return AxiosInstance.get(url, { responseType: 'blob' })
        },
        createSupplier: (
            values: CreateSupplierRequest
        ): AxiosPromise<{ supplier: Supplier }> => {
            return AxiosInstance.post(AdminApi.routes.CREATE_SUPPLIERS, values)
        },
        getSupplier: (
            supplierId: string
        ): AxiosPromise<{ supplier: Supplier }> => {
            const url = AdminApi.routes.GET_SUPPLIER.replace(
                ':supplierId',
                supplierId
            )
            return AxiosInstance.get(url)
        },
        updateSupplier: (
            supplierId: string,
            values: CreateSupplierRequest
        ): AxiosPromise<{ supplier: Supplier }> => {
            const url = AdminApi.routes.UPDATE_SUPPLIER.replace(
                ':supplierId',
                supplierId
            )
            return AxiosInstance.put(url, values)
        },
        deleteSupplier: (supplierId: string): AxiosPromise<void> => {
            const url = AdminApi.routes.DELETE_SUPPLIER.replace(
                ':supplierId',
                supplierId
            )
            return AxiosInstance.delete(url)
        },
        getSuppliers: (
            companyId: string
        ): AxiosPromise<{ suppliers: Supplier[] }> => {
            const url = AdminApi.routes.GET_SUPPLIERS.replace(
                ':companyId',
                companyId
            )
            return AxiosInstance.get(url)
        },
    },
}
