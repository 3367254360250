import React from 'react'
import {
    FuelEnum,
    VehicleTypeEnum,
    CarStateEnum,
    AvailabilityEnum,
    StandsEnum,
    NotificationTypeEnum,
} from '../shared.enums'
import { Colors } from '../../configs/styled.config'

const TranslationUtils = {
    getFuelTypeTranslation: (fuelType: string) => {
        switch (fuelType) {
            case FuelEnum.DIESEL:
                return 'Diesel'
            case FuelEnum.ELECTRIC:
                return 'Eléctrico'
            case FuelEnum.GAS:
                return 'Gás'
            case FuelEnum.GASOLINE:
                return 'Gasolina'
            case FuelEnum.HYBRID:
                return 'Híbrido'
            default:
                return '-'
        }
    },
    getVehicleTypeTranslation: (vehicleType: VehicleTypeEnum) => {
        switch (vehicleType) {
            case VehicleTypeEnum.COMMERCIAL:
                return 'Comercial'
            case VehicleTypeEnum.HEAVY_GOODS:
                return 'Pesado de Mercadorias'
            case VehicleTypeEnum.HEAVY_PASSENGERS:
                return 'Pesado de Passageiros'
            case VehicleTypeEnum.LIGHT_PASSENGERS:
                return 'Ligeiro de passageiros'
            case VehicleTypeEnum.MOTORCYCLES:
                return 'Motociclos'
            default:
                return '-'
        }
    },
    getStateTranslation: (state: CarStateEnum) => {
        switch (state) {
            case CarStateEnum.NEW:
                return 'Novo'
            case CarStateEnum.USED:
                return 'Usado'
            case CarStateEnum.SEMI_NEW:
                return 'Semi-novo'
            default:
                return '-'
        }
    },
    getAvailabilityTranslation: (availability: AvailabilityEnum) => {
        switch (availability) {
            case AvailabilityEnum.READY_FOR_SALE:
                return (
                    <span style={{ color: Colors.green }}>
                        Pronto para venda
                    </span>
                )
            case AvailabilityEnum.REPARING:
                return <span style={{ color: '#e6ac0e' }}>Em reparação</span>
            case AvailabilityEnum.VERIFYING:
                return <span style={{ color: '#bf920b' }}>A verificar</span>
            case AvailabilityEnum.SOLD:
                return <span style={{ color: Colors['green'] }}>Vendido</span>
            case AvailabilityEnum.DELIVERED:
                return (
                    <span style={{ color: Colors['alive-green'] }}>
                        Entregue
                    </span>
                )
            default:
                return '-'
        }
    },
    getStandLocationTranslation: (standLocation: StandsEnum) => {
        switch (standLocation) {
            case StandsEnum.ILHAVO:
                return 'Ílhavo'
            default:
                return '-'
        }
    },
    getNotificationTypeTranslation: (
        notificationType: NotificationTypeEnum
    ) => {
        switch (notificationType) {
            case NotificationTypeEnum.IN_STOCK_VEHICLE:
                return 'Veículo em Stock'
            case NotificationTypeEnum.NEW_VEHICLE:
                return 'Novo veículo no sistema'
            case NotificationTypeEnum.SOLD_VEHICLE:
                return 'Veículo vendido'
            case NotificationTypeEnum.UPDATED_VEHICLE:
                return 'Veículo actualizado'
        }
    },
    getCostTypesTranslation: (costType: string) => {
        switch (costType) {
            case 'MECHANIC':
                return 'Mecânica'
            case 'PAINT_OR_PLATE':
                return 'Pintura/Chapa'
            case 'STAMP':
                return 'Selo'
            case 'TRANSPORTATION':
                return 'Transporte'
            case 'INSPECTION':
                return 'Vistoria'
            case 'UPHOLSTERER':
                return 'Estofador'
            case 'COMPLAINT':
                return 'Reclamação'
            case 'OTHER':
                return 'Outros'
            default:
                return ''
        }
    },
    getContactStatusTranslation: (status: string) => {
        switch (status) {
            case 'OPEN':
                return 'Aberto'
            case 'CLOSED':
                return 'Fechado'
            case 'SOLD':
                return 'Vendido'
            default:
                return ''
        }
    },
    getContactFontTranslation: (font: string) => {
        switch (font) {
            case 'OLD_CLIENT':
                return 'Cliente Antigo'
            case 'EMAIL':
                return 'Email'
            case 'EVENTS':
                return 'Eventos'
            case 'RADIO':
                return 'Rádio'
            case 'RECOMENDATION':
                return 'Recomendação'
            case 'SOCIAL_NETWORK':
                return 'Redes Sociais'
            case 'OTHER':
                return 'Outros'
            default:
                return ''
        }
    },
    getOriginTranslation: (origin: string) => {
        switch (origin) {
            case 'OLD_CUSTOMER':
                return 'Cliente Antigo'
            case 'TEMPORARY_CUSTOMER':
                return 'Visita ao Stand'
            case 'EMAIL':
                return 'Email'
            case 'EVENTS':
                return 'Eventos'
            case 'RADIO':
                return 'Rádio'
            case 'RECOMENDATION':
                return 'Recomendação'
            case 'SOCIAL_NETWORK':
                return 'Redes Sociais'
            case 'OTHER':
                return 'Outros'
            case 'WEBSITE':
                return 'Website'
            default:
                return ''
        }
    },
}

export default TranslationUtils
