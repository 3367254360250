import React from "react";

const VehiclesIcon = () => {
  return (
    <svg
      viewBox="0 0 23 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="inherit"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Vehicles-/-Create-vehicle---Dropdowns-open"
          transform="translate(-21.000000, -136.000000)"
          fillRule="nonzero"
          stroke="inherit"
        >
          <g
            id="UI-/-Navigation-Menu"
            transform="translate(0.000000, -0.000000)"
          >
            <g
              id="Nav-Menu-/-Item-/-Selected"
              transform="translate(12.000000, 127.000000)"
            >
              <g id="mdi-car-multiple">
                <g id="Icon" transform="translate(10.000000, 10.000000)">
                  <path
                    d="M7,9.00011075 L8.5,4.50011075 L17.5,4.50011075 L19,9.00011075 M17.5,14.0001107 C16.6715729,14.0001107 16,13.3285379 16,12.5001107 C16,11.6716836 16.6715729,11.0001107 17.5,11.0001107 C18.3284271,11.0001107 19,11.6716836 19,12.5001107 C19,13.3285379 18.3284271,14.0001107 17.5,14.0001107 Z M8.5,14.0001107 C7.67157288,14.0001107 7,13.3285379 7,12.5001107 C7,11.6716836 7.67157288,11.0001107 8.5,11.0001107 C9.32842712,11.0001107 10,11.6716836 10,12.5001107 C10,13.3285379 9.32842712,14.0001107 8.5,14.0001107 Z M18.92,4.00011075 C18.7099561,3.39685458 18.1387535,2.99459927 17.5,3.00005481 L8.5,3.00005481 C7.86124646,2.99459927 7.29004393,3.39685458 7.08,4.00011075 L5,10.0001107 L5,18.0001107 C5,18.5523955 5.44771525,19.0001107 6,19.0001107 L7,19.0001107 C7.55228475,19.0001107 8,18.5523955 8,18.0001107 L8,17.0001107 L18,17.0001107 L18,18.0001107 C18,18.5523955 18.4477153,19.0001107 19,19.0001107 L20,19.0001107 C20.5522847,19.0001107 21,18.5523955 21,18.0001107 L21,10.0001107 L18.92,4.00011075 Z M13.92,1.00011075 C13.7099561,0.396854579 13.1387535,-0.00540072653 12.5,5.48120815e-05 L3.5,5.48120815e-05 C2.86124646,-0.00540072653 2.29004393,0.396854579 2.08,1.00011075 L0,7.00011075 L0,15.0001107 C0,15.5523955 0.44771525,16.0001107 1,16.0001107 L2,16.0001107 C2.55228475,16.0001107 3,15.5523955 3,15.0001107 L3,10.9101107 C2.31751532,10.66247 1.91035587,9.96159473 2.03330068,9.24605588 C2.1562455,8.53051704 2.77399756,8.00574337 3.5,8.00011075 L3.57,8.00011075 L4.27,6.00011075 L2,6.00011075 L3.5,1.50011075 L14.09,1.50011075 L13.92,1.00011075 Z"
                    id="Icon-Shape"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default VehiclesIcon;
