import React from 'react'
import { AdminApi } from '../admin.api'
import { useDispatch, useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import CompanySearch from '../components/company-search.component'
import { AdminUtils } from '../admin.utils'
import { PageTitle } from '../../shared/shared.styled'
import { useFilter } from '../../shared/hooks/use-filter.hook'
import { Company } from '../../shared/shared.models'
import { ColumnProps } from '../../shared/components/table/table.component'
import { SortUtils, SortOrderEnum } from '../../shared/sort.utils'
import { HeaderEnumCompany } from '../../shared/components/table/table-headers.component'
import { useAlert } from 'react-alert'
import { createSelector } from 'reselect'
import ActionButton from '../../shared/components/action-button.component'
import {
    getCompanyCarsComponent,
    getCompanyColorComponent,
    getCompanyNameComponent,
    getCompanyStandsComponent,
    renderCompanyLogoComponent,
} from '../../shared/shared.utils'
import { TableCompany } from '../../shared/components/table/table-companies.component'

const companySelector = createSelector(
    (state: IStoreInterface) => state.adminReducer.companies,
    companies => companies.filter(company => company.active === true)
)

const SuperAdminDashboardPage = () => {
    // Hooks initialization
    const dispatch = useDispatch()

    // Redux State
    const companies = useSelector(companySelector)
    const alert = useAlert()

    const [visibleCompanies, setVisibleCompanies] = React.useState<Company[]>(
        []
    )

    // Local state
    const [isFetchingCompanies, setIsFetchingCompanies] = React.useState(false)
    const { filters, updateCompanyFilter, clearAllFilters } = useFilter()

    React.useEffect(() => {
        setVisibleCompanies(companies)
    }, [companies])

    // Effects
    React.useEffect(() => {
        setIsFetchingCompanies(true)
        AdminApi.methods
            .getCompanies()
            .finally(() => setIsFetchingCompanies(false))
            .then(
                res => {
                    const { companies } = res.data
                    dispatch(
                        AdminActions.methods.getCompaniesSuccessAction(
                            companies
                        )
                    )
                },
                e => {
                    alert.show(
                        'Erro ao actualizar as empresas, por favor tente mais tarde',
                        { type: 'error' }
                    )
                }
            )
    }, [dispatch, alert])

    React.useEffect(() => {
        const newVisibleCompanies = AdminUtils.filterAllCompanies(
            filters,
            companies
        )
        setVisibleCompanies(newVisibleCompanies)
    }, [filters, companies])

    // Functions
    const navigateToCreateCompany = () => {
        navigate(RoutesEnum.CREATE_COMPANY)
    }

    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedCompanies = SortUtils.sortCompanies(
            visibleCompanies,
            header,
            order
        )
        setVisibleCompanies(sortedCompanies)
    }

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: 'image',
                ratio: 1 / 10,
                label: '',
                renderFunction: company => renderCompanyLogoComponent(company),
            },
            {
                key: HeaderEnumCompany.NAME,
                ratio: 3 / 10,
                label: 'Nome',
                renderFunction: company => getCompanyNameComponent(company),
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumCompany.COLOR,
                label: 'Cor',
                ratio: 2 / 10,
                renderFunction: company => getCompanyColorComponent(company),
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumCompany.NUMBER_OF_STANDS,
                label: 'Número de stands',
                ratio: 2 / 10,
                renderFunction: company => getCompanyStandsComponent(company),
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumCompany.NUMBER_OF_CARS,
                label: 'Número de veículos',
                ratio: 2 / 10,
                renderFunction: company => getCompanyCarsComponent(company),
                onClick: onHeaderClick,
            },
        ]
    }

    const editVehicle = (company: Company) => {
        navigate(RoutesEnum.COMPANY_PAGE.replace(':companyId', company.uuid))
    }

    return (
        <>
            <PageTitle>Empresas disponíveis</PageTitle>
            <CompanySearch
                updateFilter={updateCompanyFilter}
                filters={filters}
                clearAllFilters={clearAllFilters}
            />
            <TableCompany
                isLoadingData={isFetchingCompanies}
                values={visibleCompanies}
                onRowClick={editVehicle}
                columns={getColumns()}
            />
            <ActionButton onClick={navigateToCreateCompany} />
        </>
    )
}

export default SuperAdminDashboardPage
