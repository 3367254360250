import { object, string, number, boolean } from 'yup'

export const createVehicleSchema = () => {
    const schema = object().shape({
        brand: string()
            .trim()
            .required('Campo obrigatório'),
        model: string().required('Campo obrigatório'),
        licensePlate: string()
            .required('Campo obrigatório')
            .max(8, 'Só pode ter no máximo 8 digitos'),
        dateVehicle: string().nullable(),
        createdAt: string().required('Campo obrigatório'),
        numberDoors: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        numberSeats: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        motorPower: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        mileage: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        cylinder: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        sellerPrice: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        buyPrice: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        associatedCosts: number()
            .typeError('Precisa de ser um número')
            .nullable(),
        previewCosts: number()
            .typeError('Precisa de ser um número')
            .nullable(),
    })

    return schema
}

export const createSaleValidationSchema = () => {
    const schema = object().shape({
        dateOfSale: string().required('Campo obrigatório'),
        salePrice: number()
            .typeError('Precisa de ser um número')
            .required('Campo obrigatório'),
        client: object().shape({
            name: string().required('Campo obrigatório'),
        }),
        loan: boolean(),
        sellerUuid: string().required('Campo obrigatório'),
        loanValue: number().when('loan', {
            is: true,
            then: number()
                .typeError('Precisa de ser um número')
                .required('Campo obrigatório'),
        }),
        loanCompany: string().when('loan', {
            is: true,
            then: string().required('Campo obrigatório'),
        }),
    })

    return schema
}

export const createStandSchema = () => {
    const schema = object().shape({
        name: string().required('Campo obrigatório'),
        location: string().required('Campo obrigatório'),
        address: string().nullable(),
    })

    return schema
}

export const createSupplierSchema = () => {
    const schema = object().shape({
        name: string().required('Campo obrigatório'),
        street: string().nullable(),
        address: string().nullable(),
        parish: string().nullable(),
        county: string().nullable(),
        district: string().nullable(),
        phone: string().nullable(),
        email: string().nullable(),
        nif: number().nullable(),
    })

    return schema
}

export const createCompanySchema = () => {
    const schema = object().shape({
        name: string().required('Campo obrigatório'),
        color: string().nullable(),
        logo: string().nullable(),
    })

    return schema
}

export const createUserSchema = () => {
    const schema = object().shape({
        username: string().required('Campo obrigatório'),
        password: string().required('Campo obrigatório'),
        firstName: string().nullable(),
        lastName: string().nullable(),
    })

    return schema
}

export const createContactSchema = () => {
    const schema = object().shape({
        name: string()
            .trim()
            .required('Campo obrigatório'),
        status: string().required('Campo obrigatório'),
        contactUserUuid: string().required('Campo obrigatório'),
    })

    return schema
}
