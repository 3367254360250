import { LoggedUser, Vehicle } from '../shared/shared.models'
import { RoleEnum } from '../shared/shared.enums'
import { ColumnProps } from '../shared/components/table/table.component'
import {
    euroFormatter,
    getVehicleTotalValue,
    renderVehicleImageComponent,
    getBrandAndModelComponent,
    getSaleMargin,
} from '../shared/shared.utils'
import moment from 'moment'
import { SortOrderEnum } from '../shared/sort.utils'

export const TableUtils = {
    getSoldVehiclesColumns: (
        loggedUser: LoggedUser,
        onHeaderClick: (header: string, order: SortOrderEnum) => void
    ): ColumnProps[] => {
        if (loggedUser.role === RoleEnum.WORKSHOP) {
            return [
                {
                    key: 'image',
                    ratio: 0.5 / 10,
                    label: '',
                    renderFunction: vehicle =>
                        renderVehicleImageComponent(vehicle),
                },
                {
                    ratio: 3 / 10,
                    label: 'Marca/Modelo/Versão',
                    key: 'brand',
                    renderFunction: vehicle =>
                        getBrandAndModelComponent(vehicle),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 2 / 10,
                    label: 'Mês/Ano de venda',
                    key: 'dateOfSale',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.saleDate &&
                        moment(value.saleDate).format('MM-YYYY'),
                    onClick: onHeaderClick,
                },
            ]
        } else if (
            loggedUser.role === RoleEnum.SELLER ||
            loggedUser.role === RoleEnum.EDITOR1 ||
            loggedUser.role === RoleEnum.EDITOR2
        ) {
            return [
                {
                    key: 'image',
                    ratio: 0.5 / 10,
                    label: '',
                    renderFunction: vehicle =>
                        renderVehicleImageComponent(vehicle),
                },
                {
                    ratio: 3 / 10,
                    label: 'Marca/Modelo/Versão',
                    key: 'brand',
                    renderFunction: vehicle =>
                        getBrandAndModelComponent(vehicle),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 2 / 10,
                    label: 'Mês/Ano de venda',
                    key: 'dateOfSale',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.saleDate &&
                        moment(value.saleDate).format('MM-YYYY'),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 2 / 10,
                    label: 'Valor da venda',
                    key: 'salePrice',
                    alignment: 'flex-end',
                    renderFunction: (value: Vehicle) =>
                        value.salePrice
                            ? euroFormatter.format(value.salePrice)
                            : '-',
                    onClick: onHeaderClick,
                },
            ]
        } else if (loggedUser.role === RoleEnum.EDITOR3) {
            return [
                {
                    key: 'image',
                    ratio: 0.5 / 10,
                    label: '',
                    renderFunction: vehicle =>
                        renderVehicleImageComponent(vehicle),
                },
                {
                    ratio: 3 / 10,
                    label: 'Marca/Modelo/Versão',
                    key: 'brand',
                    renderFunction: vehicle =>
                        getBrandAndModelComponent(vehicle),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 2 / 10,
                    label: 'Mês/Ano de venda',
                    key: 'dateOfSale',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.saleDate &&
                        moment(value.saleDate).format('MM-YYYY'),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1 / 10,
                    label: 'Valor da venda',
                    key: 'salePrice',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.salePrice
                            ? euroFormatter.format(value.salePrice)
                            : '-',
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1.5 / 10,
                    label: 'Valor financiado',
                    key: 'loanValue',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.loanValue
                            ? euroFormatter.format(value.loanValue)
                            : '-',
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1 / 10,
                    label: 'Financeira',
                    key: 'loanCompany',
                    renderFunction: (value: Vehicle) =>
                        value.financialName ? value.financialName : '-',
                },
                {
                    ratio: 1 / 10,
                    label: 'Vendedor',
                    key: 'seller',
                    renderFunction: (value: Vehicle) =>
                        value.username ? value.username : '-',
                },
            ]
        } else {
            return [
                {
                    key: 'image',
                    ratio: 0.5 / 10,
                    label: '',
                    renderFunction: vehicle =>
                        renderVehicleImageComponent(vehicle),
                },
                {
                    ratio: 2 / 10,
                    label: 'Marca/Modelo/Versão',
                    key: 'brand',
                    renderFunction: vehicle =>
                        getBrandAndModelComponent(vehicle),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1.5 / 10,
                    label: 'Mês/Ano de venda',
                    key: 'dateOfSale',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.saleDate &&
                        moment(value.saleDate).format('MM-YYYY'),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1 / 10,
                    label: 'Valor da venda',
                    key: 'salePrice',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.salePrice
                            ? euroFormatter.format(value.salePrice)
                            : '-',
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1.5 / 10,
                    label: 'Margem da venda',
                    key: 'saleMargin',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.salePrice
                            ? euroFormatter.format(getSaleMargin(value))
                            : euroFormatter.format(getVehicleTotalValue(value)),
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1.5 / 10,
                    label: 'Valor financiado',
                    key: 'loanValue',
                    alignment: 'center',
                    renderFunction: (value: Vehicle) =>
                        value.loanValue
                            ? euroFormatter.format(value.loanValue)
                            : '-',
                    onClick: onHeaderClick,
                },
                {
                    ratio: 1 / 10,
                    label: 'Financeira',
                    key: 'loanCompany',
                    renderFunction: (value: Vehicle) =>
                        value.financialName ? value.financialName : '-',
                },
                {
                    ratio: 1 / 10,
                    label: 'Vendedor',
                    key: 'seller',
                    renderFunction: (value: Vehicle) =>
                        value.username ? value.username : '-',
                },
            ]
        }
    },
}
