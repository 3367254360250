import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { RoleEnum } from '../shared.enums'

export const useHasStands = () => {
    const standsExists = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            (state.adminReducer.user.role === RoleEnum.MANAGER ||
                state.adminReducer.user.role === RoleEnum.ADMIN ||
                state.adminReducer.user.role === RoleEnum.EDITOR3 ||
                state.adminReducer.user.role === RoleEnum.WORKSHOP) &&
            state.adminReducer.stands.length > 0 &&
            state.adminReducer.stands.some(stand => stand.active === true)
    )

    return standsExists
}
