import React from 'react'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../../configs/store.config'
import { Formik } from 'formik'
import { createSaleValidationSchema } from '../../../admin/validation.schemas'
import { Container } from '../../../shared/shared.styled'
import { SaleRequest } from '../../sales.interfaces'
import { CreateSaleForm } from '../../forms/create-sale.form'
import { AdminApi } from '../../../admin/admin.api'
import { navigate } from 'hookrouter'
import { VehicleRoutesEnum } from '../../../routes.constants'
import moment from 'moment'
import { LoggedUser, Vehicle, Stand } from '../../../shared/shared.models'
import { useAlert } from 'react-alert'
import { useIsAdmin } from '../../../shared/hooks/use-is-admin.hook'

interface CreateSalePageProps {
    vehicle: Vehicle
}

export const CreateSalePage = ({ vehicle }: CreateSalePageProps) => {
    // Hooks init
    const alert = useAlert()
    const isAdmin = useIsAdmin()

    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )

    const { company } = useSelector(
        (state: IStoreInterface) => state.adminReducer
    )
    const [stands, setStands] = React.useState<Stand[]>([])

    React.useEffect(() => {
        try {
            if (company) {
                AdminApi.methods.getStands(company.uuid).then(
                    res => {
                        setStands(res.data.stands)
                    },
                    e => {
                        alert.error('Error fetching the stands list')
                    }
                )
            }
        } catch (e) {
            alert.error('Erro ao listar os stands. Por favor tente mais tarde')
        }
    }, [alert, company])

    const [isLoading, setIsLoading] = React.useState(false)

    // If the vehicle doesn't exist, we don't show anything
    // In the future, we could show a error page
    if (!vehicle) return null

    const submitValues = (values: SaleRequest) => {
        setIsLoading(true)

        AdminApi.methods
            .createSale(values)
            .finally(() => setIsLoading(false))
            .then(
                () => {
                    alert.success('Venda criada com sucesso')
                    navigate(
                        VehicleRoutesEnum.SALE_DETAILS.replace(
                            ':vehicleId',
                            vehicle.uuid
                        )
                    )
                },
                () => {
                    alert.error(
                        'Houve um problema a criar a venda, por favor tente mais tarde'
                    )
                }
            )
    }

    return (
        <Container>
            <Formik<SaleRequest>
                onSubmit={submitValues}
                validationSchema={createSaleValidationSchema()}
                validateOnChange={false}
                initialValues={{
                    processDate: moment().format('YYYY-MM-DD'),
                    processCreatorUuid: loggedUser.uuid,
                    dateOfSale: '',
                    sellerUuid: !isAdmin ? loggedUser.uuid : '',
                    monthWarranty: undefined,
                    mileageWarranty: undefined,
                    vehicleUuid: vehicle.uuid,
                    salePrice: undefined,
                    effectivePrice: undefined,
                    onSignedContractValue: undefined,
                    onVehicleDeliveryValue: undefined,
                    loan: false,
                    loanValue: undefined,
                    client: {
                        name: '',
                    },
                    origin: undefined,
                }}
            >
                {() => (
                    <CreateSaleForm
                        isLoading={isLoading}
                        submitValues={submitValues}
                        vehicle={vehicle}
                        stands={stands}
                    />
                )}
            </Formik>
        </Container>
    )
}
