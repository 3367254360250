import React from 'react'
import { Row } from '../shared.styled'
import { AlertComponentPropsWithStyle, AlertType } from 'react-alert'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import CloseIcon from '../../assets/icons/close.icon'

export const Alert = ({
    style,
    options,
    message,
    close,
}: AlertComponentPropsWithStyle) => {
    return (
        <CustomRow {...style}>
            <AlertMessage type={options.type || 'success'}>
                {message}
            </AlertMessage>
            <CloseIcon
                onClick={close}
                style={{
                    marginLeft: 36,
                    color: Colors['gold-pmauto'],
                    width: 16,
                    height: 16,
                    cursor: 'pointer',
                }}
            />
        </CustomRow>
    )
}

const CustomRow = styled(Row as any)`
    padding: 12px 24px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.5);
`

const AlertMessage = styled('span')<{ type: AlertType }>`
  ${Typography['Body_Text_#1_Regular_Left']}
  color: ${props => {
      switch (props.type) {
          case 'success':
              return 'green'
          case 'error':
              return Colors['alive-red']
          case 'info':
              return 'yellow'
      }
  }}
`
