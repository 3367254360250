import React from 'react'
import { RatioContainer, Column, WrapRow } from '../../shared/shared.styled'
import SearchBar from '../../shared/components/search-bar.component'
import styled from 'styled-components'
import SmallButton from '../../shared/components/small-button.component'
import CloseIcon from '../../assets/icons/close.icon'
import { FilterCompanyProps } from '../../shared/shared.interface'

export enum CompanyFilters {
    NAME = 'NAME',
    NUMBER_OF_STANDS = 'NUMBER_OF_STANDS',
}

const CompanySearch = ({
    updateFilter,
    filters,
    clearAllFilters,
}: FilterCompanyProps) => {
    //const alert = useAlert()

    return (
        <SearchContainer>
            <WrapRow>
                <RatioContainer ratio={1.25 / 5}>
                    <SearchBar
                        handleChange={value =>
                            updateFilter(CompanyFilters.NAME, value)
                        }
                        value={filters[CompanyFilters.NAME]}
                        placeholder="Nome"
                    />
                </RatioContainer>
                <RatioContainer ratio={1.25 / 5}>
                    <SearchBar
                        handleChange={value =>
                            updateFilter(CompanyFilters.NUMBER_OF_STANDS, value)
                        }
                        value={filters[CompanyFilters.NUMBER_OF_STANDS]}
                        placeholder="Número de stands"
                    />
                </RatioContainer>
                <RatioContainer ratio={1.5 / 5}>
                    {Object.keys(filters).length > 0 ? (
                        <SmallButton
                            iconComponent={<CloseIcon />}
                            label="Limpar filtros"
                            onClick={clearAllFilters}
                        />
                    ) : null}
                </RatioContainer>
            </WrapRow>
        </SearchContainer>
    )
}

export default CompanySearch

const SearchContainer = styled(Column as any)`
    ${WrapRow} {
        > div {
            margin-bottom: 36px;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }
`
