import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

export const useHasVehicles = () => {
    const vehiclesExists = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.vehicles &&
            state.adminReducer.vehicles.length > 0
    )

    return vehiclesExists
}
