import React, { useState } from 'react'
import ReactModal from 'react-modal'
import { Column, Row } from '../../../shared/shared.styled'
import { Formik } from 'formik'
import TextInput from '../../../shared/components/text-input.component'
import PrimaryButton from '../../../shared/components/button.component'
import * as Yup from 'yup'
import { ContactIteraction } from '../../admin.interfaces'
import styled from 'styled-components'
import { Typography, Colors } from '../../../configs/styled.config'
import CloseIcon from '../../../assets/icons/close.icon'
import { SelectedIteraction } from '../contact-iteractions.component'

interface AddIteractionModalProps {
    addIteraction: (values: ContactIteraction) => void
    editIteraction: (values: SelectedIteraction) => void
    hideModal: () => void
    selectedIteraction: SelectedIteraction | undefined
}

const formikValidation = Yup.object().shape({
    description: Yup.string().required('Campo obrigatório'),
    date: Yup.string().required('Campo obrigatório'),
})

export const AddIteractionModal = ({
    addIteraction,
    editIteraction,
    hideModal,
    selectedIteraction,
}: AddIteractionModalProps) => {
    function sleep(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    const [executing, setExecuting] = useState(false)

    // Initial values
    const initialValues = selectedIteraction
        ? selectedIteraction.iteraction
        : {
              description: '',
              date: '',
          }

    async function submitIteraction(values: ContactIteraction) {
        setExecuting(true)
        if (selectedIteraction) {
            editIteraction({
                iteraction: values,
                iteractionIdx: selectedIteraction.iteractionIdx,
            })
        } else {
            addIteraction(values)
        }
        await sleep(1000)
        setExecuting(false)
    }

    return (
        <ReactModal
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: { zIndex: 1000 },
                content: { width: 500, margin: 'auto', height: 'fit-content' },
            }}
            isOpen={true}
        >
            <Column>
                <Row>
                    <StyledTitle>Nova iteração</StyledTitle>
                    <CloseIcon
                        onClick={() => hideModal()}
                        style={{
                            marginLeft: 'auto',
                            width: 18,
                            height: 18,
                            color: Colors['gold-pmauto'],
                        }}
                    />
                </Row>
                <Formik
                    initialValues={initialValues}
                    onSubmit={submitIteraction}
                    validationSchema={formikValidation}
                    validateOnChange={false}
                >
                    {({ handleChange, handleSubmit, errors, values }) => {
                        return (
                            <StyledColumn>
                                <TextInput
                                    value={values.description}
                                    onChange={handleChange}
                                    name="description"
                                    title="Descrição"
                                />
                                <Row
                                    style={{ justifyContent: 'space-between' }}
                                >
                                    <div style={{ width: '100%' }}>
                                        <TextInput
                                            value={values.date}
                                            onChange={handleChange}
                                            name="date"
                                            type="date"
                                            error={errors.date}
                                            title="Data"
                                        />
                                    </div>
                                </Row>

                                <PrimaryButton
                                    label="Guardar iteração"
                                    type="button"
                                    redTheme={true}
                                    onClick={() => handleSubmit()}
                                    disabled={executing || false}
                                />
                            </StyledColumn>
                        )
                    }}
                </Formik>
            </Column>
        </ReactModal>
    )
}

const StyledTitle = styled('h1')`
    ${Typography.H2_Regular_Left}
`

const StyledColumn = styled(Column as any)`
    padding: 24px 40px;

    > div {
        margin-bottom: 24px;
    }
`
