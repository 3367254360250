import React from 'react'
import { Row } from '../../shared/shared.styled'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { Vehicle } from '../../shared/shared.models'
import { euroFormatter, getSaleMargin } from '../../shared/shared.utils'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'

interface SoldVehiclesAnalyticsProps {
    visibleVehicles: Vehicle[]
}

interface AnalyticValues {
    salesValue: number
    margin: number
    financedValue: number
}

export const SoldVehiclesAnalytics = ({
    visibleVehicles,
}: SoldVehiclesAnalyticsProps) => {
    // Hooks initialization
    const isAdmin = useIsAdmin()

    // Local state
    const [analyticValues, setAnalyticValues] = React.useState<AnalyticValues>({
        salesValue: 0,
        margin: 0,
        financedValue: 0,
    })

    // We do every math in a single function to only iterate once over the array
    const getAnalyticValues = React.useCallback(() => {
        let salesValue = 0
        let margin = 0
        let financedValue = 0

        visibleVehicles.forEach(v => {
            const vehicleCostMargin = getSaleMargin(v)
            if (v.salePrice) {
                salesValue += v.salePrice
                margin += vehicleCostMargin
            } else {
                margin -= vehicleCostMargin
            }
            if (v.loanValue) {
                financedValue += v.loanValue
            }
        })

        setAnalyticValues({
            salesValue,
            margin,
            financedValue,
        })
    }, [visibleVehicles])

    /**
     * Effect that runs everytime the list of vehicles change
     * Used to calculate the analytic values
     */
    React.useEffect(() => {
        getAnalyticValues()
    }, [getAnalyticValues])

    return (
        <CustomRow>
            <Row>
                <RowLabel>Número de viaturas</RowLabel>
                <RowValue>{visibleVehicles.length}</RowValue>
            </Row>
            {isAdmin ? (
                <>
                    <Row>
                        <RowLabel>Valor total de vendas</RowLabel>
                        <RowValue>
                            {euroFormatter.format(analyticValues.salesValue)}
                        </RowValue>
                    </Row>
                    <Row>
                        <RowLabel>Margem Total</RowLabel>
                        <RowValue>
                            {euroFormatter.format(analyticValues.margin)}
                        </RowValue>
                    </Row>
                    <Row>
                        <RowLabel>Valor financiado total</RowLabel>
                        <RowValue>
                            {euroFormatter.format(analyticValues.financedValue)}
                        </RowValue>
                    </Row>
                </>
            ) : null}
        </CustomRow>
    )
}

const CustomRow = styled(Row as any)`
    margin-bottom: 24px;
    ${Row} {
        margin-right: 32px;
    }
`

const RowLabel = styled('span')`
    ${Typography.H3_Regular_Left}
    font-size: 18px;
    margin-right: 8px;
`

const RowValue = styled('span')`
    ${Typography['Body_Text_#1_Regular_Left']}
`
