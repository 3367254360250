import { DropdownOption } from '../shared.interface'
import {
    FuelEnum,
    CarStateEnum,
    VehicleTypeEnum,
    AvailabilityEnum,
    ShiftEnum,
    RoleEnum,
    FontEnum,
    StatusEnum,
    OriginEnum,
} from '../shared.enums'
import {
    Financial,
    LoggedUser,
    Stand,
    SimpleUser,
    Supplier,
} from '../shared.models'
import TranslationUtils from './translations.utils'

export const DropdownUtils = {
    getSuppliersDropdownOptions: (suppliers: Supplier[]): DropdownOption[] => {
        return suppliers.map(supplier => {
            return {
                key: supplier.uuid,
                label: supplier.name,
            }
        })
    },
    getLoanCompanyDropdownOptions: (
        financials: Financial[]
    ): DropdownOption[] => {
        return financials.map(financial => {
            return {
                key: financial.name,
                label: financial.name,
            }
        })
    },
    getLoanCompanyDropdownOptionsSale: (
        financials: Financial[]
    ): DropdownOption[] => {
        return financials.map(financial => {
            return {
                key: financial.financialUuid
                    ? financial.financialUuid
                    : financial.uuid,
                label: financial.name,
            }
        })
    },
    getShiftOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(ShiftEnum).map(shift => {
            switch (shift) {
                case ShiftEnum.AUTOMATIC:
                    return {
                        label: 'Automático',
                        key: shift,
                    }
                case ShiftEnum.MANUAL:
                    return {
                        label: 'Manual',
                        key: shift,
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })
        return dropdownOptions
    },
    getFuelDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(FuelEnum).map(fuel => {
            switch (fuel) {
                case FuelEnum.DIESEL:
                    return {
                        label: 'Diesel',
                        key: fuel,
                    }
                case FuelEnum.ELECTRIC:
                    return {
                        label: 'Elétrico',
                        key: fuel,
                    }
                case FuelEnum.GAS:
                    return {
                        label: 'Gás',
                        key: fuel,
                    }
                case FuelEnum.GASOLINE:
                    return {
                        label: 'Gasolina',
                        key: fuel,
                    }
                case FuelEnum.HYBRID:
                    return {
                        label: 'Híbrido',
                        key: fuel,
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })
        return dropdownOptions
    },

    getVehicleStateDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(CarStateEnum).map(state => {
            switch (state) {
                case CarStateEnum.NEW:
                    return {
                        key: state,
                        label: 'Novo',
                    }
                case CarStateEnum.USED:
                    return {
                        key: state,
                        label: 'Usado',
                    }
                case CarStateEnum.SEMI_NEW:
                    return {
                        key: state,
                        label: 'Semi-novo',
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })

        return dropdownOptions
    },
    getVehicleTypesDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(VehicleTypeEnum).map(type => {
            switch (type) {
                case VehicleTypeEnum.COMMERCIAL:
                    return {
                        key: type,
                        label: 'Comercial',
                    }
                case VehicleTypeEnum.HEAVY_GOODS:
                    return {
                        key: type,
                        label: 'Pesado Mercadorias',
                    }
                case VehicleTypeEnum.HEAVY_PASSENGERS:
                    return {
                        key: type,
                        label: 'Pesado Passageiros',
                    }
                case VehicleTypeEnum.LIGHT_PASSENGERS:
                    return {
                        key: type,
                        label: 'Ligeiro Passageiros',
                    }
                case VehicleTypeEnum.MOTORCYCLES:
                    return {
                        key: type,
                        label: 'Motociclos',
                    }
                default:
                    return {
                        key: 'Error',
                        label: 'error',
                    }
            }
        })

        return dropdownOptions
    },

    getStandLocationDropdownOptions: (stands: Stand[]): DropdownOption[] => {
        const dropdownOptions = Object.values(stands).map(stand => {
            switch (stand) {
                case stand:
                    return {
                        key: stand.uuid,
                        label: stand.name,
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })

        return dropdownOptions
    },
    getAllCompanyUsersDropdownOptions: (
        users: SimpleUser[]
    ): DropdownOption[] => {
        return users.map(user => {
            return { key: user.uuid, label: user.username }
        })
    },
    getUsersDropdownOptions: (users: LoggedUser[]): DropdownOption[] => {
        return users
            .filter(u => u.username !== 'admin')
            .map(user => {
                return { key: user.username, label: user.username }
            })
    },
    getUsersDropdownOptionsSale: (users: LoggedUser[]): DropdownOption[] => {
        return users
            .filter(u => u.username !== 'admin')
            .filter(u => u.role !== RoleEnum.MANAGER)
            .map(user => {
                return { key: user.uuid, label: user.username }
            })
    },
    getVehicleAvailabilityDropdownOptions: (): DropdownOption[] => {
        return [
            {
                key: AvailabilityEnum.READY_FOR_SALE,
                label: 'Pronto para venda',
            },
            {
                key: AvailabilityEnum.REPARING,
                label: 'Em reparação',
            },
            {
                key: AvailabilityEnum.VERIFYING,
                label: 'A verificar',
            },
        ]
    },
    getVehicleCostTypeOptions: (costTypes: string[]): DropdownOption[] => {
        const options = costTypes.map(costType => ({
            key: costType,
            label: TranslationUtils.getCostTypesTranslation(costType),
        }))

        return options
    },
    getRolesEnumDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(RoleEnum).map(role => {
            switch (role) {
                case RoleEnum.ADMIN:
                    return {
                        label: 'Administrador',
                        key: role,
                    }
                case RoleEnum.MANAGER:
                    return {
                        label: 'Manager',
                        key: role,
                    }
                case RoleEnum.SELLER:
                    return {
                        label: 'Vendedor',
                        key: role,
                    }
                case RoleEnum.CLIENT:
                    return {
                        label: 'Cliente',
                        key: role,
                    }
                case RoleEnum.EDITOR1:
                    return {
                        label: 'Editor Tipo 1',
                        key: role,
                    }
                case RoleEnum.EDITOR2:
                    return {
                        label: 'Editor Tipo 2',
                        key: role,
                    }
                case RoleEnum.EDITOR3:
                    return {
                        label: 'Editor Tipo 3',
                        key: role,
                    }
                case RoleEnum.WORKSHOP:
                    return {
                        label: 'Oficina',
                        key: role,
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })
        return dropdownOptions
    },
    getContactFontdDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(FontEnum).map(state => {
            switch (state) {
                case FontEnum.EMAIL:
                    return {
                        key: state,
                        label: 'Email',
                    }
                case FontEnum.EVENTS:
                    return {
                        key: state,
                        label: 'Eventos',
                    }
                case FontEnum.OLD_CLIENT:
                    return {
                        key: state,
                        label: 'Cliente Antigo',
                    }
                case FontEnum.OTHER:
                    return {
                        key: state,
                        label: 'Outro',
                    }

                case FontEnum.RADIO:
                    return {
                        key: state,
                        label: 'Radio',
                    }
                case FontEnum.RECOMENDATION:
                    return {
                        key: state,
                        label: 'Recomendação',
                    }
                case FontEnum.SOCIAL_NETWORK:
                    return {
                        key: state,
                        label: 'Redes Sociais',
                    }
                case FontEnum.STAND_TICKET:
                    return {
                        key: state,
                        label: 'Passagem no Stand',
                    }
                case FontEnum.OTHER_WEB_SV:
                    return {
                        key: state,
                        label: 'Outros Web (StandVirtual)',
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })

        return dropdownOptions
    },
    getContactStatusdDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(StatusEnum).map(state => {
            switch (state) {
                case StatusEnum.CLOSED:
                    return {
                        key: state,
                        label: 'Fechado',
                    }
                case StatusEnum.OPEN:
                    return {
                        key: state,
                        label: 'Aberto',
                    }
                case StatusEnum.SOLD:
                    return {
                        key: state,
                        label: 'Vendido',
                    }
                case StatusEnum.ORDERED:
                    return {
                        key: state,
                        label: 'Encomendado',
                    }
                default:
                    return {
                        label: 'Error',
                        key: 'error',
                    }
            }
        })

        return dropdownOptions
    },
    getOriginDropdownOptions: (): DropdownOption[] => {
        const dropdownOptions = Object.values(OriginEnum)
            .map(origin => {
                switch (origin) {
                    case OriginEnum.EMAIL:
                        return {
                            key: origin,
                            label: 'Email',
                        }
                    case OriginEnum.EVENTS:
                        return {
                            key: origin,
                            label: 'Eventos',
                        }
                    case OriginEnum.OLD_CUSTOMER:
                        return {
                            key: origin,
                            label: 'Cliente Antigo',
                        }
                    case OriginEnum.OTHER:
                        return {
                            key: origin,
                            label: 'Outro',
                        }
                    case OriginEnum.RADIO:
                        return {
                            key: origin,
                            label: 'Rádio',
                        }
                    case OriginEnum.RECOMMENDATION:
                        return {
                            key: origin,
                            label: 'Recomendação',
                        }
                    case OriginEnum.SOCIAL_NETWORK:
                        return {
                            key: origin,
                            label: 'Redes Sociais',
                        }
                    case OriginEnum.TEMPORARY_CUSTOMER:
                        return {
                            key: origin,
                            label: 'Visita ao Stand',
                        }
                    case OriginEnum.WEBSITE:
                        return {
                            key: origin,
                            label: 'Website',
                        }
                    case OriginEnum.OTHER_WEB_SV:
                        return {
                            key: origin,
                            label: 'Outros Web (StandVirtual)',
                        }
                    default:
                        return {
                            label: 'Error',
                            key: 'error',
                        }
                }
            })
            .sort((a, b) => (a.label > b.label ? 1 : -1))

        return dropdownOptions
    },
}
