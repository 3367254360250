import React from 'react'
import { IBaseInterface } from './stand.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container, Column } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { CreateStandRequest } from '../admin.interfaces'
import PublicStandFeatures from '../components/public-stand-features.component'
import ListVehiclesInStand from '../components/list-vehicles-stand.component'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { Vehicle } from '../../shared/shared.models'
import Loader from 'react-spinners/BounceLoader'
import { Typography, Colors } from '../../configs/styled.config'
import styled from 'styled-components'

export const StandDetailsPage = ({ stand }: IBaseInterface) => {
    // Hooks
    const isAdmin = useIsAdmin()
    const dispatch = useDispatch()
    const alert = useAlert()

    const [vehicles, setVehicles] = React.useState<Vehicle[]>([])

    // Redux state
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)

                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    // Effects
    React.useEffect(() => {
        AdminApi.methods
            .getVehiclesStand(stand.uuid)
            .finally(() => setIsLoading(false))
            .then(res => {
                const { vehicles } = res.data
                setVehicles(vehicles)
                dispatch(
                    AdminActions.methods.getVehiclesStandSuccessAction(vehicles)
                )
            })
            .catch(e => {
                alert.error(
                    'Erro a ir buscar os veículos do stand, por favor tente mais tarde'
                )
            })
    }, [dispatch, alert, stand.uuid])

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar os veículos do stand</LoadingText>
            </Column>
        )
    }

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
        }
    }

    /**
     * Calls the API endpoint to update a stand and handles the
     * loading and error states of the form
     */
    async function updateStand(
        values: CreateStandRequest,
        actions: FormikHelpers<CreateStandRequest>
    ) {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.updateStand(stand.uuid, values)
            dispatch(
                AdminActions.methods.updateStandSuccessAction(res.data.stand)
            )
            setIsEditing(false)
            alert.success('Stand gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o stand. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    /**
     * Calls the API endpoint to update a stand and put it active and handles the
     * loading and error states of the form
     */
    async function activateStand() {
        try {
            const res = await AdminApi.methods.updateStandActive(
                stand.uuid,
                stand.companyUuid
            )
            dispatch(
                AdminActions.methods.updateStandSuccessAction(res.data.stand)
            )
            setIsEditing(false)
            alert.success('Stand gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o stand. Por favor tente mais tarde'
            )
        } finally {
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(':companyId', stand.companyUuid)
            )
        }
    }

    /**
     * Calls the API endpoint to deactivate a stand and handles the
     * loading and error states of the form
     */
    async function deactivateStand() {
        try {
            const res = await AdminApi.methods.deactivateStand(
                stand.uuid,
                stand.companyUuid
            )
            dispatch(
                AdminActions.methods.deactivateStandSuccessAction(
                    res.data.stand
                )
            )
            setIsEditing(false)
            alert.success(
                'Stand desativado com successo.\nTodos os veículos deste stand ficaram sem stand associado'
            )
        } catch (e) {
            alert.error(
                'Erro ao tentar desativar o stand. Por favor tente mais tarde'
            )
        } finally {
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(':companyId', stand.companyUuid)
            )
        }
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...stand,
        }
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit And Sell vehicle buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                    />
                </>
            )
        }
        return (
            <>
                <PrimaryButton
                    label="Editar stand"
                    onClick={() => setIsEditing(true)}
                />

                <PrimaryButton
                    isLoading={isSubmitting}
                    label={stand.active ? 'Desativar Stand' : 'Ativar Stand'}
                    onClick={
                        stand.active
                            ? () => deactivateStand()
                            : () => activateStand()
                    }
                    redTheme={stand.active ? true : false}
                    greenTheme={stand.active ? false : true}
                />
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateStandRequest>
                initialValues={getInitialValues()}
                onSubmit={updateStand}
            >
                {({ values, setFieldValue, dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={`Detalhes do ${stand.name}`}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>
                        <PublicStandFeatures isEditing={isAdmin && isEditing} />
                        <ListVehiclesInStand vehicles={vehicles} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
