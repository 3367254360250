import React from 'react'
import { Container } from '../../shared/shared.styled'
import { useDispatch } from 'react-redux'
import { Sale } from '../sales.interfaces'
import { EditSaleForm } from '../forms/sale-details.form'
import { AdminApi } from '../../admin/admin.api'
import { SaleApi } from '../sale.api'
import { Formik } from 'formik'
import { useAlert } from 'react-alert'
import { AdminActions } from '../../admin/admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { AvailabilityEnum } from '../../shared/shared.enums'
import { Vehicle } from '../../shared/shared.models'

interface SaleDetailsPageProps {
    vehicle: Vehicle
}

export const SaleDetailsPage = ({ vehicle }: SaleDetailsPageProps) => {
    // Hooks
    const alert = useAlert()
    const dispatch = useDispatch()

    // API CALLS

    const deleteSale = async (saleId: string) => {
        try {
            const res = await SaleApi.methods.deleteSale(saleId)
            alert.success('Venda eliminada com sucesso')
            // Now we need to replace the vehicle with the response
            dispatch(
                AdminActions.methods.updateVehicleSuccessAction(
                    res.data.vehicle
                )
            )
            navigate(RoutesEnum.SOLD_VEHICLES)
        } catch (e) {
            alert.error('Erro ao eliminar a venda, tente mais tarde')
        }
    }

    /**
     * Endpoint call to update a sale
     * After the request, updates the redux store with the new sale and shows an alert
     * @param values Sale
     */
    const updateSale = async (values: Sale) =>
        new Promise<Sale>(async (resolve, reject) => {
            try {
                const res = await SaleApi.methods.updateSale(
                    values.uuid,
                    values
                )
                dispatch(
                    AdminActions.methods.updateSaleSuccessAction(res.data.sale)
                )
                alert.success('Informação guardada com sucesso')
                resolve(res.data.sale)
            } catch (e) {
                alert.error('Erro ao editar a venda, tente mais tarde')
                reject()
            }
        })

    const deliverVehicle = async (vehicleId: string) =>
        new Promise<void>(async resolve => {
            try {
                const res = await AdminApi.methods.updateVehicle_PATCH(
                    vehicleId,
                    {
                        availability: AvailabilityEnum.DELIVERED,
                    }
                )
                alert.success('Estado do veículo mudado para entregue!')
                // Now we need to replace the vehicle with the response
                dispatch(
                    AdminActions.methods.updateVehicleSuccessAction(
                        res.data.vehicle
                    )
                )
                navigate(RoutesEnum.SOLD_VEHICLES)
            } catch (e) {
                alert.error(
                    'Erro ao mudar o estado do veículo, tente mais tarde'
                )
            }
            resolve()
        })

    return (
        <Container>
            <Formik<Sale>
                initialValues={vehicle.sale as Sale}
                onSubmit={(values: Sale) => {}}
            >
                <EditSaleForm
                    vehicle={vehicle}
                    deleteSale={deleteSale}
                    updateSale={updateSale}
                    deliverVehicle={deliverVehicle}
                />
            </Formik>
        </Container>
    )
}
