import React from 'react'
import { TableManage } from '../../shared/components/table/table-manage.component'
import { useDispatch } from 'react-redux'
import { useAlert } from 'react-alert'
import { LoggedUser } from '../../shared/shared.models'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import { ColumnProps } from '../../shared/components/table/table.component'
import { HeaderEnumUsers } from '../../shared/components/table/table-headers.component'
import { RoutesEnum } from '../../routes.constants'
import { navigate } from 'hookrouter'

interface CompanyUsersProps {
    companyId: string
}

// const userSelector = createSelector(
//     (state: IStoreInterface) => state.adminReducer.users,
//     (companyId: CompanyUsersProps) => companyId.companyId,
//     (users, companyId) => users.find(u => u.companyUuid === companyId)
// )

const CompanyUsers = ({ companyId }: CompanyUsersProps) => {
    // Hooks initialization
    const dispatch = useDispatch()

    const alert = useAlert()

    const [users, setUsers] = React.useState<LoggedUser[]>([])

    // Local state
    const [isFetchingUsers, setIsFetchingUsers] = React.useState(false)

    // Effects
    React.useEffect(() => {
        setIsFetchingUsers(true)
        AdminApi.methods
            .getUsers(companyId)
            .finally(() => setIsFetchingUsers(false))
            .then(res => {
                const { users } = res.data
                setUsers(users)
                dispatch(AdminActions.methods.getUsersSuccessAction(users))
            })
            .catch(e => {
                alert.show(
                    'Erro a ir buscar os users, por favor tente mais tarde'
                )
            })
    }, [dispatch, alert, companyId])

    const editUser = (user: LoggedUser) => {
        navigate(RoutesEnum.GET_USER.replace(':userId', user.uuid))
    }
    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedUsers = SortUtils.sortUsers(users, header, order)
        setUsers(sortedUsers)
    }

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: HeaderEnumUsers.USERNAME,
                ratio: 3 / 10,
                label: 'Username',
                renderFunction: user => user.username,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumUsers.FIRSTNAME,
                label: 'Primeiro Nome',
                ratio: 2 / 10,
                renderFunction: user => user.firstName,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumUsers.LASTNAME,
                label: 'Último Nome',
                ratio: 2 / 10,
                renderFunction: user => user.lastName,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumUsers.ROLE,
                label: 'Cargo',
                ratio: 2 / 10,
                renderFunction: user => user.role,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumUsers.ACTIVE,
                label: 'Estado',
                ratio: 1 / 10,
                renderFunction: user => (user?.isActive ? 'Ativo' : 'Inativo'),
                onClick: onHeaderClick,
            },
        ]
    }

    return (
        <TableManage
            companyId={companyId}
            isLoadingData={isFetchingUsers}
            values={users}
            onRowClick={editUser}
            columns={getColumns()}
            type="utilizadores"
        />
    )
}

export default CompanyUsers
