import React from "react";

const AccountIcon = () => {
  return (
    <svg
      viewBox="0 0 18 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="Backoffice"
        stroke="inherit"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square"
      >
        <g
          id="Vehicles-/-Create-vehicle---Dropdowns-open"
          transform="translate(-22.000000, -971.000000)"
          stroke="inherit"
        >
          <g
            id="UI-/-Navigation-Menu"
            transform="translate(0.000000, -0.000000)"
          >
            <g id="mdi-account" transform="translate(19.000000, 968.000000)">
              <g id="Icon" transform="translate(4.000000, 4.000000)">
                <path
                  d="M8,0 C10.209139,0 12,1.790861 12,4 C12,6.209139 10.209139,8 8,8 C5.790861,8 4,6.209139 4,4 C4,1.790861 5.790861,0 8,0 Z M8,10 C12.418,10 16,11.79 16,14 L16,16 L0,16 L0,14 C0,11.79 3.582,10 8,10 Z"
                  id="Icon-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AccountIcon;
