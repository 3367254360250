import React from "react";
import styled from "styled-components";
import { Typography, Colors } from "../../configs/styled.config";
import PlusIcon from "../../assets/icons/plus.icon";
import { Column } from "../../shared/shared.styled";
import { useAlert } from "react-alert";
import Loader from "react-spinners/BounceLoader";

interface UploadVideoProps {
  handleUpload: (file: File) => Promise<void>;
  isUploading: boolean;
}

const UploadVideo = ({ isUploading, handleUpload }: UploadVideoProps) => {
  const alert = useAlert();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: After we upload the file, we receive it. We then need to update this form with the new video
    if (e.target.files) {
      try {
        await handleUpload(e.target.files[0]);
        alert.success("The video were uploaded successfully");
      } catch (e) {
        alert.error("Error uploading the video, please try again later");
      } finally {
        // In case of error or success, we clear the files from the input
        e.currentTarget.value = "";
      }
    }
  };

  return (
    <Column style={{ marginTop: 24 }}>
      <UploadVideoContainer htmlFor="uploadVideo">
        <input
          ref={inputRef}
          accept="video/mp4,video/x-m4v,video/*"
          id="uploadVideo"
          onChange={uploadFile}
          type="file"
          style={{ display: "none" }}
        />
        {isUploading ? (
          <Loader color={Colors["gold-pmauto"]} />
        ) : (
          <div>
            <IconContainer>
              <PlusIcon />
            </IconContainer>
            <UploadVideoPlaceholder>Escolher vídeo</UploadVideoPlaceholder>
          </div>
        )}
      </UploadVideoContainer>
    </Column>
  );
};

export default UploadVideo;

const UploadVideoContainer = styled("label")`
  border: dashed 1px #979797;
  background-color: #f8f8f8;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const IconContainer = styled("div")`
  width: 40px;
  height: 40px;
  margin-bottom: 24px;

  stroke: ${Colors["gold-pmauto"]};
  fill: ${Colors["gold-pmauto"]};
`;

const UploadVideoPlaceholder = styled("div")`
  ${Typography["Body_Text_#2_High_Contrast_Center"]};
`;
