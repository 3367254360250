import React from 'react'
import { Column, RatioContainer, Row } from '../../shared/shared.styled'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { Typography } from '../../configs/styled.config'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'
import { Supplier } from '../../shared/shared.models'

interface SupplierTableProps {
    supplier: Supplier
    editSupplier: (supplierIdx: number) => void
    isEditing?: boolean
}

export const SupplierTable = ({
    supplier,
    editSupplier,
    isEditing = true,
}: SupplierTableProps) => {
    const isAdmin = useIsAdmin()
    return (
        <Column>
            <div style={{ marginBottom: 16 }}>
                <Row>
                    <RatioContainer ratio={2 / 8}>
                        <HeaderText>Nome</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={3 / 8}>
                        <HeaderText>Email</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8}>
                        <HeaderText>Telemóvel</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8}>
                        <HeaderText>É frequente</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={1 / 8} />
                </Row>
                <Separator />
            </div>
            <TableRow key={`supplier-${supplier?.uuid}`}>
                <RatioContainer ratio={2 / 8}>
                    <EllipsedRowText>
                        {supplier.name ? supplier.name : '-'}
                    </EllipsedRowText>
                </RatioContainer>
                <EllipsedContainer ratio={3 / 8}>
                    <EllipsedRowText>
                        {supplier.email ? supplier.email : '-'}
                    </EllipsedRowText>
                </EllipsedContainer>
                <EllipsedContainer ratio={1 / 8}>
                    <EllipsedRowText>
                        {supplier.phone ? supplier.phone : '-'}
                    </EllipsedRowText>
                </EllipsedContainer>
                <EllipsedContainer ratio={1 / 8}>
                    <EllipsedRowText>
                        {supplier.isFrequent !== undefined
                            ? supplier.isFrequent
                                ? 'Sim'
                                : 'Não'
                            : '-'}
                    </EllipsedRowText>
                </EllipsedContainer>
                <ButtonsContainer ratio={1 / 8}>
                    {isEditing ? (
                        <EditIcon onClick={() => editSupplier(0)} />
                    ) : null}
                </ButtonsContainer>
            </TableRow>
        </Column>
    )
}

const HeaderText = styled('span')`
    ${Typography['Body_Text_#1_High_Contrast_Left']}
`

const RowText = styled('div')`
    ${Typography['Body_Text_#2_Regular_Left']}
`

const EllipsedRowText = styled(RowText as any)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 24px;
`

const EllipsedContainer = styled(RatioContainer as any)`
    text-overflow: ellipsis;
    overflow: hidden;
`

const ButtonsContainer = styled(RatioContainer as any)`
    justify-content: flex-end;

    svg {
        width: 20px;
        height: 20px;
        fill: inherit;
        cursor: pointer;
    }

    svg:not(:last-child) {
        margin-right: 16px;
    }
`

const TableRow = styled(Row as any)`
    height: 36px;

    :nth-child(odd) {
        background-color: #fafafa;
    }
`

const Separator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`
