import React from 'react'
import { IBaseInterface } from './supplier.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { CreateSupplierRequest } from '../admin.interfaces'
import SupplierFeatures from '../components/supplier-features.component'
import { RoutesEnum } from '../../routes.constants'
import { navigate } from 'hookrouter'

export const SupplierDetailsPage = ({ supplier }: IBaseInterface) => {
    // Hooks
    const isAdmin = useIsAdmin()
    const dispatch = useDispatch()
    const alert = useAlert()

    // Redux state
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)

                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
        }
    }

    /**
     * Calls the API endpoint to update a supplier and handles the
     * loading and error states of the form
     */
    async function updateSupplier(
        values: CreateSupplierRequest,
        actions: FormikHelpers<CreateSupplierRequest>
    ) {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.updateSupplier(
                supplier.uuid ?? '',
                values
            )
            dispatch(
                AdminActions.methods.updateSupplierSuccessAction(
                    res.data.supplier
                )
            )
            setIsEditing(false)
            alert.success('Fornecedor gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o fornecedor. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    async function deleteSupplier() {
        try {
            const res = await AdminApi.methods.deleteSupplier(
                supplier.uuid ?? ''
            )
            if (res.status === 200)
                dispatch(
                    AdminActions.methods.deleteSupplierSuccessAction(supplier)
                )
            setIsEditing(false)
            alert.success('Fornecedor apagado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar apagar o fornecedor. Por favor tente mais tarde'
            )
        } finally {
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(
                    ':companyId',
                    supplier.companyUuid
                )
            )
        }
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...supplier,
        }
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit And Sell vehicle buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                    />
                </>
            )
        }
        return (
            <>
                <PrimaryButton
                    label="Editar fornecedor"
                    onClick={() => setIsEditing(true)}
                />

                <PrimaryButton
                    isLoading={isSubmitting}
                    label={'Apagar fornecedor'}
                    onClick={() => deleteSupplier()}
                    redTheme={true}
                />
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateSupplierRequest>
                initialValues={getInitialValues()}
                onSubmit={updateSupplier}
            >
                {({ values, setFieldValue, dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={`Detalhes do ${supplier.name}`}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>
                        <SupplierFeatures isEditing={isAdmin && isEditing} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
