import styled from 'styled-components'
import { Typography } from '../configs/styled.config'

export const Row = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const RowTable = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 950px;
`

export const Column = styled('div')`
    display: flex;
    flex-direction: column;
`

export const Container = styled('div')`
    padding: 0px 50px;

    @media screen and (max-width: 767px) {
        padding: 0px 10px;
    }
`

export const RatioContainer = styled('div')<{ ratio: number }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: ${props => props.ratio};
`

export const WrapRow = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    align-items: flex-start;
    > div {
        margin-right: 24px;
        margin-bottom: 24px;
        align-self: center;
    }
`

export const Section = styled('div')`
    display: flex;
    flex-direction: column;
    margin-bottom: 58px;
    width: 100%;
`

export const SectionTitle = styled('h2')`
    ${Typography['H3_Regular_Left']};
    font-size: 24px;
`

export const SmallSectionTitle = styled('h2')`
    ${Typography.H3_Regular_Left};
`

export const SectionSeparator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #eef2f4;
    margin-bottom: 32px;
`

export const LockContainer = styled('div')`
    margin-left: auto;
    cursor: pointer;

    width: 24px;
    height: 24px;

    svg {
        fill: #44566c;
    }
`

export const PageTitle = styled('h1')`
    margin: 0;
    ${Typography.H1_Regular_Left};

    margin-bottom: 24px;
`

export const InfoPhrase = styled('span')`
    ${Typography['H3_Regular_Left']};
    font-size: 12px;
    margin-bottom: 8px;
`
