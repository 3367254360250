import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'

interface SmallButtonProps extends HTMLAttributes<HTMLButtonElement> {
    label: string
    type?: 'submit' | 'button'
    disabled?: boolean
    iconComponent?: React.ReactNode
}

const SmallButton = ({
    label,
    iconComponent,
    disabled = false,
    type = 'button',
    ...rest
}: SmallButtonProps) => {
    return (
        <StyledButton disabled={disabled} type={type} {...rest}>
            {iconComponent ? (
                <IconContainer>{iconComponent}</IconContainer>
            ) : null}
            <ButtonText>{label}</ButtonText>
        </StyledButton>
    )
}

export default SmallButton

const ButtonText = styled('span')`
    ${Typography['Body_Text_#3_Regular_Left']}
`

const StyledButton = styled('button')`
    border: 0;
    outline: 0;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    ${props =>
        !props.disabled &&
        `
  :hover {
    ${ButtonText} {
      ${Typography['Body_Text_#3_High Contrast_Left']}
    }
  }
`}
`

const IconContainer = styled('div')`
    width: 14px;
    height: 14px;
    margin-right: 8px;
`
