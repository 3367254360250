import React, { HTMLProps } from "react";

const TrashIcon = (props: HTMLProps<SVGSVGElement>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 17">
      <g fill="none" fillRule="evenodd">
        <path fill="inherit" fillOpacity=".4" d="M-561-185H879V839H-561z" />
        <path fill="#FFF" d="M-561-185H50V848h-611z" />
        <g stroke="inherit" strokeLinecap="round" strokeWidth="2">
          <path
            strokeLinejoin="round"
            d="M2.719 3.719l.664 10.625c.031.614.478 1.062 1.062 1.062h6.11c.586 0 1.025-.448 1.062-1.062l.664-10.625"
          />
          <path fill="#000" fillRule="nonzero" d="M1.656 3.752h11.688" />
          <path
            strokeLinejoin="round"
            d="M5.375 3.719V2.39a.795.795 0 0 1 .797-.797h2.656a.795.795 0 0 1 .797.797v1.328M7.533 5.844v7.437M5.11 5.844l.265 7.437M9.89 5.844l-.265 7.437"
          />
        </g>
      </g>
    </svg>
  );
};

export default TrashIcon;
