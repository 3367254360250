import React from 'react'
import {
    RatioContainer,
    Column,
    WrapRow,
    SectionTitle,
} from '../../shared/shared.styled'
import Checkbox from '../../shared/components/checkbox.component'
import styled from 'styled-components'
import SmallButton from '../../shared/components/small-button.component'
import CloseIcon from '../../assets/icons/close.icon'
import { FilterProps } from '../../shared/shared.interface'
import { VehicleFilters } from './vehicle-search.component'

const WaitingVehicleSearch = ({
    clearAllFilters,
    clearFilter,
    updateFilter,
    filters,
}: FilterProps) => {
    const auxUpdateFilter = (key: VehicleFilters, value: boolean) => {
        if (value) {
            updateFilter(key, value)
        } else {
            clearFilter(key)
        }
    }

    return (
        <SearchContainer>
            <WrapRow>
                <RatioContainer ratio={1 / 5}>
                    <SectionTitle style={{ fontSize: 18 }}>
                        Filtros
                    </SectionTitle>
                </RatioContainer>
                <RatioContainer ratio={1.25 / 5}>
                    <Checkbox
                        label="Em reparação"
                        onChange={e =>
                            auxUpdateFilter(
                                VehicleFilters.REPAIR_VEHICLE,
                                e.target.checked
                            )
                        }
                        checked={
                            filters[VehicleFilters.REPAIR_VEHICLE] || false
                        }
                    />
                </RatioContainer>
                <RatioContainer ratio={1.25 / 5}>
                    <Checkbox
                        label="Sem preço"
                        onChange={e =>
                            auxUpdateFilter(
                                VehicleFilters.NO_PRICE_VEHICLE,
                                e.target.checked
                            )
                        }
                        checked={
                            filters[VehicleFilters.NO_PRICE_VEHICLE] || false
                        }
                    />
                </RatioContainer>
                <RatioContainer ratio={1.5 / 5}>
                    {Object.keys(filters).length > 0 ? (
                        <SmallButton
                            iconComponent={<CloseIcon />}
                            label="Limpar filtros"
                            onClick={clearAllFilters}
                        />
                    ) : null}
                </RatioContainer>
            </WrapRow>
        </SearchContainer>
    )
}

export default WaitingVehicleSearch

const SearchContainer = styled(Column as any)`
    ${WrapRow} {
        > div {
            margin-bottom: 36px;
        }
        > div:not(:last-child) {
            margin-right: 24px;
        }
    }
`
