import React from 'react'
import { IBaseInterface } from './contact.page'
import PrimaryButton from '../../shared/components/button.component'
import { Container } from '../../shared/shared.styled'
import FixedPageTitle from '../components/fixed-page-title.component'
import { Formik, Form, FormikHelpers } from 'formik'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { CreateContactRequest } from '../admin.interfaces'

import { StatusEnum } from '../../shared/shared.enums'
import ContactUserInfo from '../components/contact-user-info.component'
import ContactManagement from '../components/contact-management.component'
import { ContactIteractionsSection } from '../components/contact-iteractions.component'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { Contact } from '../../shared/shared.models'

export const ContactDetailsPage = ({ contact, setContact }: IBaseInterface) => {
    // Hooks
    const dispatch = useDispatch()
    const alert = useAlert()
    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    setIsEditing(false)

                    hideConfirmationModal()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )
    // Local state
    const [isEditing, setIsEditing] = React.useState(false)

    function cancelEdition(formIsDirty: boolean) {
        if (formIsDirty) {
            showConfirmationModal()
            // TODO: Reset the form
        } else {
            setIsEditing(false)
        }
    }

    /**
     * Calls the API endpoint to update a contact and handles the
     * loading and error states of the form
     */
    async function updateContact(
        values: CreateContactRequest,
        actions: FormikHelpers<CreateContactRequest>
    ) {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.updateContact(
                contact.uuid,
                values
            )
            dispatch(
                AdminActions.methods.updateContactSuccessAction(
                    res.data.contact
                )
            )
            setIsEditing(false)
            alert.success('Contacto gravado com successo')
        } catch (e) {
            alert.error(
                'Erro ao tentar gravar o contacto. Por favor tente mais tarde'
            )
        } finally {
            actions.setSubmitting(false)
        }
    }

    /**
     * Helper function to get the formik initial values
     */
    function getInitialValues() {
        return {
            ...contact,
        }
    }

    async function changeContactStatus(status: string) {
        AdminApi.methods.patchContact(contact.uuid, status).then(
            res => {
                dispatch(
                    AdminActions.methods.patchContactSuccessAction(
                        res.data.contact
                    )
                )

                alert.success('Contacto gravado com successo.')
                setContact(res.data.contact)
            },
            e => {
                alert.error(
                    'Erro ao tentar gravar o contacto. Por favor tente mais tarde'
                )
            }
        )
    }

    function deleteContact(contact: Contact) {
        AdminApi.methods.deleteContact(contact.uuid).then(
            res => {
                dispatch(
                    AdminActions.methods.deleteContactSuccessAction(
                        contact.uuid
                    )
                )

                alert.success('Contacto apagado com successo.')
                navigate(RoutesEnum.CONTACTS)
            },
            e => {
                alert.error(
                    'Erro ao tentar apagar o contacto. Por favor tente mais tarde'
                )
            }
        )
    }

    /**
     * Renders the Top buttons, depending on the state of the page
     * If the page is in edition mode, it renders Cancel and Save buttons
     * If the page is in display mode, it renders Edit contact buttons
     */
    function renderButtons(formIsDirty: boolean, isSubmitting: boolean) {
        if (isEditing) {
            return (
                <>
                    <PrimaryButton
                        disabled={isSubmitting}
                        label="Cancelar"
                        onClick={() => cancelEdition(formIsDirty)}
                    />
                    <PrimaryButton
                        isLoading={isSubmitting}
                        label="Guardar informação"
                        type="submit"
                        redTheme={true}
                    />
                </>
            )
        }
        return (
            <>
                {(contact.status === StatusEnum.OPEN ||
                    contact.status === StatusEnum.ORDERED) && (
                    <PrimaryButton
                        label="Editar contato"
                        onClick={() => setIsEditing(true)}
                        redTheme={true}
                    />
                )}
                {(contact.status === StatusEnum.CLOSED ||
                    contact.status === StatusEnum.SOLD) && (
                    <PrimaryButton
                        label="Re-Abrir"
                        onClick={() => changeContactStatus(StatusEnum.OPEN)}
                        redTheme={true}
                    />
                )}
                {(contact.status === StatusEnum.OPEN ||
                    contact.status === StatusEnum.ORDERED) && (
                    <>
                        <PrimaryButton
                            label={'Fechado'}
                            onClick={() =>
                                changeContactStatus(StatusEnum.CLOSED)
                            }
                            goldTheme={true}
                        />
                        <PrimaryButton
                            label={'Vendido'}
                            onClick={() => changeContactStatus(StatusEnum.SOLD)}
                            greenTheme={true}
                        />
                    </>
                )}
                {(contact.status === StatusEnum.OPEN ||
                    contact.status === StatusEnum.ORDERED) && (
                    <PrimaryButton
                        label={'Apagar'}
                        onClick={() => deleteContact(contact)}
                        redTheme={true}
                    />
                )}
            </>
        )
    }

    return (
        <Container>
            <Formik<CreateContactRequest>
                initialValues={getInitialValues()}
                onSubmit={updateContact}
            >
                {({ values, setFieldValue, dirty, isSubmitting }) => (
                    <Form translate="yes">
                        <FixedPageTitle
                            label={`Detalhes do ${contact.name}`}
                            rightButtons={renderButtons(dirty, isSubmitting)}
                        ></FixedPageTitle>

                        <ContactUserInfo isEditing={isEditing} />

                        <ContactManagement isEditing={isEditing} />

                        <ContactIteractionsSection isEditing={isEditing} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}
