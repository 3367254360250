import React from 'react'
import { Contact, LoggedUser } from '../../shared/shared.models'
import { useRoutes } from 'hookrouter'
import { useSelector, useDispatch } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import { ContactDetailsPage } from './contact-details.page'

export interface IBaseInterface {
    contact: Contact
    setContact: (value: Contact) => void
}

const routes = {
    '/': () => (contact: Contact, setContact: (value: Contact) => void) => (
        <ContactDetailsPage contact={contact} setContact={setContact} />
    ),
}

interface ContactPageProps {
    contactId: string
}

/**
 * This page handles the routing of the ContactPage
 * @param contactId {string}
 */
export const ContactPage = ({ contactId }: ContactPageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()
    const loggedUser = useSelector(
        (state: IStoreInterface) => state.adminReducer.user as LoggedUser
    )
    const [isLoading, setIsLoading] = React.useState(true)
    const [contact, setContact] = React.useState<Contact>()

    /**
     * Gets the contact
     */
    // Effect to fetch the list of contacts
    React.useEffect(() => {
        setIsLoading(true)
        AdminApi.methods
            .getContact(contactId)
            .finally(() => {
                setIsLoading(false)
            })
            .then(
                res => {
                    setContact(res.data.contact)
                    dispatch(
                        AdminActions.methods.getContactSuccessAction(
                            res.data.contact
                        )
                    )
                },
                e => {
                    alert.error('Error fetching the contact')
                }
            )
    }, [alert, contactId, dispatch, loggedUser.companyUuid])

    /**
     * Calls the endpoint to fetch the contact information
     */
    const fetchContactData = React.useCallback(async () => {
        try {
            const res = await AdminApi.methods.getContact(contactId)
            dispatch(
                AdminActions.methods.updateContactSuccessAction(
                    res.data.contact
                )
            )
        } catch (e) {
            alert.error(
                'Houve um erro a ir buscar a informação deste contacto. Por favor tente mais tarde'
            )
        } finally {
            setIsLoading(false)
        }
    }, [contactId, dispatch, alert])

    React.useEffect(() => {
        fetchContactData()
    }, [fetchContactData])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação do contato</LoadingText>
            </Column>
        )
    } else {
        if (contact) {
            return match(contact, setContact)
        } else {
            return (
                <Column
                    style={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Loader color={Colors['gold-pmauto']} />
                    <LoadingText>
                        A carregar a informação do contato
                    </LoadingText>
                </Column>
            )
        }
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
