import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { AdminApi } from '../admin.api'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { CreateUserRequest } from '../admin.interfaces'
import UserFeatures from '../components/user-features.component'
// import ManagementSection from "../components/management-section.component";
import FixedPageTitle from '../components/fixed-page-title.component'
// import { CostsSection } from "../components/costs-section.component";
import { createUserSchema } from '../validation.schemas'
import { Container } from '../../shared/shared.styled'
import PrimaryButton from '../../shared/components/button.component'
import { useAlert } from 'react-alert'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { Company } from '../../shared/shared.models'
import { RoleEnum } from '../../shared/shared.enums'

export interface IBaseInterface {
    company: Company
}

interface CompanyPageProps {
    companyId: string
}

const CreateUserPage = ({ companyId }: CompanyPageProps) => {
    // Hooks Initialization
    const dispatch = useDispatch()
    const alert = useAlert()

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    hideConfirmationModal()
                    window.history.back()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )

    /**
     * Helper function to get the initial formikValues
     * @param user UserLogged | undefined
     */
    const getFormikInitialValues = () => {
        return {
            username: '',
            password: '',
            isActive: true,
            role: RoleEnum.ADMIN,
            companyUuid: companyId,
        }
    }

    /**
     * Calls the API endpoint to save a user, and handles the loading/error states
     * of the form
     * @param values CreateUserRequest
     * @param actions FormikHelpers<CreateUserRequest>
     */
    const addUser = async (
        values: CreateUserRequest,
        actions: FormikHelpers<CreateUserRequest>
    ) => {
        try {
            actions.setSubmitting(true)
            const res = await AdminApi.methods.createUser(values)
            dispatch(
                AdminActions.methods.createUserSuccessAction(res.data.user)
            )
            navigate(
                RoutesEnum.COMPANY_PAGE.replace(
                    ':companyId',
                    values.companyUuid
                )
            )
        } catch (e) {
            alert.error(
                'Erro ao criar o utilizador. Por favor tente mais tarde'
            )
        }
    }

    const checkIfFormIsDirtyAndClose = (isDirty: boolean) => {
        if (isDirty) {
            showConfirmationModal()
        } else {
            window.history.back()
        }
    }

    const renderButtons = (isSubmitting: boolean, isDirty: boolean) => (
        <>
            <PrimaryButton
                label="Cancelar"
                onClick={() => checkIfFormIsDirtyAndClose(isDirty)}
            />
            <PrimaryButton
                isLoading={isSubmitting}
                type="submit"
                label="Criar utilizador"
                redTheme={true}
            />
        </>
    )

    return (
        <Container>
            <Formik<CreateUserRequest>
                onSubmit={addUser}
                initialValues={getFormikInitialValues()}
                validateOnChange={false}
                validationSchema={createUserSchema()}
                enableReinitialize={true}
            >
                {({ isSubmitting, handleSubmit, dirty }) => (
                    <Form
                        translate="yes"
                        onSubmit={isSubmitting ? () => null : handleSubmit}
                    >
                        <FixedPageTitle
                            label="Criar um novo utilizador"
                            rightButtons={renderButtons(isSubmitting, dirty)}
                        />
                        <UserFeatures isEditing={true} isCreating={true} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CreateUserPage
