import React from "react";
import styled from "styled-components";
import { Typography, Colors } from "../../configs/styled.config";
import PlusIcon from "../../assets/icons/plus.icon";
import { Column } from "../../shared/shared.styled";
import { useAlert } from "react-alert";
import Loader from "react-spinners/BounceLoader";

interface UploadPhotosProps {
  handleUpload: (files: File[]) => Promise<void>;
  isUploading: boolean;
}

const UploadPhotos = ({ isUploading, handleUpload }: UploadPhotosProps) => {
  const alert = useAlert();
  /* 
    We assume that we can only upload fotos after the vehicle is created.
    With this assumption, it's easier for us because we can just upload the files directly after the user selects them.
    This means we can remove complex logic when dealing with files/images
  */

  const inputRef = React.useRef<HTMLInputElement>(null);

  const uploadFiles = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // TODO: After we upload the files, we receive an array of photos. We then need to update this form with the new array of photos
    if (e.target.files) {
      try {
        await handleUpload(Array.from(e.target.files));
        alert.success("The selected photos were uploaded successfully");
      } catch (e) {
        alert.error("Error uploading the photos, please try again later");
      } finally {
        // In case of error or success, we clear the files from the input
        e.currentTarget.value = "";
      }
    }
  };

  return (
    <Column style={{ marginTop: 24 }}>
      <UploadPhotosContainer htmlFor="upload">
        <input
          ref={inputRef}
          accept="image/*"
          id="upload"
          onChange={uploadFiles}
          type="file"
          style={{ display: "none" }}
          multiple={true}
        />
        {isUploading ? (
          <Loader color={Colors["gold-pmauto"]} />
        ) : (
          <div>
            <IconContainer>
              <PlusIcon />
            </IconContainer>
            <UploadPhotosPlaceholder>Escolher fotos</UploadPhotosPlaceholder>
          </div>
        )}
      </UploadPhotosContainer>
    </Column>
  );
};

export default UploadPhotos;

const UploadPhotosContainer = styled("label")`
  border: dashed 1px #979797;
  background-color: #f8f8f8;
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const IconContainer = styled("div")`
  width: 40px;
  height: 40px;
  margin-bottom: 24px;

  stroke: ${Colors["gold-pmauto"]};
  fill: ${Colors["gold-pmauto"]};
`;

const UploadPhotosPlaceholder = styled("div")`
  ${Typography["Body_Text_#2_High_Contrast_Center"]};
`;
