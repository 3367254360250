import React from 'react'
import { useFormikContext } from 'formik'
import { CreateUserRequest } from '../admin.interfaces'
import {
    SectionTitle,
    Column,
    Row,
    Section,
    WrapRow,
    RatioContainer,
    SectionSeparator,
} from '../../shared/shared.styled'
import TextInput from '../../shared/components/text-input.component'
import Dropdown from '../../shared/components/dropdown.component'
import styled from 'styled-components'
import { DropdownUtils } from '../../shared/utils/dropdowns.utils'
import TextPassword from '../../shared/components/text-password.component'

interface UserFeaturesProps {
    isEditing?: boolean
    isCreating: boolean
    rightElement?: React.ReactNode
}

const UserFeatures = ({
    isEditing = true,
    isCreating,
    rightElement = null,
}: UserFeaturesProps) => {
    const { values, errors, handleChange, setFieldValue } = useFormikContext<
        CreateUserRequest
    >()

    return (
        <Section>
            <Row>
                <SectionTitle>Informação do Utilizador</SectionTitle>
            </Row>
            <SectionSeparator />
            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1 / 4}>
                            <TextInput
                                inEdition={isEditing}
                                disabled={isCreating ? false : true}
                                required={true}
                                title="Username"
                                name="username"
                                placeholder="Ex: joao.sousa"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'username',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.username}
                                error={errors.username}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <TextPassword
                                inEdition={isEditing}
                                disabled={isCreating ? false : true}
                                required={true}
                                title="Password"
                                name="password"
                                placeholder="Minimo 6 caracteres"
                                onChange={handleChange}
                                onBlur={evt => {
                                    setFieldValue(
                                        'password',
                                        evt.target.value.trim()
                                    )
                                }}
                                value={values.password}
                                error={errors.password}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1 / 4}>
                            <Dropdown
                                inEdition={isEditing}
                                placeholder="Cargo do utilizador"
                                label="Cargo do utilizador"
                                options={DropdownUtils.getRolesEnumDropdownOptions()}
                                value={values.role}
                                selectValue={opt =>
                                    setFieldValue('role', opt.key)
                                }
                                error={errors.role}
                                required={true}
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
            </ResponsiveRow>
            <ResponsiveRow>
                <Column style={{ width: '100%' }}>
                    <WrapRow style={{ alignItems: 'flex-start' }}>
                        <RatioContainer ratio={1}>
                            <TextInput
                                inEdition={isEditing}
                                disabled={isCreating ? false : true}
                                title="Primeiro Nome"
                                name="firstName"
                                placeholder="Ex: Joao"
                                onChange={handleChange}
                                value={values.firstName ? values.firstName : ''}
                                error={errors.firstName}
                            />
                        </RatioContainer>
                        <RatioContainer ratio={1}>
                            <TextInput
                                inEdition={isEditing}
                                disabled={isCreating ? false : true}
                                title="Sobrenome"
                                placeholder="Ex: Sousa"
                                name="lastName"
                                onChange={handleChange}
                                value={values.lastName ? values.lastName : ''}
                                error={errors.lastName}
                            />
                        </RatioContainer>
                    </WrapRow>
                </Column>
                <div style={{ maxWidth: '50%' }}>{rightElement}</div>
            </ResponsiveRow>
        </Section>
    )
}

export default UserFeatures

const ResponsiveRow = styled(Row as any)`
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
    @media screen and (max-width: 1025px) {
        flex-direction: column-reverse;
        justify-content: center;
        > ${Column} {
            width: 100% !important;
        }

        img {
            margin: auto;
            margin-bottom: 48px;
        }
    }
`
