import React from 'react'
import { Row } from '../../shared/shared.styled'
import { Contact } from '../../shared/shared.models'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'
import { StatusEnum } from '../../shared/shared.enums'

interface ContactAnalyticsProps {
    visibleContacts: Contact[]
    allContacts: Contact[]
}

interface AnalyticValues {
    numberVisits: number
    numberOpen: number
}

export const ContactAnalytics = ({
    visibleContacts,
    allContacts,
}: ContactAnalyticsProps) => {
    // Hooks initialization

    // Local state
    const [analyticValues, setAnalyticValues] = React.useState<AnalyticValues>({
        numberVisits: 0,
        numberOpen: 0,
    })

    // We do every math in a single function to only iterate once over the array
    const getAnalyticValues = React.useCallback(() => {
        let numberVisits = visibleContacts?.length
        let numberOpen = 0

        visibleContacts.forEach(v => {
            if (v.status === StatusEnum.OPEN) {
                numberOpen += 1
            }
        })

        setAnalyticValues({
            numberVisits,
            numberOpen,
        })
    }, [visibleContacts])

    /**
     * Effect that runs everytime the list of vehicles change
     * Used to calculate the analytic values
     */
    React.useEffect(() => {
        getAnalyticValues()
    }, [getAnalyticValues])

    return (
        <CustomRow>
            <Row>
                <RowLabel>Número de visitas</RowLabel>
                <RowValue>{analyticValues.numberVisits}</RowValue>
            </Row>
            <Row>
                <RowLabel>Número de contatos abertos</RowLabel>
                <RowValue>{analyticValues.numberOpen}</RowValue>
            </Row>
        </CustomRow>
    )
}

const CustomRow = styled(Row as any)`
    margin-bottom: 24px;
    ${Row} {
        margin-right: 32px;
    }
`

const RowLabel = styled('span')`
    ${Typography.H3_Regular_Left}
    font-size: 18px;
    margin-right: 8px;
`

const RowValue = styled('span')`
    ${Typography['Body_Text_#1_Regular_Left']}
`
