import React from 'react'
import { useRoutes } from 'hookrouter'
import { useDispatch } from 'react-redux'
import { AdminApi } from '../admin.api'
import Loader from 'react-spinners/DotLoader'
import { AdminActions } from '../admin.store'
import { useAlert } from 'react-alert'
import { Column } from '../../shared/shared.styled'
import styled from 'styled-components'
import { Typography, Colors } from '../../configs/styled.config'
import { Supplier } from '../../shared/shared.models'
import { SupplierDetailsPage } from './supplier-details.page'

export interface IBaseInterface {
    supplier: Supplier
}

const routes = {
    '/': () => (supplier: Supplier) => (
        <SupplierDetailsPage supplier={supplier} />
    ),
}

interface SupplierPageProps {
    supplierId: string
}

/**
 * This page handles the routing of the SupplierPage
 * @param supplierId {string}
 */
export const SupplierPage = ({ supplierId }: SupplierPageProps) => {
    // Hooks
    const match = useRoutes(routes)
    const dispatch = useDispatch()
    const alert = useAlert()

    const [supplier, setSupplier] = React.useState<Supplier>()
    const [isLoading, setIsLoading] = React.useState(true)

    /**
     * Calls the endpoint to fetch the supplier information
     */
    const fetchSupplierData = React.useCallback(async () => {
        try {
            const res = await AdminApi.methods.getSupplier(supplierId)
            dispatch(
                AdminActions.methods.updateSupplierSuccessAction(
                    res.data.supplier
                )
            )
            setSupplier(res.data.supplier)
        } catch (e) {
            alert.error(
                'Houve um erro a ir buscar a informação deste fornecedor. Por favor tente mais tarde'
            )
        } finally {
            setIsLoading(false)
        }
    }, [supplierId, dispatch, alert])

    React.useEffect(() => {
        fetchSupplierData()
    }, [fetchSupplierData])

    if (!match) {
        throw new Error('Page not found')
    }

    if (isLoading) {
        return (
            <Column
                style={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Loader color={Colors['gold-pmauto']} />
                <LoadingText>A carregar a informação do fornecedor</LoadingText>
            </Column>
        )
    }

    if (!supplier) {
        throw new Error('Este fornecedor não existe')
    } else {
        return match(supplier)
    }
}

const LoadingText = styled('span')`
    margin-top: 24px;
    ${Typography['Body_Text_#1_Regular_Center']}
`
