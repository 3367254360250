import React from 'react'
import { CustomNotification } from '../shared.models'
import styled from 'styled-components'
import { Row, Column } from '../shared.styled'
import { getNotificationDescription } from '../shared.utils'
import { Typography, Colors } from '../../configs/styled.config'
import TrashIcon from '../../assets/icons/trash.icon'
import TranslationUtils from '../utils/translations.utils'

interface NotificationRowProps {
    notif: CustomNotification
    onNotificationClick: (notif: CustomNotification) => void
    onNotificationDelete: (notif: CustomNotification) => void
    onToggleNotification: (notif: CustomNotification) => void
}

export const NotificationRow = ({
    notif,
    onNotificationClick,
    onToggleNotification,
    onNotificationDelete,
}: NotificationRowProps) => {
    return (
        <Container onClick={() => onNotificationClick(notif)} key={notif.uuid}>
            <Column style={{ maxWidth: '85%' }}>
                <NotificationType isRead={notif.isRead}>
                    {TranslationUtils.getNotificationTypeTranslation(
                        notif.notificationType
                    )}
                </NotificationType>
                <NotificationDescription>
                    {getNotificationDescription(notif)}
                </NotificationDescription>
            </Column>
            <Row style={{ marginLeft: 'auto' }}>
                <IconContainer
                    onClick={e => e.stopPropagation()}
                    style={{ marginRight: 12 }}
                >
                    <ReadNotifButton
                        isRead={notif.isRead}
                        onClick={() => onToggleNotification(notif)}
                    />
                </IconContainer>
                <IconContainer
                    onClick={e => {
                        onNotificationDelete(notif)
                        e.stopPropagation()
                    }}
                >
                    <TrashIcon style={{ stroke: Colors['gold-pmauto'] }} />
                </IconContainer>
            </Row>
        </Container>
    )
}

const Container = styled(Row as any)`
    margin-bottom: 16px;
    cursor: pointer;
`

const IconContainer = styled('div')`
    width: 16px;
    height: 16px;
`

const ReadNotifButton = styled('div')<{ isRead: boolean }>`
    width: 16px;
    height: 16px;

    border-radius: 8px;
    background-color: ${props => (props.isRead ? '#e0e0e0' : '#ec1c24')};

    :hover {
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5);
    }
`

const NotificationType = styled('span')<{ isRead: boolean }>`
    ${props =>
        props.isRead
            ? Typography['Body_Text_#1_Regular_Left']
            : Typography['Body_Text_#1_High_Contrast_Left']}
    margin-bottom: 8px;
`

const NotificationDescription = styled('span')`
    ${Typography['Body_Text_#2_Low_Contrast_Left']};
`
