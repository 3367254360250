import React from 'react'
import {
    Column,
    RatioContainer,
    WrapRow,
    Row,
} from '../../shared/shared.styled'
import { useIsAdmin } from '../../shared/hooks/use-is-admin.hook'
import { Typography, Colors } from '../../configs/styled.config'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import styled from 'styled-components'
import { CompanyFinancial } from '../../shared/shared.models'

interface FinancialsTableProps {
    financials: CompanyFinancial[]
    editFinancial: (costIdx: number) => void
    deleteFinancial: (costIdx: number) => void
    isEditing?: boolean
}

export const FinancialsTable = ({
    financials,
    editFinancial,
    deleteFinancial,
    isEditing = true,
}: FinancialsTableProps) => {
    const isAdmin = useIsAdmin()

    // const getAvailableFinancials = () => {
    //   const financials = Fina
    //   previousCosts.push(values);

    //   formikProps.setFieldValue("costs", previousCosts);
    //   hideModal();
    // };
    return (
        <Column>
            <div style={{ marginBottom: 16 }}>
                <WrapRow>
                    <RatioContainer ratio={5 / 8}>
                        <HeaderText>Financeira</HeaderText>
                    </RatioContainer>
                    <RatioContainer ratio={2 / 8}>
                        <HeaderText>Código</HeaderText>
                    </RatioContainer>
                </WrapRow>
                <Separator />
            </div>
            {financials.map((financial, idx) => (
                <TableRow key={`financial-${idx}`}>
                    <EllipsedContainer ratio={4 / 8}>
                        <EllipsedRowText>
                            {financial.name ? financial.name : '-'}
                        </EllipsedRowText>
                    </EllipsedContainer>
                    <RatioContainer ratio={3 / 8}>
                        <RowText>
                            {financial.code ? financial.code : '-'}
                        </RowText>
                    </RatioContainer>

                    <ButtonsContainer ratio={1 / 8}>
                        {isAdmin && isEditing ? (
                            <>
                                <EditIcon onClick={() => editFinancial(idx)} />
                                <DeleteForeverIcon
                                    onClick={() => deleteFinancial(idx)}
                                    style={{
                                        fill: Colors['gold-pmauto'],
                                    }}
                                />
                            </>
                        ) : null}
                    </ButtonsContainer>
                </TableRow>
            ))}
        </Column>
    )
}

const HeaderText = styled('span')`
    ${Typography['Body_Text_#1_High_Contrast_Left']}
`

const RowText = styled('div')`
    ${Typography['Body_Text_#2_Regular_Left']}
`

const EllipsedRowText = styled(RowText as any)`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 24px;
`

const EllipsedContainer = styled(RatioContainer as any)`
    text-overflow: ellipsis;
    overflow: hidden;
`

const ButtonsContainer = styled(RatioContainer as any)`
    justify-content: flex-end;

    svg {
        width: 20px;
        height: 20px;
        fill: inherit;
        cursor: pointer;
    }

    svg:not(:last-child) {
        margin-right: 16px;
    }
`

const TableRow = styled(Row as any)`
    height: 36px;

    :nth-child(odd) {
        background-color: #fafafa;
    }
`

const Separator = styled('div')`
    width: 100%;
    height: 1px;
    background-color: #ebebeb;
`
