import React, { SVGProps } from "react";

const CloseIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <path fill="inherit" fillOpacity=".4" d="M-550-52H890V972H-550z" />
        <path fill="inherit" d="M-550-52H61V981h-611z" />
        <path
          fill="#333"
          d="M17.485.515a1.44 1.44 0 0 1 0 2.036L11.036 9l6.45 6.449a1.44 1.44 0 0 1-2.037 2.036L9 11.036l-6.449 6.45a1.44 1.44 0 0 1-2.036-2.037l6.448-6.45L.515 2.551A1.44 1.44 0 0 1 2.55.515L9 6.963 15.45.515a1.44 1.44 0 0 1 2.036 0z"
        />
      </g>
    </svg>
  );
};

export default CloseIcon;
