import React from 'react'
import { useRoutes } from 'hookrouter'
import LoginPage from './admin/pages/login.page'
import { RoutesEnum } from './routes.constants'

const unAuthenticatedRoutes = {
    [RoutesEnum.HOME]: () => <LoginPage />, // FIXME: Resolve this when we have a homepage
    [RoutesEnum.LOGIN]: () => <LoginPage />,
}

const PublicRoutes = () => {
    const routeResult = useRoutes(unAuthenticatedRoutes)
    return <div>{routeResult || '404 not found'}</div>
}

export default PublicRoutes
