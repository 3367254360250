import React, { useState } from 'react'
import {
    Section,
    SectionTitle,
    WrapRow,
    RatioContainer,
} from '../../../../shared/shared.styled'
import { useFormikContext } from 'formik'
import { Sale } from '../../../sales.interfaces'
import PdfGeneratorButton from '../../../../shared/components/button2.component'
import { AdminApi } from '../../../../admin/admin.api'
import { useAlert } from 'react-alert'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../../../../admin/admin.store'
import { Vehicle } from '../../../../shared/shared.models'

interface DocumentsSectionProps {
    vehicle: Vehicle
}

export const DocumentsSection = ({ vehicle }: DocumentsSectionProps) => {
    const { values } = useFormikContext<Sale>()

    const alert = useAlert()
    const dispatch = useDispatch()

    const downloadPDF = async (typePDF: string) =>
        new Promise<Blob>(async () => {
            try {
                const response =
                    typePDF === 'circulation'
                        ? await AdminApi.methods.getPDFCirculationAuthorization(
                              values?.uuid
                          )
                        : typePDF === 'contract'
                        ? await AdminApi.methods.getPDFContract(values?.uuid)
                        : typePDF === 'warranty'
                        ? await AdminApi.methods.getPDFWarranty(values?.uuid)
                        : typePDF === 'material'
                        ? await AdminApi.methods.getPDFMaterial(values?.uuid)
                        : typePDF === 'fines'
                        ? await AdminApi.methods.getPDFFines(values?.uuid)
                        : typePDF === 'privacy'
                        ? await AdminApi.methods.getPDFPrivacy(values?.uuid)
                        : typePDF === 'retake'
                        ? await AdminApi.methods.getPDFRetake(values?.uuid)
                        : await AdminApi.methods.getPDFContract(values?.uuid)

                alert.success('O PDF será transferido automaticamente')
                // Create a link element to download the PDF
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(response.data)

                if (typePDF === 'circulation') {
                    link.download = `${vehicle?.licensePlate} - Autorização de Circulação`

                    dispatch(
                        AdminActions.methods.getPDFCirculationAuthorization(
                            response.data
                        )
                    )
                } else if (typePDF === 'contract') {
                    link.download = `${vehicle?.licensePlate} - Contrato de Venda`

                    dispatch(AdminActions.methods.getPDFContract(response.data))
                } else if (typePDF === 'warranty') {
                    link.download = `${vehicle?.licensePlate} - Garantia`
                    dispatch(AdminActions.methods.getPDFWarranty(response.data))
                } else if (typePDF === 'material') {
                    link.download = `${vehicle?.licensePlate} - Material`
                    dispatch(AdminActions.methods.getPDFMaterial(response.data))
                } else if (typePDF === 'fines') {
                    link.download = `${vehicle?.licensePlate} - Multas`
                    dispatch(AdminActions.methods.getPDFFines(response.data))
                } else if (typePDF === 'privacy') {
                    link.download = `${vehicle?.licensePlate} - Política de Privacidade`

                    dispatch(AdminActions.methods.getPDFPrivacy(response.data))
                } else if (typePDF === 'retake') {
                    link.download = `${vehicle?.licensePlate} - Retoma`
                    dispatch(AdminActions.methods.getPDFRetake(response.data))
                }

                // Append the link to the document and trigger the click event
                document.body.appendChild(link)
                link.click()

                setTimeout(() => {
                    document.body.removeChild(link)
                }, 1000)
            } catch (error) {
                console.error('Error generating PDF:', error)
            }
        })

    return (
        <Section>
            <SectionTitle>Documentos</SectionTitle>
            <WrapRow>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('circulation')}
                        title={'Autorização de circulação'}
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('contract')}
                        title={'Contrato'}
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('warranty')}
                        title={'Garantia'}
                    />
                </RatioContainer>
            </WrapRow>
            <WrapRow>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('material')}
                        title={'Material'}
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('fines')}
                        title={'Multas'}
                    />
                </RatioContainer>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('privacy')}
                        title={'Privacidade'}
                    />
                </RatioContainer>
            </WrapRow>
            <WrapRow>
                <RatioContainer ratio={1 / 3}>
                    <PdfGeneratorButton
                        icon={'/icons/pdf.png'}
                        action={() => downloadPDF('retake')}
                        title={'Retoma'}
                    />
                </RatioContainer>
            </WrapRow>
        </Section>
    )
}
