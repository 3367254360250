import React from 'react'
import { Row } from '../shared.styled'
import produce from 'immer'
import styled from 'styled-components'
import { Colors, Typography } from '../../configs/styled.config'

export interface MinMaxValues {
    min?: number
    max?: number
}

interface MinMaxFilterProps {
    handleChangeCb: (values: MinMaxValues) => void
    values: MinMaxValues
}

const MinMaxFilter = ({ handleChangeCb, values }: MinMaxFilterProps) => {
    const _changeValues = (type: string, value: any) => {
        const numberValue = !value ? undefined : Number(value)
        const newVals = produce(values, draft => {
            if (type === 'min') {
                draft.min = numberValue
            } else {
                draft.max = numberValue
            }
        })
        handleChangeCb(newVals)
    }

    return (
        <Row>
            <StyledInput
                value={values.min ? values.min : ''}
                type="number"
                placeholder="Preço minimo"
                onChange={e => _changeValues('min', e.target.value)}
            />
            <Separator>-</Separator>
            <StyledInput
                value={values.max ? values.max : ''}
                type="number"
                placeholder="Preço máximo"
                onChange={e => _changeValues('max', e.target.value)}
            />
        </Row>
    )
}

export default MinMaxFilter

const StyledInput = styled('input')`
    font-family: Merriweather-Light;
    border: 0;
    height: 36px;
    outline: 0;
    border-bottom: 1px solid ${Colors['light-grey-blue']};
    font-size: 16px;
    ${Typography['Body_Text_#2_Regular_Left']};
    ::placeholder {
        color: #8996a6;
    }
`

const Separator = styled('span')`
    margin: 0px 16px;
`
