import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'
import { RoleEnum } from '../shared.enums'

export const useIsSeller = () => {
    const isSeller = useSelector(
        (state: IStoreInterface) =>
            state.adminReducer.user &&
            state.adminReducer.user.role === RoleEnum.SELLER
    )

    return isSeller
}
