import { AxiosInstance } from "../configs/axios.config";
import { AxiosPromise } from "axios";
import { SaleRequest, Sale } from "./sales.interfaces";
import { Vehicle } from "../shared/shared.models";

export const SaleApi = {
  routes: {
    sales: "/sales/"
  },
  methods: {
    updateSale: (
      saleId: string,
      data: SaleRequest
    ): AxiosPromise<{ sale: Sale }> => {
      const url = `${SaleApi.routes.sales}${saleId}`;
      return AxiosInstance.put(url, data);
    },
    deleteSale: (saleId: string): AxiosPromise<{ vehicle: Vehicle }> => {
      const url = `${SaleApi.routes.sales}${saleId}`;
      return AxiosInstance.delete(url);
    }
  }
};
