import React from 'react'
import { Column, Row, SectionTitle, Section } from '../../shared/shared.styled'
import { useFormikContext } from 'formik'
import SmallButton from '../../shared/components/small-button.component'
import PlusIcon from '../../assets/icons/plus.icon'
import { Colors, Typography } from '../../configs/styled.config'
import { CreateContactRequest, ContactIteraction } from '../admin.interfaces'
import { useModal } from 'react-modal-hook'
import styled from 'styled-components'
import { AddIteractionModal } from './modals/add-iteraction.modal'
import { ContactIteractionTable } from './contact-iteractions-table.component'

export interface SelectedIteraction {
    iteraction: ContactIteraction
    iteractionIdx: number
}

interface ContactIteractionsSectionProps {
    isEditing?: boolean
}

export const ContactIteractionsSection = ({
    isEditing = true,
}: ContactIteractionsSectionProps) => {
    const formikProps = useFormikContext<CreateContactRequest>()

    const [selectedIteraction, setSelectedIteraction] = React.useState<
        SelectedIteraction | undefined
    >(undefined)

    const [showModal, hideModal] = useModal(
        () => (
            <AddIteractionModal
                selectedIteraction={selectedIteraction}
                hideModal={hideModal}
                addIteraction={addIteraction}
                editIteraction={editIteraction}
            />
        ),
        [selectedIteraction]
    )

    /**
     * Adds a iteraction to our formik values
     * @param values {ContactIteraction}
     */
    const addIteraction = (values: ContactIteraction) => {
        const previousIteractions = formikProps.values.iteractions || []
        previousIteractions.push(values)

        formikProps.setFieldValue('iteractions', previousIteractions)
        hideModal()
    }

    /**
     * Deletes a iteraction from our formik values
     * @param iteractionIdx {number}
     */
    const deleteIteraction = (iteractionIdx: number) => {
        const { iteractions, deletedIteractions } = formikProps.values
        let newDeletedIteractions = deletedIteractions
        if (iteractions) {
            const deletedIteraction = iteractions.splice(iteractionIdx, 1)
            // If we're in edition mode, we need to check if we need to persist this deletion on the backend
            // We only want to delete the costs that are already save in the backend
            if (deletedIteraction[0].uuid) {
                if (newDeletedIteractions) {
                    newDeletedIteractions.push(deletedIteraction[0])
                } else {
                    newDeletedIteractions = []
                    newDeletedIteractions.push(deletedIteraction[0])
                }
                formikProps.setFieldValue(
                    'deletedIteractions',
                    newDeletedIteractions
                )
            }
            // We update our inmemory costs
            formikProps.setFieldValue('iteractions', iteractions)
        }
    }

    /**
     * Replaces a iteraction with the new values
     * @param obj selectedIteraction
     */
    const editIteraction = (obj: SelectedIteraction) => {
        const previousIteraction = formikProps.values.iteractions
        if (previousIteraction) {
            previousIteraction.splice(obj.iteractionIdx, 1, obj.iteraction)
            formikProps.setFieldValue('iteractions', previousIteraction)
        }
        setSelectedIteraction(undefined)
        hideModal()
    }

    const showEditIteractionModal = (iteractionIdx: number) => {
        const { iteractions } = formikProps.values
        if (iteractions) {
            const iteraction = iteractions[iteractionIdx]
            setSelectedIteraction({ iteraction, iteractionIdx })
            showModal()
        }
    }

    return (
        <Section>
            <SectionTitle>
                <Row>
                    Iterações detalhadas
                    <Row style={{ marginLeft: 'auto' }}>
                        <SmallButton
                            disabled={!isEditing}
                            iconComponent={
                                <PlusIcon
                                    style={{
                                        fill: isEditing
                                            ? Colors['gold-pmauto']
                                            : '#d8d8d8',
                                    }}
                                />
                            }
                            label="Adicionar uma nova iteração"
                            onClick={() => showModal()}
                        />
                    </Row>
                </Row>
            </SectionTitle>
            <Column style={{ marginTop: 16 }}>
                {formikProps.values.iteractions &&
                formikProps.values.iteractions.length > 0 ? (
                    <ContactIteractionTable
                        editIteraction={showEditIteractionModal}
                        deleteIteraction={deleteIteraction}
                        iteractions={formikProps.values.iteractions}
                        isEditing={isEditing}
                    />
                ) : (
                    <StyledText>Não existem iterações.</StyledText>
                )}
            </Column>
        </Section>
    )
}

const StyledText = styled('span')`
    align-self: center;
    ${Typography.H1_Regular_Center};
    font-size: 20px;
    margin-top: 24px;
`

// const TotalText = styled('span')`
//     ${Typography['Body_Text_#1_High_Contrast_Right']};
//     margin-right: 8px;
// `

// const ValueText = styled('span')`
//     ${Typography['Body_Text_#1_Regular_Left']};
// `
