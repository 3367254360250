import React from 'react'
import { TableManage } from '../../shared/components/table/table-manage.component'
import { Stand } from '../../shared/shared.models'
import { SortOrderEnum, SortUtils } from '../../shared/sort.utils'
import { ColumnProps } from '../../shared/components/table/table.component'
import { HeaderEnumStand } from '../../shared/components/table/table-headers.component'
import { RoutesEnum } from '../../routes.constants'
import { navigate } from 'hookrouter'

interface CompanyStandsProps {
    stands: Stand[] | undefined
    companyId: string
}

const CompanyStands = ({ stands, companyId }: CompanyStandsProps) => {
    // Hooks initialization
    //const dispatch = useDispatch()

    //const alert = useAlert()

    const [visibleStands, setVisibleStands] = React.useState(
        stands ? stands : []
    )

    // Local state
    //const [isFetchingStands, setIsFetchingStands] = React.useState(false)

    const editStand = (stand: Stand) => {
        navigate(RoutesEnum.GET_STAND.replace(':standId', stand.uuid))
    }
    const onHeaderClick = (header: string, order: SortOrderEnum) => {
        const sortedStands = SortUtils.sortStands(visibleStands, header, order)
        setVisibleStands(sortedStands)
    }

    const getColumns = (): ColumnProps[] => {
        return [
            {
                key: HeaderEnumStand.NAME,
                ratio: 2 / 12,
                label: 'Nome',
                renderFunction: stand => stand.name,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumStand.LOCATION,
                label: 'Localização',
                ratio: 3 / 12,
                renderFunction: stand => stand.location,
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumStand.ON_SALE,
                label: 'Nº de Carros em Venda',
                ratio: 2 / 12,
                renderFunction: stand =>
                    stand.active ? stand.vehiclesLengthInSale : '---',
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumStand.SOLD,
                label: 'Nº de Carros Vendidos',
                ratio: 2 / 12,
                renderFunction: stand =>
                    stand.active ? stand.vehiclesLengthSold : '---',
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumStand.OTHER,
                label: 'Nº de Carros em Espera/Reparar',
                ratio: 2 / 12,
                renderFunction: stand =>
                    stand.active ? stand.vehiclesLengthOthers : '---',
                onClick: onHeaderClick,
            },
            {
                key: HeaderEnumStand.ACTIVE,
                label: 'Estado',
                ratio: 1 / 12,
                renderFunction: stand => (stand.active ? 'Ativo' : 'Inativo'),
                onClick: onHeaderClick,
            },
        ]
    }

    return (
        <TableManage
            companyId={companyId}
            isLoadingData={false}
            values={visibleStands}
            onRowClick={editStand}
            columns={getColumns()}
            type="stands"
        />
    )
}

export default CompanyStands
