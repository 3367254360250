import React from 'react'
import styled from 'styled-components'
import PrimaryButton from './../../shared/components/button.component'
import TextInput from '../../shared/components/text-input.component'
import { Formik, FormikHelpers, FormikProps } from 'formik'
import * as Yup from 'yup'
import { LoginRequest } from './../admin.interfaces'
import { AdminApi } from '../admin.api'
import { AdminActions } from '../admin.store'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from 'hookrouter'
import { AxiosInstance } from '../../configs/axios.config'
import { RoutesEnum } from '../../routes.constants'
import { IStoreInterface } from '../../configs/store.config'
import { Colors } from '../../configs/styled.config'

const validationSchema = Yup.object().shape({
    username: Yup.string().required(),
    password: Yup.string().required(),
})

const LoginPage = () => {
    const dispatch = useDispatch()
    const token = useSelector(
        (state: IStoreInterface) => state.adminReducer.token
    )

    const [error, setError] = React.useState('')

    if (token) {
        navigate(RoutesEnum.VEHICLES_LIST, true)
    }

    const _handleSubmit = async (
        values: LoginRequest,
        actions: FormikHelpers<LoginRequest>
    ) => {
        actions.setSubmitting(true)
        try {
            const res = await AdminApi.methods.login(values)
            const { user, token, company } = res.data
            AxiosInstance.defaults.headers.common[
                'Authorization'
            ] = `Bearer ${token}`
            dispatch(
                AdminActions.methods.loginSuccessAction(user, token, company)
            )
            if (user.isSuperUser) navigate(RoutesEnum.COMPANIES_LIST)
            else navigate(RoutesEnum.VEHICLES_LIST)
        } catch (e) {
            setError('Erro ao autenticar, por favor tente mais tarde')
        }
    }

    return (
        <LoginContainer>
            <LoginCard>
                {/* <Logo src="/images/logo_digiauto.svg" alt="logo" /> */}
                <Image src="/images/digiauto_name.png" />
                <TitleLogin>
                    Plataforma de Gestão de Stands Automóveis
                </TitleLogin>
                <Description>
                    Insira os seus dados para poder aceder à sua área privada
                </Description>
                <Formik
                    initialValues={{ username: '', password: '' }}
                    onSubmit={_handleSubmit}
                    validationSchema={validationSchema}
                    validateOnChange={false}
                >
                    {({
                        handleSubmit,
                        values,
                        handleChange,
                        errors,
                        isSubmitting,
                    }: FormikProps<LoginRequest>) => (
                        <LoginForm onSubmit={handleSubmit}>
                            <TextInput
                                onChange={handleChange}
                                name="username"
                                value={values.username}
                                containerStyle={{ marginBottom: 24 }}
                                title="Utilizador"
                                placeholder="Insira o seu email"
                                error={errors.username}
                            />
                            <TextInput
                                type="password"
                                onChange={handleChange}
                                name="password"
                                value={values.password}
                                containerStyle={{ marginBottom: 50 }}
                                title="Password"
                                placeholder="Insira a sua password"
                                error={errors.password}
                            />

                            <PrimaryButton
                                type="submit"
                                redTheme={true}
                                isLoading={isSubmitting}
                                style={{ width: '100%' }}
                                label="Login"
                            />
                            {!!error ? <LoginError>{error}</LoginError> : ''}
                        </LoginForm>
                    )}
                </Formik>
            </LoginCard>
        </LoginContainer>
    )
}

export default LoginPage

const Image = styled('img')`
    width: 100%;
    margin-bottom: 24px;
`

const Description = styled('div')`
    font-family: Merriweather-Light;
    font-size: 12px;
    text-align: center;
    margin: 0 16px 16px 16px;
    color: ${Colors.dark};
`

const TitleLogin = styled('div')`
    font-family: Merriweather-Light;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    margin: 0 0 16px 0;
    color: ${Colors.dark};
`

const LoginContainer = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/images/background-car-dealer.jpg');
    background-size: cover;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

const LoginForm = styled('form')`
    width: 100%;
`

const LoginCard = styled('div')`
    background-color: white;
    border-radius: 10px;
    padding: 36px 24px;
    width: 360px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const LoginError = styled('div')`
    margin-top: 24px;
    color: ${Colors['alive-red']};
    text-align: center;
`

// const Logo = styled("img")`
//   width: unset;
//   max-height: 10vw;
// `;
