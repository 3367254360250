import React from 'react'
import {
    Row,
    Column,
    SectionTitle,
    SectionSeparator,
} from '../../shared/shared.styled'
import Checkbox from '../../shared/components/checkbox.component'
import styled from 'styled-components'
import { Typography } from '../../configs/styled.config'
import { useSelector } from 'react-redux'
import { IStoreInterface } from '../../configs/store.config'

interface CreateVehicleExtrasProps {
    handleChange: (value: string) => void
    selectedExtras: string[]
    isEditing?: boolean
}

const CreateVehicleExtras = ({
    handleChange,
    selectedExtras,
    isEditing = true,
}: CreateVehicleExtrasProps) => {
    const categories = useSelector(
        (state: IStoreInterface) => state.adminReducer.categories
    )

    const isSelected = (extra: string) => {
        return selectedExtras.includes(extra)
    }

    return (
        <>
            <Row>
                <SectionTitle>Extras</SectionTitle>
            </Row>
            <SectionSeparator />
            <Row style={{ alignItems: 'flex-start', flexWrap: 'wrap' }}>
                {categories.map(cat => (
                    <Column
                        key={`cat-${cat.label}`}
                        style={{ marginRight: 60, marginBottom: 24 }}
                    >
                        <CategoryLabel>{cat.label}</CategoryLabel>
                        <ExtrasColumn isEditing={isEditing}>
                            {cat.extras.map(extra => (
                                <Checkbox
                                    key={`extra-${extra.description}`}
                                    checked={isSelected(extra.description)}
                                    disabled={!isEditing}
                                    onChange={e => handleChange(e.target.value)}
                                    value={extra.description}
                                    label={extra.label}
                                />
                            ))}
                        </ExtrasColumn>
                    </Column>
                ))}
            </Row>
        </>
    )
}

export default CreateVehicleExtras

const CategoryLabel = styled('span')`
    ${Typography.H4_High_contrast_Left}
    margin-bottom: 16px;
    text-align: left;
    cursor: pointer;
`

const ExtrasColumn = styled(Column as any)<{ isEditing: boolean }>`
    > ${Row} {
        margin-bottom: 8px;
    }
`
