import React from 'react'
import { Column, Row } from '../../shared.styled'
import ReactModal from 'react-modal'
import PrimaryButton from '../button.component'
import styled from 'styled-components'
import { Typography } from '../../../configs/styled.config'

export interface ConfirmationModalProps {
    title?: string
    description?: string
    onCancel: () => void
    onClick: () => void
}

export const ConfirmationModal = ({
    title = 'Cancelar acção',
    description = 'Tem a certeza que quer sair?',
    onCancel,
    onClick,
}: ConfirmationModalProps) => {
    return (
        <ReactModal
            ariaHideApp={false}
            isOpen={true}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: { zIndex: 1000 },
                content: { width: 400, margin: 'auto', height: 'fit-content' },
            }}
        >
            <Column>
                <ModalTitle>{title}</ModalTitle>
                <ModalDescription>{description}</ModalDescription>
                <ButtonRow>
                    <PrimaryButton
                        type="button"
                        label="Cancelar"
                        onClick={onCancel}
                    />
                    <PrimaryButton
                        type="button"
                        label="Confirmar"
                        redTheme={true}
                        onClick={onClick}
                    />
                </ButtonRow>
            </Column>
        </ReactModal>
    )
}

const ModalTitle = styled('h3')`
    ${Typography.H4_High_contrast_Right}
`

const ModalDescription = styled('span')`
    ${Typography['Body_Text_#3_Regular_Left']};
`

const ButtonRow = styled(Row as any)`
    justify-content: flex-end;
    margin-top: 24px;

    button:not(:last-child) {
        margin-right: 12px;
    }
`
