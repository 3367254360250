export enum RoutesEnum {
    HOME = '/',
    LOGIN = '/login',
    VEHICLES_LIST = '/admin/vehicles',
    CREATE_VEHICLE = '/admin/vehicle/new',
    VEHICLE_PAGE = '/admin/vehicle-details/:vehicleId/',
    ADMIN_VEHICLES = '/admin/admin-vehicles',
    SELL_VEHICLE = '/admin/vehicles/:vehicleId/sale',
    SOLD_VEHICLES = '/admin/sold-vehicles',
    GET_USER = '/account/user/:userId/',
    ANALYTICS = '/admin/analytics/',
    CONTACTS = '/account/contacts/',
    // STAND_LIST = "/admin/stands",
    CREATE_STAND = '/superuser/company/:companyId/stand/new',
    GET_STAND = '/superuser/stand-details/:standId/',
    COMPANIES_LIST = '/superuser/companies',
    CREATE_COMPANY = '/superuser/companies/new',
    COMPANY_PAGE = '/superuser/company-details/:companyId/',
    CREATE_USER = '/superuser/company/:companyId/user/new',
    CREATE_FINANCIAL = '/superuser/company/:companyId/financials/',
    CREATE_CONTACT = '/account/contacts/new',
    CONTACT_PAGE = '/admin/contacts/:contactId/',
    GET_SUPPLIER = '/superuser/supplier-details/:supplierId/',
    CREATE_SUPPLIER = '/superuser/company/:companyId/supplier/new',
}

export enum VehicleRoutesEnum {
    VEHICLE_DETAILS = '/admin/vehicle-details/:vehicleId/', // This is because this is an extension of the VEHICLE_PAGE url
    CREATE_SALE = '/admin/vehicle-details/:vehicleId/new-sale', // This is because this is an extension of the VEHICLE_PAGE url
    SALE_DETAILS = '/admin/vehicle-details/:vehicleId/sale-details', // This is because this is an extension of the VEHICLE_PAGE url
}
