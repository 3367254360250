import React from 'react'
import { Provider, useSelector } from 'react-redux'
import store, { IStoreInterface } from './configs/store.config'
import { Manager } from 'react-popper'
import PublicRoutes from './public-routes.component'
import PrivateRoutes from './private-routes.component'
import { ModalProvider } from 'react-modal-hook'
import {
    Provider as AlertProvider,
    positions,
    transitions,
    AlertProviderProps,
} from 'react-alert'
import { Alert } from './shared/components/alert.component'
import Modal from 'react-modal'
const RouteHandler = () => {
    const token = useSelector(
        (state: IStoreInterface) => state.adminReducer.token
    )

    return token ? <PrivateRoutes /> : <PublicRoutes />
}
// optional cofiguration
const options: AlertProviderProps = {
    position: positions.BOTTOM_CENTER,
    timeout: 5000,
    template: Alert,
    offset: '30px',
    transition: transitions.FADE,
    containerStyle: { bottom: 50, zIndex: 10000 },
}

const App = () => {
    return (
        <Provider store={store}>
            <ModalProvider>
                <AlertProvider {...options}>
                    <Manager>
                        <RouteHandler />
                    </Manager>
                </AlertProvider>
            </ModalProvider>
        </Provider>
    )
}

Modal.setAppElement('#root')

export default App
