import { DateTime } from 'luxon'
import { Company, Contact, Vehicle } from '../shared/shared.models'
import { VehicleFilters } from './components/vehicle-search.component'
import { AvailabilityEnum } from '../shared/shared.enums'
import moment from 'moment'
import { CompanyFilters } from './components/company-search.component'
import { ContactFilters } from './components/contacts-search.component'

export const AdminUtils = {
    /**
     * Calculates the days in stock of a specific vehicles
     */
    getDaysInStock: (date: string) => {
        const data = DateTime.fromISO(date).diffNow('day')
        return Math.abs(Math.round(data.days))
    },

    /**
     * Formats the data of the licensePlate to show only the month and year
     */
    getVehicleMonthAndDate: (vehicle: Vehicle) => {
        if (vehicle.dateVehicle) {
            const aux = moment(vehicle.dateVehicle)
            return aux.format('MM/YYYY')
        }
        return '-'
    },
    /**
     * Formats the data of the licensePlate to show only the month and year
     */
    getSaleMonthAndDate: (vehicle: Vehicle) => {
        if (vehicle.saleDate) {
            const aux = moment(vehicle.saleDate, 'YYYY-MM-DD')
            return aux.format('MM/YYYY')
        }
        return '-'
    },
    /**
     * Formats the data of the contact to show only the month and year
     */
    getContactMonthAndDate: (contact: Contact) => {
        if (contact.date) {
            const aux = moment(contact.date, 'YYYY-MM-DD')
            return aux.format('MM/YYYY')
        }
        return '-'
    },
    /**
     * Filters a list of vehicles
     * We can add more cases to this function to make this more generic
     */
    filterAllVehicles: (filters: any, vehicles: Vehicle[]): Vehicle[] => {
        const hasFilters = Object.keys(filters).length > 0
        if (hasFilters) {
            let newVehicles = vehicles
            Object.keys(filters).forEach(key => {
                const value = filters[key]
                switch (key) {
                    case VehicleFilters.BRAND_OR_MODEL:
                        newVehicles = newVehicles.filter(nv => {
                            const fulLText = `${nv.brand} ${nv.model}`
                            return (
                                nv.brand
                                    .toUpperCase()
                                    .includes(value.toUpperCase()) ||
                                nv.model
                                    .toUpperCase()
                                    .includes(value.toUpperCase()) ||
                                fulLText
                                    .toUpperCase()
                                    .includes(value.toUpperCase())
                            )
                        })
                        break
                    case VehicleFilters.LICENSE_PLATE:
                        newVehicles = newVehicles.filter(nv =>
                            nv.licensePlate
                                .toUpperCase()
                                .includes(value.toUpperCase())
                        )
                        break
                    case VehicleFilters.LOCATION:
                        newVehicles = newVehicles.filter(
                            nv => nv.standLocation === value
                        )
                        break
                    case VehicleFilters.VEHICLE_TYPE:
                        newVehicles = newVehicles.filter(
                            nv => nv.typeVehicle === value
                        )
                        break
                    case VehicleFilters.PRICE_RANGE:
                        newVehicles = newVehicles.filter(v => {
                            if (value.min && value.max) {
                                return (
                                    v.sellerPrice >= value.min &&
                                    v.sellerPrice <= value.max
                                )
                            } else if (value.min) {
                                return v.sellerPrice >= value.min
                            } else if (value.max) {
                                return v.sellerPrice <= value.max
                            } else {
                                return true
                            }
                        })
                        break
                    case VehicleFilters.SALE_MONTH:
                        newVehicles = newVehicles.filter(nv => {
                            if (nv.saleDate) {
                                const aux = moment(nv.saleDate).month() + 1
                                return aux === Number(value)
                            }
                            return false
                        })
                        break
                    case VehicleFilters.SALE_YEAR:
                        newVehicles = newVehicles.filter(nv => {
                            if (nv.saleDate) {
                                const aux = moment(nv.saleDate).year()
                                return aux === Number(value)
                            }
                            return false
                        })
                        break
                    case VehicleFilters.FINANCED_VEHICLE:
                        newVehicles = newVehicles.filter(nv =>
                            value ? nv.loanValue && nv.loanValue > 0 : true
                        )
                        break
                    case VehicleFilters.LOAN_COMPANY:
                        newVehicles = newVehicles.filter(
                            nv => nv.financialName && nv.financialName === value
                        )
                        break
                    case VehicleFilters.SELLER:
                        newVehicles = newVehicles.filter(
                            nv => nv.username && nv.username === value
                        )
                        break
                    case VehicleFilters.SHIFT:
                        newVehicles = newVehicles.filter(
                            nv => nv.shift && nv.shift === value
                        )
                        break
                    case VehicleFilters.STAND_LOCATION:
                        newVehicles = newVehicles.filter(
                            nv =>
                                nv.saleStandLocation &&
                                nv.saleStandLocation === value
                        )
                        break
                }
            })
            return newVehicles
        }
        return vehicles
    },
    /**
     * Filters the vehicles in the Waiting Vehicle pages
     */
    filterWaitingVehicles: (filters: any, vehicles: Vehicle[]) => {
        const hasFilters = Object.keys(filters).length > 0
        let newVehicles = [...vehicles]
        if (hasFilters) {
            Object.keys(filters).forEach(key => {
                switch (key) {
                    case VehicleFilters.REPAIR_VEHICLE:
                        newVehicles = newVehicles.filter(
                            nv => nv.availability === AvailabilityEnum.REPARING
                        )
                        break
                    case VehicleFilters.NO_PRICE_VEHICLE:
                        newVehicles = newVehicles.filter(
                            nv => !nv.sellerPrice && !nv.sale?.salePrice
                        )
                        break
                }
            })
            return newVehicles
        }
        return newVehicles
    },
    /**
     * Filters the companies
     */
    filterAllCompanies: (filters: any, companies: Company[]) => {
        const hasFilters = Object.keys(filters).length > 0
        let newCompanies = [...companies]
        if (hasFilters) {
            Object.keys(filters).forEach(key => {
                const value = filters[key]
                switch (key) {
                    case CompanyFilters.NAME:
                        newCompanies = newCompanies.filter(nv =>
                            nv.name.toUpperCase().includes(value.toUpperCase())
                        )
                        break
                }
            })
            return newCompanies
        }
        return newCompanies
    },
    /**
     * Filters a list of contacts
     * We can add more cases to this function to make this more generic
     */
    filterAllContacts: (filters: any, contacts: Contact[]): Contact[] => {
        const hasFilters = Object.keys(filters).length > 0
        if (hasFilters) {
            let newContacts = contacts
            Object.keys(filters).forEach(key => {
                const value = filters[key]
                switch (key) {
                    case ContactFilters.NAME:
                        newContacts = newContacts.filter(nv => {
                            return nv.name
                                .toUpperCase()
                                .includes(value.toUpperCase())
                        })
                        break
                    case ContactFilters.STATUS:
                        newContacts = newContacts.filter(
                            nv => nv.status && nv.status === value
                        )
                        break
                    case ContactFilters.MONTH:
                        newContacts = newContacts.filter(nv => {
                            if (nv.date) {
                                const aux = moment(nv.date).month() + 1
                                return aux === Number(value)
                            }
                            return false
                        })
                        break
                    case ContactFilters.YEAR:
                        newContacts = newContacts.filter(nv => {
                            if (nv.date) {
                                const aux = moment(nv.date).year()
                                return aux === Number(value)
                            }
                            return false
                        })
                        break
                    case ContactFilters.SELLER:
                        newContacts = newContacts.filter(
                            nv =>
                                nv.contactUserUuid &&
                                nv.contactUserUuid === value
                        )
                        break
                }
            })
            return newContacts
        }
        return contacts
    },
}
