import React from 'react'
import {
    Section,
    SectionTitle,
    WrapRow,
} from '../../../../shared/shared.styled'
import { useFormikContext } from 'formik'
import { SaleRequest } from '../../../sales.interfaces'
import TextArea from '../../../../shared/components/text-area.component'
import { DateInput } from '../../../../shared/components/date-input.component'
import { SharedSectionProps } from '../../../../shared/shared.interface'

export const RepairsSection = ({ isEditing }: SharedSectionProps) => {
    const { values, handleChange } = useFormikContext<SaleRequest>()
    return (
        <Section>
            <SectionTitle>Reparações</SectionTitle>
            <WrapRow>
                <TextArea
                    inEdition={isEditing}
                    containerStyles={{ maxWidth: '500px' }}
                    onChange={handleChange}
                    name="workToBeDone"
                    title="Trabalhos a realizar"
                    value={values.workToBeDone}
                />
                <DateInput
                    inEdition={isEditing}
                    onChange={handleChange}
                    name="forecastDate"
                    value={values.forecastDate}
                    title="Previsão de entrega"
                    containerStyle={{ maxWidth: '250px' }}
                />
            </WrapRow>
        </Section>
    )
}
