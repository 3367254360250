import React from "react";
import { ScrollPosition } from "../shared.interface";

function getScrollPosition(): ScrollPosition {
  return { x: window.pageXOffset, y: window.pageYOffset };
}

export function useScrollPosition() {
  const [scrollPosition, setScrollPosition] = React.useState<ScrollPosition>(
    getScrollPosition()
  );

  React.useEffect(() => {
    let requestRunning: number | null = null;

    function handleScroll() {
      if (requestRunning === null) {
        requestRunning = window.requestAnimationFrame(() => {
          setScrollPosition(getScrollPosition());
          requestRunning = null;
        });
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollPosition;
}
