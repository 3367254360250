import React from 'react'
import { Formik, FormikHelpers, Form } from 'formik'
import { AdminApi } from '../admin.api'
import { useDispatch } from 'react-redux'
import { AdminActions } from '../admin.store'
import { navigate } from 'hookrouter'
import { RoutesEnum } from '../../routes.constants'
import { CreateSupplierRequest } from '../admin.interfaces'
import FixedPageTitle from '../components/fixed-page-title.component'
import { createSupplierSchema } from '../validation.schemas'
import { Container } from '../../shared/shared.styled'
import PrimaryButton from '../../shared/components/button.component'
import { useAlert } from 'react-alert'
import { useModal } from 'react-modal-hook'
import { ConfirmationModal } from '../../shared/components/modals/confirmation.modal'
import { Company } from '../../shared/shared.models'
import SupplierFeatures from '../components/supplier-features.component'
import { useIsSuperAdmin } from '../../shared/hooks/use-is-super-admin.hook'

export interface IBaseInterface {
    company: Company
}

interface CompanyPageProps {
    companyId: string
}

const CreateSupplierPage = ({ companyId }: CompanyPageProps) => {
    // Hooks Initialization
    const dispatch = useDispatch()
    const isSuperAdmin = useIsSuperAdmin()
    const alert = useAlert()

    const [showConfirmationModal, hideConfirmationModal] = useModal(
        () => (
            <ConfirmationModal
                title="Tem a certeza que quer sair"
                description="Vai perder toda a informação que modificou"
                onClick={() => {
                    hideConfirmationModal()
                    window.history.back()
                }}
                onCancel={hideConfirmationModal}
            />
        ),
        []
    )

    /**
     * Helper function to get the initial formikValues
     * @param supplier Supplier | undefined
     */
    const getFormikInitialValues = () => {
        return {
            name: '',
            street: '',
            parish: '',
            county: '',
            district: '',
            phone: '',
            email: undefined,
            nif: undefined,
            notes: '',
            isFrequent: false,
            companyUuid: companyId,
        }
    }

    /**
     * Calls the API endpoint to save a supplier, and handles the loading/error states
     * of the form
     * @param values CreateSupplierRequest
     * @param actions FormikHelpers<CreateSupplierRequest>
     */
    const addSupplier = async (
        values: CreateSupplierRequest,
        actions: FormikHelpers<CreateSupplierRequest>
    ) => {
        try {
            actions.setSubmitting(true)
            const res = await AdminApi.methods.createSupplier(values)
            dispatch(
                AdminActions.methods.createSupplierSuccessAction(
                    res.data.supplier
                )
            )
            alert.success('Fornecedor criado com sucesso')
            if (isSuperAdmin) {
                navigate(
                    RoutesEnum.COMPANY_PAGE.replace(
                        ':companyId',
                        values.companyUuid
                    )
                )
            } else {
                navigate(RoutesEnum.ANALYTICS)
            }
        } catch (e) {
            alert.error(
                'Erro ao criar o fornecedor. Por favor tente mais tarde'
            )
        }
    }

    const checkIfFormIsDirtyAndClose = (isDirty: boolean) => {
        if (isDirty) {
            showConfirmationModal()
        } else {
            window.history.back()
        }
    }

    const renderButtons = (isSubmitting: boolean, isDirty: boolean) => (
        <>
            <PrimaryButton
                label="Cancelar"
                onClick={() => checkIfFormIsDirtyAndClose(isDirty)}
            />
            <PrimaryButton
                isLoading={isSubmitting}
                type="submit"
                label="Criar fornecedor"
                redTheme={true}
            />
        </>
    )

    return (
        <Container>
            <Formik<CreateSupplierRequest>
                onSubmit={addSupplier}
                initialValues={getFormikInitialValues()}
                validateOnChange={false}
                validationSchema={createSupplierSchema()}
                enableReinitialize={true}
            >
                {({ isSubmitting, handleSubmit, dirty }) => (
                    <Form
                        translate="yes"
                        onSubmit={isSubmitting ? () => null : handleSubmit}
                    >
                        <FixedPageTitle
                            label="Criar um novo fornecedor"
                            rightButtons={renderButtons(isSubmitting, dirty)}
                        />

                        <SupplierFeatures isEditing={true} />
                    </Form>
                )}
            </Formik>
        </Container>
    )
}

export default CreateSupplierPage
