import React from 'react'
import { SmallSectionTitle, WrapRow } from '../../../../shared/shared.styled'
import Checkbox from '../../../../shared/components/checkbox.component'
import { useFormikContext } from 'formik'
import { DropdownUtils } from '../../../../shared/utils/dropdowns.utils'
import Dropdown from '../../../../shared/components/dropdown.component'
import TextInput from '../../../../shared/components/text-input.component'
import { SaleRequest } from '../../../sales.interfaces'
import { SharedSectionProps } from '../../../../shared/shared.interface'
import { IStoreInterface } from '../../../../configs/store.config'
import { useSelector } from 'react-redux'
import { useIsWorkshop } from '../../../../shared/hooks/use-is-workshop.hook'

export const LoansSection = ({ isEditing }: SharedSectionProps) => {
    const isWorkshop = useIsWorkshop()
    const { values, handleChange, setFieldValue, errors } = useFormikContext<
        SaleRequest
    >()

    const financials = useSelector(
        (state: IStoreInterface) => state.adminReducer.financials
    )

    function financeValues() {
        if (values.loanCompany && financials) {
            const fin = financials
                .filter(elem => {
                    if (elem.financialUuid)
                        return elem.financialUuid === values.loanCompany
                    else return elem.uuid === values.loanCompany
                })
                .map(el => {
                    if (el.financialUuid) return el.financialUuid
                    return el.uuid
                })
            if (fin.length > 0) return fin[0]
            return undefined
        }

        return undefined
    }
    return (
        <>
            <SmallSectionTitle>Financiamento</SmallSectionTitle>
            <Checkbox
                disabled={!isEditing}
                checked={values.loan}
                onChange={() => {
                    setFieldValue('loanValue', 0)
                    setFieldValue('loanCompany', null)
                    setFieldValue('loan', !values.loan)
                }}
                containerStyle={{ marginBottom: 16 }}
                label="Viatura financiada"
            />
            <WrapRow>
                {!isWorkshop && (
                    <TextInput
                        inEdition={isEditing}
                        title="Valor financiado"
                        name="loanValue"
                        value={values.loanValue ? values.loanValue : 0}
                        error={errors.loanValue}
                        type="number"
                        onChange={handleChange}
                        containerStyle={{ maxWidth: '250px' }}
                        disabled={!values.loan}
                    />
                )}
                <Dropdown
                    inEdition={isEditing}
                    label="Financeira"
                    value={financeValues()}
                    error={errors.loanCompany}
                    selectValue={opt => setFieldValue('loanCompany', opt.key)}
                    clearValue={() => setFieldValue('loanCompany', undefined)}
                    placeholder="Escolha a financeira"
                    options={DropdownUtils.getLoanCompanyDropdownOptionsSale(
                        financials ? financials : []
                    )}
                    disabled={!values.loan}
                />
            </WrapRow>
        </>
    )
}
